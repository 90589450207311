<template>
  <div class="page-container">
    <div class="page-header">
      <div class="title" >
        <img src="../../assets/menu-icons/users-active.svg" alt="reports-icon">
        <span class="text">
            Управление пользователями
        </span>
      </div>
    </div>
    <div class="page-content">
      <div class="tab-content table">
        <TableComponent
            :columns="columns"
            :rows="tableRows_1"
            :title="'Пользователи'"
            :show-additional-table-header="false"
            :isLightFontAdditional="true"
            :usersManagement="true"
            @downloadXSLX="downloadXSLX"
            :actionType="'usersManagement'"
            :noData="noData"
            :totalCount="totalCount"
            :pageSize="pageSize"
            :pageCount="pageCount"
            :currentPage="Number(currentPage)"
            :isLoading="loading"
            @regetData="regetData($event)"
        />
      </div>
    </div>
  </div>
</template>

<script>

import {mapActions, mapMutations, mapState} from "vuex";

export default {
  name: "UsersManagement",
  data() {
    return {
      tableColumns_1: [
        {
          name: 'ID',
          field: 'id',
          filter_name: 'id',
          filter_value: '',
          order: ''
        },
        {
          name: 'Группа',
          field: 'group',
          filter_name: 'type',
          filter_value: '',
          order: ''
        },
        {
          name: 'Имя',
          field: 'name',
          filter_name: 'name',
          filter_value: '',
          order: ''
        },
        {
          name: 'Email',
          field: 'email',
          filter_name: 'login',
          filter_value: '',
          order: ''
        },
        {
          name: 'Статус',
          field: 'status',
          filter_name: 'status_id',
          filter_value: '',
          order: ''
        },
        {
          name: '',
          field: 'actions'
        },
      ],
      tableRows_1: [
        {
          id: '1',
          name: 'ООО “Юнион Лаб”',
          group: 'Администратор',
          email: 'ivankovich@undsgn.ru',
          status: 'Активен',
          green: {status: true},
          actionType: 'usersManagement'
        },
        {
          id: '2',
          name: 'Сидоров Э. В.',
          group: 'Пользователь',
          email: 'some@mail.ru',
          status: 'Активен',
          green: {status: true},
          actionType: 'usersManagement'
        },
        {
          id: '3',
          name: 'admin',
          group: 'Менеджер',
          email: 'example@email.com',
          status: 'Заблокирован',
          red: {status: true},
          actionType: 'usersManagement'
        },
      ],

      noData: false,
      loading: false,
      totalCount: 0,
      pageSize: 0,
      pageCount: 0,
      currentPage: 0,
    }
  },
  watch: {
    async allUsers(val) {
      await this.setTablesData()
    },
    // async editUserPopup(val) {
    //   if (val === false) {
    //     await this.setTablesData()
    //   }
    // },
    // async addNewUserPopup(val) {
    //   if (val === false) {
    //     await this.setTablesData()
    //   }
    // }
  },
  computed: {
    ...mapState({
      token: state => state.user.token,
      roles: state => state.user.roles,
      userRole: state => state.user.userRole,
      allUsers: state => state.user.allUsers,
      columns: state => state.popups.columns,
      editUserPopup: state => state.popups.showEditUserPopup,
      addNewUserPopup: state => state.popups.showAddNewUserPopup,
    }),
  },
  methods: {
    ...mapActions({
      getAllUsers: 'getAllUsers',
      downloadXLSXFile: 'downloadXLSXFile',
      getUserRoles: 'getUserRoles',
      getIntegrations: 'getIntegrations',
      getPaymentIntegrations: 'getPaymentIntegrations',
    }),
    ...mapMutations({
      setColumns: 'SET_COLUMNS',
      setColumns_2: 'SET_COLUMNS_2',
      setPaginationFilters: 'SET_PAGINATION_FILTERS',
      resetPaginationFilters: 'RESET_PAGINATION_FILTERS',
    }),
    async downloadXSLX() {
      await this.getAllUsers(true)
    },
    async regetData(data) {
      console.log(data)
      this.loading = true

      let args = [
        {
          name: 'page',
          value: data.pageNumber
        },
        {
          name: 'perPage',
          value: data.size
        }
      ]

      if (Math.ceil(this.totalCount/data.size) < data.pageNumber) {
        args[0].value = 1
      }

      this.setPaginationFilters(args)

      await this.getAllUsers()
    },
    async setTablesData() {
      this.totalCount = this.allUsers.total_count
      this.pageSize = this.allUsers.page_size
      this.pageCount = this.allUsers.page_count
      this.currentPage = this.allUsers.current_page

      let usersArray = []

      if (this.roles) {
        for (let i = 0; i < this.allUsers.data.length; i++) {
          let user = this.allUsers.data[i]

          user.group = this.roles.find(item => item.id === user.type).name

          // user.id = user.ID
          user.actionType = 'usersManagement'
          if (user.status === 'Активен') {
            user.green = {status: true}
          } else {
            user.red = {status: true}
          }

          usersArray.push(user)
        }
      }

      this.tableRows_1 = usersArray

      if (!this.tableRows_1 || this.tableRows_1.length === 0) {
        this.noData = true
      } else {
        this.noData = false
      }

      if (this.columns) {
        this.setColumns(this.columns)
      } else {
        this.setColumns(this.tableColumns_1)
      }
      this.loading = false
    }
  },
  async mounted() {
    this.setColumns(null)
    this.setColumns_2(null)
    this.resetPaginationFilters()

    await this.getIntegrations();
    await this.getPaymentIntegrations();

    if (!this.roles || this.roles.length === 0) {
      await this.getUserRoles()
    }

    setTimeout(async () => {
      await this.getAllUsers()
      //await this.setTablesData()
    }, 500)
  }
}
</script>

<style scoped lang="scss">
@import "../../styles/variables";
.separator {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  height: 1px;
  background: $back;
  width: 100%;
}
.tab-content {
  margin-top: 30px;
}
</style>
