import * as type from './types';
import api from '../../../api';

const actions = {
  // getMyUser({commit}) {
  //   return new Promise((resolve, reject) => {
  //     api.get(`user/me`)
  //       .then(res => {
  //         commit(type.GET_MY_USER, res.data);
  //         resolve(res);
  //       })
  //       .catch(error => {
  //         console.log('Problem', error.message);
  //         reject(error);
  //       });
  //   })
  // },
};

export default actions;
