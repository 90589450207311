import * as type from './types';
import actions from './actions';
import api from '../../../api';

const state = {
  myUser: {},
};

const mutations = {
  [type.GET_MY_USER](state, payload) {
    state.myUser = payload
  },
};

const getters = {
};

export default {
    state,
    mutations,
    actions,
    getters,
};
