<template>
  <div class="page-container">
    <div class="page-header">
      <div class="title" >
        <img src="../../assets/menu-icons/reports-active.svg" alt="reports-icon">
        <span class="text">
            Отчеты
        </span>
      </div>
    </div>
    <div class="page-content">
      <tabs v-if="tabs" :tabsNames="tabs" @firstTabActive="firstTabActive = $event"/>
      <div class="tab-content table" :class="{ 'tab-content__active-first': firstTabActive}">
        <TableComponent
            :noData="noData"
            :columns="columns"
            :rows="tableRows"
            :totalCount="totalCount"
            :pageSize="pageSize"
            :pageCount="pageCount"
            :currentPage="Number(currentPage)"
            :isLoading="loading"
            :title="'Сверка'"
            :isBordered="true"
            :datePicker="true"
            @regetData="regetData($event)"
            @downloadXSLX="downloadXSLX"
            :actionType="'reviseReport'"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Tabs from "@/components/tabs.vue";
import {mapActions, mapMutations, mapState} from "vuex";

export default {
  name: "ReviseReport",
  components: {
    Tabs
  },
  data() {
    return {
      noData: false,
      loading: false,
      totalCount: 0,
      pageSize: 0,
      pageCount: 0,
      currentPage: 0,
      firstTabActive: false,
      tabsNames: [
        { name: 'Транзакционный отчет', linkName: 'reports.transactions' },
        { name: 'Платежный отчет', linkName: 'reports.payment' },
        { name: 'Отчет по оборотам карт', linkName: 'reports.cards' },
        { name: 'Зарплатный отчет', linkName: 'reports.salary' },
        { name: 'Отчет Сверка', linkName: 'reports.revise' },
      ],
      tableColumns_1: [
        // {
        //   name: 'Наименование',
        //   field: 'organization_name',
        //   filter_name: 'organization_name',
        //   filter_value: '',
        //   order: ''
        // },
        // {
        //   name: 'ДТ, л',
        //   field: 'diesel_litres',
        //   filter_name: '',
        //   filter_value: '',
        //   order: ''
        // },
        // {
        //   name: 'Сумма, руб',
        //   field: 'diesel_sum_roubles',
        //   filter_name: '',
        //   filter_value: '',
        //   order: ''
        // },
        // {
        //   name: 'АИ-92, л',
        //   field: 'nine_two_litres',
        //   filter_name: '',
        //   filter_value: '',
        //   order: ''
        // },
        // {
        //   name: 'Сумма, руб',
        //   field: 'nine_two_sum_roubles',
        //   filter_name: '',
        //   filter_value: '',
        //   order: ''
        // },
        // {
        //   name: 'АИ-95, л',
        //   field: 'nine_five_litres',
        //   filter_name: '',
        //   filter_value: '',
        //   order: ''
        // },
        // {
        //   name: 'Сумма, руб',
        //   field: 'nine_five_sum_roubles',
        //   filter_name: '',
        //   filter_value: '',
        //   order: ''
        // },
        // {
        //   name: 'АИ-98, л',
        //   field: 'nine_eight_litres',
        //   filter_name: '',
        //   filter_value: '',
        //   order: ''
        // },
        // {
        //   name: 'Сумма, руб',
        //   field: 'nine_eight_sum_roubles',
        //   filter_name: '',
        //   filter_value: '',
        //   order: ''
        // },
        // {
        //   name: 'СУГ, л',
        //   field: 'lpg_litres',
        //   filter_name: '',
        //   filter_value: '',
        //   order: ''
        // },
        // {
        //   name: 'Сумма, руб',
        //   field: 'lpg_sum_roubles',
        //   filter_name: '',
        //   filter_value: '',
        //   order: ''
        // },
        // {
        //   name: 'AB-blue, шт',
        //   field: 'ab_blue_pieces',
        //   filter_name: '',
        //   filter_value: '',
        //   order: ''
        // },
        // {
        //   name: 'Сумма, руб',
        //   field: 'ab_blue_sum_roubles',
        //   filter_name: '',
        //   filter_value: '',
        //   order: ''
        // },
        // {
        //   name: 'Кол-во общее, л',
        //   field: 'total_litres',
        //   filter_name: '',
        //   filter_value: '',
        //   order: ''
        // },
        // {
        //   name: 'Сумма общая, руб',
        //   field: 'total_sum_roubles',
        //   filter_name: '',
        //   filter_value: '',
        //   order: ''
        // }
      ],
      tableRows: [
        {
          name: 'ООО “ААА"',
          diesel_litres: `133`,
          diesel_sum_roubles: `13 870`,
          nine_two_litres: `120`,
          nine_two_sum_roubles: `10 990`,
          nine_five_litres: `66`,
          nine_five_sum_roubles: `12 000`,
          nine_eight_litres: `188`,
          nine_eight_sum_roubles: `49 000`,
          lpg_litres: `45`,
          lpg_sum_roubles: `8 550`,
          ab_blue_pieces: `13`,
          ab_blue_sum_roubles: `3 590`,
          total_litres: `563`,
          total_sum_roubles: `134 570`
        },
        {
          name: 'ООО “ААА"',
          diesel_litres: `133`,
          diesel_sum_roubles: `13 870`,
          nine_two_litres: `120`,
          nine_two_sum_roubles: `10 990`,
          nine_five_litres: `66`,
          nine_five_sum_roubles: `12 000`,
          nine_eight_litres: `188`,
          nine_eight_sum_roubles: `49 000`,
          lpg_litres: `45`,
          lpg_sum_roubles: `8 550`,
          ab_blue_pieces: `13`,
          ab_blue_sum_roubles: `3 590`,
          total_litres: `563`,
          total_sum_roubles: `134 570`
        }
      ]
    }
  },
  watch: {
    async revise_report(val) {
      await this.setTablesData()
    },
  },
  computed: {
    ...mapState({
      token: state => state.user.token,
      userRole: state => state.user.userRole,
      myUser: state => state.user.myUser,
      fuelTypes: state => state.user.fuelTypes,
      columns: state => state.popups.columns,
      revise_report: state => state.admin.revise_report,
    }),
    tabs() {
      switch (this.userRole) {
        case 3: return [this.tabsNames[0], this.tabsNames[1]];
        case 2: return [this.tabsNames[0], this.tabsNames[2], this.tabsNames[3]]
        case 1: return [this.tabsNames[0], this.tabsNames[4], this.tabsNames[3]]
      }
    }
  },
  methods: {
    ...mapActions({
      getReviseReport: 'getReviseReport',
      getTransactionReportXLSX: 'getTransactionReportXLSX',
      getFuelTypes: 'getFuelTypes',
    }),
    ...mapMutations({
      setColumns: 'SET_COLUMNS',
      setColumns_2: 'SET_COLUMNS_2',
      setPaginationFilters: 'SET_PAGINATION_FILTERS',
      resetPaginationFilters: 'RESET_PAGINATION_FILTERS',
    }),
    async downloadXSLX() {
      await this.getReviseReport(true)
    },
    async regetData(data) {
      console.log(data)
      this.loading = true

      let args = [
        {
          name: 'page',
          value: data.pageNumber
        },
        {
          name: 'perPage',
          value: data.size
        }
      ]

      if (Math.ceil(this.totalCount/data.size) < data.pageNumber) {
        args[0].value = 1
      }

      this.setPaginationFilters(args)

      await this.getReviseReport()
    },
    async setTablesData() {
      await this.$nextTick()
      this.totalCount = this.revise_report.total_count
      this.pageSize = this.revise_report.page_size
      this.pageCount = this.revise_report.page_count
      this.currentPage = this.revise_report.current_page
      let reviseReportData = []

      for (let i = 0; i < this.revise_report.data.length; i++) {
        let report = this.revise_report.data[i]

        reviseReportData.push(report)
      }

      this.tableRows = reviseReportData

      this.tableColumns_1 = [];
      this.tableColumns_1.push({
        name: 'Наименование',
        field: 'organization_name',
        filter_name: 'organization_name',
        filter_value: '',
        order: ''
      })
      this.fuelTypes.forEach(fuelType => {
        this.tableColumns_1.push({
          name: fuelType.name,
          id: fuelType.id,
          field: fuelType.name,
          filter_name: 'total_liters_fuel',
          filter_value: '',
          order: '',
          fuel_types: true,
          no_filter: true
        })
        this.tableColumns_1.push({
          name: 'Сумма, руб',
          id: fuelType.id,
          field: fuelType.name,
          filter_name: 'total_amount_fuel',
          filter_value: '',
          order: '',
          fuel_types_total: true,
          no_filter: true
        })
      })
      this.tableColumns_1.push(
        {
          name: 'Сумма, руб',
          field: 'total_amount',
          filter_name: 'total_amount',
          filter_value: '',
          order: '',
          fuel_types_total_end: true,
          no_filter: true
        },
        {
          name: 'Кол-во общее, л',
          field: 'total_litres',
          filter_name: 'total_liters',
          filter_value: '',
          order: '',
          fuel_types_total_end: true,
          no_filter: true
        },
        // {
        //   name: 'Сумма общая, руб',
        //   field: 'total_amount_liter',
        //   filter_name: 'total_amount_liter',
        //   filter_value: '',
        //   order: '',
        //   fuel_types_total_end: true
        // }
      )


      if (!this.tableRows || this.tableRows.length === 0) {
        this.noData = true
      } else {
        this.noData = false
      }

      if (this.columns) {
        this.setColumns(this.columns)
      } else {
        this.setColumns(this.tableColumns_1)
      }

      this.loading = false
    }
  },
  async mounted() {
    this.setColumns(null)

    // this.resetPaginationFilters()

    await this.getFuelTypes().then(res => {
      // this.getReviseReport()
      let data = {
        size: 100,
        pageNumber: 1
      };

      this.regetData(data);
    })
  }
}
</script>

<style scoped lang="scss">
@import "../../styles/variables";
.separator {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  height: 1px;
  background: $back;
  width: 100%;
}

.margin {
  margin-top: 30px;
}
</style>
