<template>
  <div class="popup">
    <h4 class="popup__title middle">Список названий</h4>

    <div class="popup-list-block">
      <ul>
        <li>ВИНКи</li>
        <li v-for="item of fuelProviders">{{ item.name }}</li>
      </ul>
      <ul>
        <li>Типы топлива</li>
        <li v-for="item of fuelTypes">{{ item.name }}</li>
      </ul>
      <ul>
        <li>Регионы</li>
        <li v-for="item of regions">{{ item.name }}</li>
      </ul>
    </div>

    <div class="actions">
      <button class="button btn-primary" @click="closeAll()">Закрыть</button>
    </div>
  </div>
</template>

<script>

import {popups} from '@/mixins/popups'
import {mapState} from "vuex";
export default {
  name: "listVinksRegionsFuel",
  mixins: [popups],
  data: () => ({}),
  computed: {
    ...mapState({
      fuelProviders: state => state.user.fuelProviders,
      regions: state => state.user.regions,
      fuelTypes: state => state.user.fuelTypes,
    })
  },
  mounted() {}
}
</script>

<style scoped lang="scss">
@import "../../styles/variables";

span {
  margin-bottom: 5px;
}

.actions {
  margin-top: 15px;
}

.popup {
  max-width: 700px;
  width: 100%;
}

.popup-list-block {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  width: 100%;
  max-height: 70vh;
  overflow-y: auto;

  ul {
    width: 200px;
    height: 100%;
    margin: 0;
    padding: 0 10px;
    list-style: none;
    border-right: 1px solid #6db534;

    &:last-child {
      border: none;
    }

    li {
      margin-bottom: 10px;
      text-align: left;

      &:first-child {
        color: #1A1A18;
        font-family: SF_Pro_SemiBold, Arial, sans-serif;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
      }
    }
  }
}
</style>
