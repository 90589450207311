<template>
  <div class="page-container">
    <div class="page-header">
      <div class="title" >
        <img src="../../assets/menu-icons/reports-active.svg" alt="reports-icon">
        <span class="text">
            Отчеты
        </span>
      </div>
    </div>
    <tabs v-if="tabs && tabs.length > 0" :tabsNames="tabs" @firstTabActive="firstTabActive = $event"/>
    <div class="page-content" :class="{ 'height155': userRole === 2 }">
      <div class="tab-content table" :class="{ 'tab-content__active-first': firstTabActive}" :style="{ marginTop: tabs && tabs.length > 0 ? '0' : '30px' }">
  <!--      <TableComponent-->
  <!--          :columns="columns"-->
  <!--          :rows="tableRows_1"-->
  <!--          :additionalColumns="additionalColumns_1"-->
  <!--          :additionalRows="additionalRows_1"-->
  <!--          :isBordered="true"-->
  <!--          :isLightFont="true"-->
  <!--          :isLightFontAdditional="true"-->
  <!--          :cardsReport="true"-->
  <!--      />&lt;!&ndash;:title='`Номер карты: <span style="color: #6DB534">7013420001865393<span/>`'&ndash;&gt;-->
        <TableComponent
          :noData="noData"
          :columns="columns"
          :rows="tableRows_1"
          :totalCount="totalCount"
          :pageSize="pageSize"
          :pageCount="pageCount"
          :currentPage="Number(currentPage)"
          :title="'Отчет по оборотам карт'"
          :isLoading="loading"
          :isBordered="true"
          :show-additional-table-header="false"
          :isLightFontAdditional="true"
          :additionalColumns="additionalColumns_1"
          :additionalColumns2="additionalColumns_2"
          :additionalColumnsData2="additionalColumnsData_2"
          :cardsReport="true"
          @regetData="regetData($event)"
          @downloadXSLX="downloadXSLX"
          :actionType="'cardsReportType'"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Tabs from "@/components/tabs.vue";
import {mapActions, mapMutations, mapState} from "vuex";

export default {
  name: "CardsReport",
  components: {
    Tabs
  },
  data() {
    return {
      firstTabActive: false,
      tabsNames: [
        { name: 'Транзакционный отчет', linkName: 'reports.transactions' },
        { name: 'Платежный отчет', linkName: 'reports.payment' },
        { name: 'Отчет по оборотам карт', linkName: 'reports.cards' },
        { name: 'Зарплатный отчет', linkName: 'reports.salary' },
      ],
      tableColumns_1: [
        {
          name: 'Поставщик',
          field: 'fuel_provider_id',
          filter_name: 'transaction.fuel_provider_id',
          filter_value: '',
          order: ''
        },
        {
          name: '№ АЗС',
          field: 'station_number',
          filter_name: 'station_number',
          reducer: 'number',
          filter_value: '',
          order: ''
        },
        {
          name: 'Дата',
          field: 'date',
          date_type: true,
          // filter_name: 'datetime',
          // filter_value: '',
          order: '',
          orderName: true
        },
        {
          name: 'Время',
          field: 'time',
          filter_name: 'time',
          filter_value: '',
          filter_name_from: 'time_from',
          filter_value_from: '',
          filter_name_to: 'time_to',
          filter_value_to: '',
          from_to_fields: true,
          order: '',
          timepicker: true,
          orderName: true
        },
        {
          name: 'Товар',
          field: 'fuelType',
          fuel_type: true,
          filter_name: 'transaction.fuel_type',//'fuel_type',
         // reducer: 'name',
          filter_value: '',
          order: '',
        },
        {
          name: 'Кол-во',
          field: 'fuel_count',
          filter_name: 'transaction.fuel_count',
          filter_value: '',
          filter_name_from: 'transaction.fuel_count_from',
          filter_value_from: '',
          filter_name_to: 'transaction.fuel_count_to',
          filter_value_to: '',
          from_to_fields: true,
          order: '',
        },
        {
          name: 'Цена',
          field: 'customers_price',
          filter_name: 'transaction.customers_price',
          filter_value: '',
          filter_name_from: 'transaction.customers_price_from',
          filter_value_from: '',
          filter_name_to: 'transaction.customers_price_to',
          filter_value_to: '',
          from_to_fields: true,
          order: '',
        },
        {
          name: 'Сумма',
          field: 'customers_sum',
          filter_name: 'transaction.customers_sum',
          filter_value: '',
          filter_name_from: 'transaction.customers_sum_from',
          filter_value_from: '',
          filter_name_to: 'transaction.customers_sum_to',
          filter_value_to: '',
          from_to_fields: true,
          order: '',
        }
      ],
      tableRows_1: [
        {
          station: 'Танеко',
          station_number: '226',
          date: '12.08.2022',
          time: '12:28:03',
          good: 'ДТ',
          quantity: '650.00',
          price: '51.50',
          sum: '33 475',
        },
        {
          station: 'Танеко',
          station_number: '226',
          date: '12.08.2022',
          time: '12:28:03',
          good: 'ДТ',
          quantity: '650.00',
          price: '51.50',
          sum: '33 475',
        }
      ],
      additionalColumns_1: [
        {
          name: 'Тип топлива',
          field: 'fuelType'
        },
        {
          name: 'ИТОГО литров.',
          field: 'total_amount'
        },
        {
          name: 'ИТОГО',
          field: 'total_customers_sum'
        },
      ],
      additionalColumns_2: [
        {
          name: 'Тип топлива',
          field: 'fuelType'
        },
        {
          name: 'ВСЕГО литров.',
          field: 'total_amount'
        },
        {
          name: 'ВСЕГО',
          field: 'total_customers_sum'
        },
      ],
      additionalColumnsData_2: [],
      additionalRows_1: [
        {
          fuel_type: 'ДТ',
          litres_total: '300',
          total: '1 214 734.5'
        },
        {
          fuel_type: 'ДТ Танеко',
          litres_total: '600',
          total: '142 220'
        },
        {
          fuel_type: '',
          litres_total: '700',
          total: '1 356 954.5'
        },
      ],
      noData: false,
      loading: false,
      totalCount: 0,
      pageSize: 0,
      pageCount: 0,
      currentPage: 0,
    }
  },
  computed: {
    ...mapState({
      token: state => state.user.token,
      userRole: state => state.user.userRole,
      columns: state => state.popups.columns,
      cardsReport: state => state.customer.cardsReport,
    }),
    tabs() {
      switch (this.userRole) {
        // case 3: return [this.tabsNames[0], this.tabsNames[1]];
        case 3: return [];
        case 2: return [this.tabsNames[0], this.tabsNames[2], this.tabsNames[3]]
      }
    }
  },
  watch: {
    async cardsReport(val) {
      await this.setTablesData()
    },
  },
  methods: {
    ...mapActions({
      getCardsReport: 'getCardsReport',
      getFuelTypesReq: 'getFuelTypes',
      getFuelProviders: 'getFuelProviders',
      getStations: 'getStations',
      getCardsSelect: 'getCardsSelect',
    }),
    ...mapMutations({
      setColumns: 'SET_COLUMNS',
      setPaginationFilters: 'SET_PAGINATION_FILTERS',
      resetPaginationFilters: 'RESET_PAGINATION_FILTERS',
    }),
    async downloadXSLX() {
      await this.getCardsReport(true)
    },
    async regetData(data) {
      this.loading = true

      let args = [
        {
          name: 'page',
          value: data.pageNumber
        },
        {
          name: 'perPage',
          value: data.size
        }
      ]

      if (Math.ceil(this.totalCount/data.size) < data.pageNumber) {
        args[0].value = 1
      }

      this.setPaginationFilters(args)

      await this.getCardsReport()
    },
    async setTablesData() {
      await this.$nextTick()
      this.totalCount = this.cardsReport.total_count
      this.pageSize = this.cardsReport.page_size
      this.pageCount = this.cardsReport.page_count
      this.currentPage = this.cardsReport.current_page
      let customersData = []

      for (let i = 0; i < this.cardsReport.data.length; i++) {
        let customer = this.cardsReport.data[i]

        customer.actionType = 'cardsReportType'

        customersData.push(customer)
      }

      this.tableRows_1 = customersData
      this.additionalColumnsData_2 = this.cardsReport.total

      if (!this.tableRows_1 || this.tableRows_1.length === 0) {
        this.noData = true
      } else {
        this.noData = false
      }

      if (this.columns) {
        this.setColumns(this.columns)
      } else {
        this.setColumns(this.tableColumns_1)
      }

      this.loading = false
    }
  },
  async mounted() {
    this.setColumns(null)
    this.resetPaginationFilters()

    await this.getFuelTypesReq()
    await this.getCardsSelect()
    await this.getCardsReport()
    await this.getFuelProviders()
    await this.getStations()
  }
}
</script>

<style scoped lang="scss">
@import "../../styles/variables";
.separator {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  height: 1px;
  background: $back;
  width: 100%;
}

.cards-report {
  overflow: auto;
  height: calc(100% - 185px);
}

.height155 {
  height: calc(100% - 155px);
}
</style>
