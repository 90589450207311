<template>
  <div class="page-container">
    <div class="page-header">
      <div class="title" >
        <img src="../../assets/menu-icons/dashboard-active.svg" alt="icon">
        <span class="text">
            Дашборд
        </span>
      </div>
    </div>
    <div class="page-content column">
      <tabs :tabsNames="tabsNames" @firstTabActive="firstTabActive = $event"/>
      <div class="tab-content table" :class="{ 'tab-content__active-first': firstTabActive }">
        <TableComponent
            :columns="columns"
            :rows="tableRows_1"
            :title="'Клиенты'"
            :isBordered="true"
            :totalCount="totalCount"
            :pageSize="pageSize"
            :pageCount="pageCount"
            :currentPage="Number(currentPage)"
            :isLoading="loading"
            @downloadXSLX="downloadXSLX"
            @regetData="regetData($event)"
            :noData="noData"
            :actionType="'managerDashboardActions'"
        />
      </div>

      <div class="tab-content table offset">
        <TableComponent
            :columns="columns_2"
            :noData="noData_2"
            :rows="tableRows_2"
            :title='`Оперативный отчет`'
            :isBordered="true"
            :isSecondTable="true"
            :totalCount="totalCount_2"
            :pageSize="pageSize_2"
            :pageCount="pageCount_2"
            :currentPage="Number(currentPage_2)"
            :isLoading="loading_2"
            @downloadXSLX="downloadXSLX_2"
            @regetData="regetData_2($event)"
            :actionType="'managerDashboardOperativeReportActions'"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapMutations, mapState} from "vuex";
import Tabs from "@/components/tabs.vue";

export default {
  name: "ExecutiveDashboard",
  components: {Tabs},
  data: () => ({
    firstTabActive: true,
    tabsNames: [
      { name: 'Дашборд руководителя', linkName: 'admin.dashboard.executive' },
      { name: 'Дашборд бухгалтера', linkName: 'admin.dashboard.accountant' },
    ],
    tableColumns_1: [
      {
        name: 'Наименование клиента',
        field: 'client_name',
        filter_name: 'users_ids[]',
        filter_value: '',
        order: ''
      },
      {
        name: 'Текущий\n' +
            'баланс',
        field: 'current_balance',
        filter_name: 'current_balance',
        filter_value: '',
        filter_name_from: 'current_balance_from',
        filter_value_from: '',
        filter_name_to: 'current_balance_to',
        filter_value_to: '',
        from_to_fields: true,
        order: '',
        balance_field: true
      },
      {
        name: 'Потребление\n' +
            'за текущий месяц\n' +
            '(тип топлива n)',
        field: 'fuel_type_month',
        filter_name: 'fuel_type_month_ids[]',
        filter_value: '',
        order: '',
        fuel_type: true
      },
      {
        name: 'Оборот\n' +
            'за текущий\n' +
            'год',
        field: 'turnover_current_year',
        filter_name: 'turnover_current_year',
        filter_value: '',
        filter_name_from: 'turnover_current_year_from',
        filter_value_from: '',
        filter_name_to: 'turnover_current_year_to',
        filter_value_to: '',
        from_to_fields: true,
        order: ''
      },
      {
        name: 'Потребление\n' +
            'за текущий год\n' +
            '(тип топлива n)',
        field: 'fuel_type_year',
        filter_name: 'fuel_type_year_ids[]',
        filter_value: '',
        order: '',
        fuel_type: true
      },
      {
        name: 'Менеджер',
        field: 'manager',
        filter_name: 'manager_ids[]',
        filter_value: '',
        order: ''
      }
    ],
    tableRows_1: [],
    tableColumns_2: [
      {
        name: 'Наименование организации',
        field: 'organization_name',
        filter_name: 'organization_name',
        filter_value: '',
        order: ''
      },
      {
        name: 'Сальдо\n' +
            'на начало\n' +
            'года',
        field: 'saldo_beginning_of_year',
        filter_name: 'saldo_beginning_of_year',
        filter_value: '',
        order: '',
        filter_name_from: 'saldo_beginning_of_year_from',
        filter_value_from: '',
        filter_name_to: 'saldo_beginning_of_year_to',
        filter_value_to: '',
        from_to_fields: true
      },
      {
        name: 'Оборот за каждый\n' +
            'месяц в текущем\n' +
            'году',
        field: 'turnover_by_month',
        filter_name: 'turnover_by_month',
        filter_value: '',
        order: '',
        month_arr: true
      },
      {
        name: 'Сумму задолженности\n' +
            'за каждый месяц\n' +
            'в текущем году',
        field: 'debt_by_month',
        filter_name: 'debt_by_month',
        filter_value: '',
        order: '',
        month_arr: true
      },
      {
        name: 'Сумму оплат\n' +
            'за текущий год',
        field: 'total_payments_current_year',
        filter_name: 'total_payments_current_year',
        filter_value: '',
        order: '',
        filter_name_from: 'total_payments_current_year_from',
        filter_value_from: '',
        filter_name_to: 'total_payments_current_year_to',
        filter_value_to: '',
        from_to_fields: true
      },
      {
        name: 'Общий долг\n' +
            'за текущий год',
        field: 'total_debt_current_year',
        filter_name: 'total_debt_current_year',
        filter_value: '',
        order: '',
        filter_name_from: 'total_debt_current_year_from',
        filter_value_from: '',
        filter_name_to: 'total_debt_current_year_to',
        filter_value_to: '',
        from_to_fields: true
      },
      {
        name: 'Менеджер',
        field: 'manager',
        filter_name: 'manager_ids[]',
        filter_value: '',
        order: ''
      }
    ],
    tableRows_2: [],

    noData: false,
    loading: false,
    totalCount: 0,
    pageSize: 0,
    pageCount: 0,
    currentPage: 0,

    noData_2: false,
    loading_2: false,
    totalCount_2: 0,
    pageSize_2: 0,
    pageCount_2: 0,
    currentPage_2: 0,
  }),
  watch: {
    async manager_dashboard(val) {
      await this.setTablesData()
    },
    async operations_report(val) {
      await this.setTablesData_2()
    },
  },
  computed: {
    ...mapState({
      userRole: state => state.user.userRole,
      columns: state => state.popups.columns,
      columns_2: state => state.popups.columns_2,
      manager_dashboard: state => state.admin.manager_dashboard,
      operations_report: state => state.admin.operations_report,
    }),
  },
  methods: {
    ...mapActions({
      getManagerDashboard: 'getManagerDashboard',
      getOperationsReport: 'getOperationsReport',
      downloadXLSXFile: 'downloadXLSXFile',
      getFuelTypesReq: 'getFuelTypes',
    }),
    ...mapMutations({
      setColumns: 'SET_COLUMNS',
      setColumns_2: 'SET_COLUMNS_2',
      setPaginationFilters: 'SET_PAGINATION_FILTERS',
      resetPaginationFilters: 'RESET_PAGINATION_FILTERS',
      setPaginationFilters_2: 'SET_PAGINATION_FILTERS_2',
      resetPaginationFilters_2: 'RESET_PAGINATION_FILTERS_2',
    }),
    async regetData(data) {
      console.log(data)
      this.loading = true

      let args = [
        {
          name: 'page',
          value: data.pageNumber
        },
        {
          name: 'page_size',
          value: data.size
        }
      ]

      if (Math.ceil(this.totalCount/data.size) < data.pageNumber) {
        args[0].value = 1
      }

      this.setPaginationFilters(args)

      await this.getManagerDashboard()
    },
    async regetData_2(data) {
      console.log(data)
      this.loading_2 = true

      let args = [
        {
          name: 'page',
          value: data.pageNumber
        },
        {
          name: 'page_size',
          value: data.size
        }
      ]

      if (Math.ceil(this.totalCount_2/data.size) < data.pageNumber) {
        args[0].value = 1
      }

      this.setPaginationFilters_2(args)

      await this.getOperationsReport()
    },
    async downloadXSLX() {
      await this.getManagerDashboard(true)
    },
    async downloadXSLX_2() {
      await this.getOperationsReport(true)
    },
    async setTablesData() {
      this.totalCount = this.manager_dashboard.total_count
      this.pageSize = this.manager_dashboard.page_size
      this.pageCount = this.manager_dashboard.page_count
      this.currentPage = this.manager_dashboard.current_page
      let dashboardData = []

      for (let i = 0; i < this.manager_dashboard.data.length; i++) {
        let data = this.manager_dashboard.data[i]
        data.actionType = 'managerDashboardActions'

        dashboardData.push(data)
      }

      this.tableRows_1 = dashboardData
      console.log(this.tableRows_1)


      if (!this.tableRows_1 || this.tableRows_1.length === 0) {
        this.noData = true
      } else {
        this.noData = false
      }

      if (this.columns) {
        this.setColumns(this.columns)
      } else {
        this.setColumns(this.tableColumns_1)
      }

      this.loading = false
    },
    async setTablesData_2() {
      this.totalCount_2 = this.operations_report.total_count
      this.pageSize_2 = this.operations_report.page_size
      this.pageCount_2 = this.operations_report.page_count
      this.currentPage_2 = this.operations_report.current_page
      let dashboardData = []

      // let operations_report_data = Object.keys(this.operations_report.data).map((key) => this.operations_report.data[key]);
      // this.operations_report.data.forEach(item => {
      //   operations_report_data.push(item)
      // })
      for (let i = 0; i < this.operations_report.data.length; i++) {
        let data = this.operations_report.data[i]
        data.actionType = 'managerDashboardOperativeReportActions'
        data['turnover_by_month_show_all'] = false;
        data['debt_by_month_show_all'] = false;

        dashboardData.push(data)
      }

      this.tableRows_2 = dashboardData

      if (!this.tableRows_2 || this.tableRows_2.length === 0) {
        this.noData_2 = true
      } else {
        this.noData_2 = false
      }

      if (this.columns_2) {
        this.setColumns_2(this.columns_2)
      } else {
        this.setColumns_2(this.tableColumns_2)
      }

      this.loading_2 = false
    }
  },
  async mounted() {
    this.setColumns(null)
    this.setColumns_2(null)

    // this.resetPaginationFilters()
    // this.resetPaginationFilters_2()

    await this.getFuelTypesReq();

    // await this.getManagerDashboard()
    //
    // await this.getOperationsReport()

    let data = {
      size: 100,
      pageNumber: 1
    };

    this.regetData(data);
    this.regetData_2(data);
  }
}
</script>

<style scoped lang="scss">
@import "../../styles/variables";

.page-content {
  display: flex;

  &.column {
    flex-direction: column;
  }
}

.tab-content {
  &.offset {
    margin-top: 30px;
  }
}
</style>
