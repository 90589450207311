import api from '../../../api';
import {
  SET_CLIENT_DETAILS,
  SET_PAYMENT_REPORT,
  SET_TRANSACTION_REPORT,
  SET_CLIENT_PROVIDERS,
  SET_CARDS_REPORT, SET_CLIENT_PROVIDERS_FUEL, SET_CLIENT_PROVIDERS_REGION
} from "./types";
import {getFinalUrl} from "@/utils/url_formatting";

const actions = {
    getClientDetails({commit, dispatch}) {
        return new Promise((resolve, reject) => {
            api.get('/dashboard/client-dashboard')
                .then(res => {
                    commit(SET_CLIENT_DETAILS, res.data);
                    resolve(res);
                })
                .catch(error => {
                    console.log('Problem', error.message);
                   dispatch('showError', { errorMessage: error.response.data.message || error.message })
                    reject(error);
                });
        })
    },
    async getTransactionReport({commit, dispatch, rootState}, getFile = false) {
        let url = `transaction/get-all-transactions`

        let finalUrl = await getFinalUrl(url, rootState)
        return new Promise(async (resolve, reject) => {
            //api.get(`customer/${customerID}/get-transaction-report`)
                if (getFile) {
                  url = `transaction/get-all-transactions?returnFile=true`
                    finalUrl = await getFinalUrl(url, rootState, false)
                    let dateNow = new Date();
                    let dd = String(dateNow.getDate()).padStart(2, '0');
                    let mm = String(dateNow.getMonth() + 1).padStart(2, '0'); //January is 0!
                    let yyyy = dateNow.getFullYear();
                    api.get(`${finalUrl}`, {responseType: 'blob'})
                        .then(res => {
                            const url = window.URL.createObjectURL(new Blob([res.data]));
                            const link = document.createElement('a');
                            link.href = url;
                            link.setAttribute('download', `транзакционный-отчет--${dd + '.' + mm + '.' + yyyy}.xlsx`); //or any other extension
                            document.body.appendChild(link);
                            link.click();
                            link.remove();
                            resolve(res);
                        })
                        .catch(error => {
                            console.log('Problem', error.message);
                            dispatch('showError', { errorMessage: error.response.data.message || error.message })
                            reject(error);
                        });
                } else {
                    api.get(finalUrl)
                        .then(res => {
                            if (res.data?.message?.includes('нет')) {
                                dispatch('showError', { errorMessage: res.data.msg || res.data.message })
                            }
                            commit(SET_TRANSACTION_REPORT, res.data);
                            resolve(res);
                        })
                        .catch(error => {
                            console.log('Problem', error.message);
                            dispatch('showError', { errorMessage: error.response?.data?.message || error.message })
                            reject(error);
                        });
                }
        })
    },
    getPaymentReport({commit, dispatch}, customerID) {
        return new Promise((resolve, reject) => {
            api.get(`customer/${customerID}/get-payment-report`)
                .then(res => {
                    commit(SET_PAYMENT_REPORT, res.data);
                    resolve(res);
                })
                .catch(error => {
                    console.log('Problem', error.message);
                   dispatch('showError', { errorMessage: error.response.data.message || error.message })
                    reject(error);
                });
        })
    },
    getTransactionReportXLSX({commit, dispatch}, customerID) {
        return new Promise((resolve, reject) => {
            let dateNow = new Date();
            let dd = String(dateNow.getDate()).padStart(2, '0');
            let mm = String(dateNow.getMonth() + 1).padStart(2, '0'); //January is 0!
            let yyyy = dateNow.getFullYear();
            api.get(`customer/${customerID}/get-transaction-report-xlsx`, {responseType: 'blob'})
                .then(res => {
                    const url = window.URL.createObjectURL(new Blob([res.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', `транзакционный_отчет-${dd + '.' + mm + '.' + yyyy}.xlsx`); //or any other extension
                    document.body.appendChild(link);
                    link.click();
                    link.remove();
                    resolve(res);
                })
                .catch(error => {
                    console.log('Problem', error.message);
                   dispatch('showError', { errorMessage: error.response.data.message || error.message })
                    reject(error);
                });
        })
    },
    getPaymentReportXLSX({commit, dispatch}, customerID) {
        return new Promise((resolve, reject) => {
            let dateNow = new Date();
            let dd = String(dateNow.getDate()).padStart(2, '0');
            let mm = String(dateNow.getMonth() + 1).padStart(2, '0'); //January is 0!
            let yyyy = dateNow.getFullYear();
            api.get(`customer/${customerID}/get-payment-report-xlsx`, {responseType: 'blob'})
                .then(res => {
                    const url = window.URL.createObjectURL(new Blob([res.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', `платёжный_отчет${dd + '.' + mm + '.' + yyyy}.xlsx`); //or any other extension
                    document.body.appendChild(link);
                    link.click();
                  link.remove();
                    resolve(res);
                })
                .catch(error => {
                    console.log('Problem', error.message);
                   dispatch('showError', { errorMessage: error.response.data.message || error.message })
                    reject(error);
                });
        })
    },
  async getCardsReport({commit, dispatch, rootState}, getFile = false) {
    let url = `dashboard/card-turnover-report`
    let finalUrl = await getFinalUrl(url, rootState)
    return new Promise(async (resolve, reject) => {
      if (getFile) {
        url = `dashboard/card-turnover-report?returnFile=true`
          finalUrl = await getFinalUrl(url, rootState, false)
        let dateNow = new Date();
        let dd = String(dateNow.getDate()).padStart(2, '0');
        let mm = String(dateNow.getMonth() + 1).padStart(2, '0'); //January is 0!
        let yyyy = dateNow.getFullYear();
        api.get(`${finalUrl}`, {responseType: 'blob'})
          .then(res => {
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `отчет-по-оборотам-карт--${dd + '.' + mm + '.' + yyyy}.xlsx`); //or any other extension
            document.body.appendChild(link);
            link.click();
            link.remove();
            resolve(res);
          })
          .catch(error => {
            console.log('Problem', error.message);
            dispatch('showError', { errorMessage: error.response.data.message || error.message })
            reject(error);
          });
      } else {
        api.get(finalUrl)
          .then(res => {
            commit(SET_CARDS_REPORT, res.data);
            resolve(res);
          })
          .catch(error => {
            console.log('Problem', error.message);
            commit('TOGGLE_OVERLAY', false)
            commit('SET_SHOW_DISCOUNT_POPUP', false)
            dispatch('showError', {errorMessage: error.response.data.message || error.message})
            reject(error);
          });
      }
    })
  },
  getClientProviders({commit, dispatch}, customerID) {
    return new Promise((resolve, reject) => {
      api.get(`customer/${customerID}/get-providers-discount`)
        .then(res => {
          commit(SET_CLIENT_PROVIDERS, res.data);
          resolve(res);
        })
        .catch(error => {
          console.log('Problem', error.message);
          dispatch('showError', { errorMessage: error.response.data.message || error.message })
          reject(error);
        });
    })
  },
  setClientProviders({commit, dispatch}, payload) {
    return new Promise((resolve, reject) => {
      api.post(`customer/${payload.customerID}/edit-customer-providers-discount`, payload.data)
        .then(res => {
          commit('TOGGLE_OVERLAY', false)
          commit('SET_SHOW_DISCOUNT_POPUP', false)
          dispatch('showError', { errorMessage: res.data.msg || res.data.message, isSuccess: true })
          resolve(res);
        })
        .catch(error => {
          console.log('Problem', error.message);
          commit('TOGGLE_OVERLAY', false)
          commit('SET_SHOW_DISCOUNT_POPUP', false)
          dispatch('showError', { errorMessage: error.response.data.message || error.message })
          reject(error);
        });
    })
  },
  removeClientProviders({commit, dispatch}, payload) {
    return new Promise((resolve, reject) => {
      api.post(`customer/${payload.customerID}/delete-providers-discount`, {start_date: payload.start_date})
        .then(res => {
          // commit('TOGGLE_OVERLAY', false)
          // commit('SET_SHOW_DISCOUNT_POPUP', false)
          if (res.data !== null) {
            dispatch('showError', {errorMessage: res.data.msg || res.data.message, isSuccess: true})
          }
          resolve(res);
        })
        .catch(error => {
          // commit('TOGGLE_OVERLAY', false)
          // commit('SET_SHOW_DISCOUNT_POPUP', false)
          dispatch('showError', { errorMessage: error.response.data.message || error.message })
          reject(error);
        });
    })
  },
  getClientProvidersFuel({commit, dispatch}, customerID) {
    return new Promise((resolve, reject) => {
      api.get(`customer/${customerID}/get-provider-fuel-types-discount`)
        .then(res => {
          commit(SET_CLIENT_PROVIDERS_FUEL, res.data);
          resolve(res);
        })
        .catch(error => {
          console.log('Problem', error.message);
          dispatch('showError', { errorMessage: error.response.data.message || error.message })
          reject(error);
        });
    })
  },
  setClientProvidersFuel({commit, dispatch}, payload) {
    return new Promise((resolve, reject) => {
      api.post(`customer/${payload.customerID}/edit-customer-fuel-providers-discount`, payload.data)
        .then(res => {
          commit('TOGGLE_OVERLAY', false)
          commit('SET_SHOW_DISCOUNT_FUEL_TYPE_POPUP', false)
          dispatch('showError', { errorMessage: res.data.msg || res.data.message, isSuccess: true })
          resolve(res);
        })
        .catch(error => {
          console.log('Problem', error.message);
          commit('TOGGLE_OVERLAY', false)
          commit('SET_SHOW_DISCOUNT_FUEL_TYPE_POPUP', false)
          dispatch('showError', { errorMessage: error.response.data.message || error.message })
          reject(error);
        });
    })
  },
  removeClientProvidersFuel({commit, dispatch}, payload) {
    return new Promise((resolve, reject) => {
      api.post(`customer/${payload.customerID}/delete-provider-fuel-types-discount`, {start_date: payload.start_date})
        .then(res => {
          // commit('TOGGLE_OVERLAY', false)
          // commit('SET_SHOW_DISCOUNT_POPUP', false)
          if (res.data !== null) {
            dispatch('showError', {errorMessage: res.data.msg || res.data.message, isSuccess: true})
          }
          resolve(res);
        })
        .catch(error => {
          // commit('TOGGLE_OVERLAY', false)
          // commit('SET_SHOW_DISCOUNT_POPUP', false)
          dispatch('showError', { errorMessage: error.response.data.message || error.message })
          reject(error);
        });
    })
  },
  getClientProvidersRegion({commit, dispatch}, customerID) {
    return new Promise((resolve, reject) => {
      api.get(`customer/${customerID}/get-provider-regions-discount`)
        .then(res => {
          commit(SET_CLIENT_PROVIDERS_REGION, res.data);
          resolve(res);
        })
        .catch(error => {
          console.log('Problem', error.message);
          dispatch('showError', { errorMessage: error.response.data.message || error.message })
          reject(error);
        });
    })
  },
  setClientProvidersRegion({commit, dispatch}, payload) {
    return new Promise((resolve, reject) => {
      api.post(`customer/${payload.customerID}/edit-customer-region-providers-discount`, payload.data)
        .then(res => {
          commit('TOGGLE_OVERLAY', false)
          commit('SET_SHOW_DISCOUNT_REGION_POPUP', false)
          dispatch('showError', { errorMessage: res.data.msg || res.data.message, isSuccess: true })
          resolve(res);
        })
        .catch(error => {
          console.log('Problem', error.message);
          commit('TOGGLE_OVERLAY', false)
          commit('SET_SHOW_DISCOUNT_REGION_POPUP', false)
          dispatch('showError', { errorMessage: error.response.data.message || error.message })
          reject(error);
        });
    })
  },
  removeClientProvidersRegion({commit, dispatch}, payload) {
    return new Promise((resolve, reject) => {
      api.post(`customer/${payload.customerID}/delete-provider-regions-discount`, {start_date: payload.start_date})
        .then(res => {
          // commit('TOGGLE_OVERLAY', false)
          // commit('SET_SHOW_DISCOUNT_POPUP', false)
          if (res.data !== null) {
            dispatch('showError', {errorMessage: res.data.msg || res.data.message, isSuccess: true})
          }
          resolve(res);
        })
        .catch(error => {
          // commit('TOGGLE_OVERLAY', false)
          // commit('SET_SHOW_DISCOUNT_POPUP', false)
          dispatch('showError', { errorMessage: error.response.data.message || error.message })
          reject(error);
        });
    })
  },
};

export default actions;
