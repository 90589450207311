import * as type from './types';
import api from '../../../api';
import router from '@/router'
import {getFinalUrl} from "@/utils/url_formatting";

const actions = {
    async getMyUser({commit, dispatch}) {
        return await new Promise((resolve, reject) => {
            api.get(`user/get-user`)
                .then(res => {
                    commit(type.GET_MY_USER, res.data);
                    commit('SET_USER_ROLE', res.data.type)
                    localStorage.setItem('role', res.data.type);
                    resolve(res);
                })
                .catch(error => {
                    console.log('Problem', error.message);
                    dispatch('showError', { errorMessage: error.response.data.message || error.message })
                    reject(error);
                });
        })
    },
    async getUserRoles({commit, dispatch}) {
        return await new Promise((resolve, reject) => {
            api.get(`catalog/get-user-types`)
                .then(res => {
                    commit('SET_ROLES', res.data)
                    resolve(res);
                })
                .catch(error => {
                    console.log('Problem', error.message);
                   dispatch('showError', { errorMessage: error.response.data.message || error.message })
                    reject(error);
                });
        })
    },
    async getUserStatuses({commit, dispatch}) {
        return await new Promise((resolve, reject) => {
            api.get(`catalog/get-user-statuses`)
                .then(async res => {
                    await commit('SET_USER_STATUSES', res.data)
                    resolve(res);
                })
                .catch(error => {
                    console.log('Problem', error.message);
                   dispatch('showError', { errorMessage: error.response.data.message || error.message })
                    reject(error);
                });
        })
    },
    async getCardLimits({commit, dispatch}) {
        return await new Promise((resolve, reject) => {
            api.get(`catalog/get-card-limits`)
                .then(res => {
                    commit('SET_CARD_LIMITS', res.data)
                    resolve(res);
                })
                .catch(error => {
                    console.log('Problem', error.message);
                   dispatch('showError', { errorMessage: error.response.data.message || error.message })
                    reject(error);
                });
        })
    },
    async getFuelTypes({commit, dispatch}) {
        return await new Promise((resolve, reject) => {
            api.get(`catalog/get-fuel-types`)
                .then(res => {
                    commit('SET_FUEL_TYPES', res.data)
                    resolve(res);
                })
                .catch(error => {
                    console.log('Problem', error.message);
                   dispatch('showError', { errorMessage: error.response.data.message || error.message })
                    reject(error);
                });
        })
    },
    async getRegions({commit, dispatch}) {
        return await new Promise((resolve, reject) => {
            api.get(`region-catalog/get-region-all`)
                .then(res => {
                    commit('SET_REGIONS', res.data)
                    resolve(res);
                })
                .catch(error => {
                    console.log('Problem', error.message);
                   dispatch('showError', { errorMessage: error.response.data.message || error.message })
                    reject(error);
                });
        })
    },
    async getFuelProviders({commit, dispatch}) {
        return await new Promise((resolve, reject) => {
            api.get(`catalog/get-fuel-providers`)
                .then(res => {
                    commit('SET_FUEL_PROVIDERS', res.data)
                    resolve(res);
                })
                .catch(error => {
                    console.log('Problem', error.message);
                   dispatch('showError', { errorMessage: error.response.data.message || error.message })
                    reject(error);
                });
        })
    },
    async getStations({commit, dispatch}) {
        return await new Promise((resolve, reject) => {
            api.get(`catalog/get-stations`)
                .then(res => {
                    commit('SET_STATIONS', res.data)
                    resolve(res);
                })
                .catch(error => {
                    console.log('Problem', error.message);
                   dispatch('showError', { errorMessage: error.response.data.message || error.message })
                    reject(error);
                });
        })
    },
    async getAllUsers({commit, dispatch, rootState}, getFile = false) {
        let url = `user/get-all-users`

        let finalUrl = await getFinalUrl(url, rootState)
        return await new Promise(async (resolve, reject) => {
            if (getFile) {
              url = `user/get-all-users?returnFile=true`
                finalUrl = await getFinalUrl(url, rootState, false)
              let dateNow = new Date();
              let dd = String(dateNow.getDate()).padStart(2, '0');
              let mm = String(dateNow.getMonth() + 1).padStart(2, '0'); //January is 0!
              let yyyy = dateNow.getFullYear();
              api.get(`${finalUrl}`, {responseType: 'blob'})
                .then(res => {
                  const url = window.URL.createObjectURL(new Blob([res.data]));
                  const link = document.createElement('a');
                  link.href = url;
                  link.setAttribute('download', `пользователи--${dd + '.' + mm + '.' + yyyy}.xlsx`); //or any other extension
                  document.body.appendChild(link);
                  link.click();
                  link.remove();
                  resolve(res);
                })
                .catch(error => {
                  console.log('Problem', error.message);
                  dispatch('showError', { errorMessage: error.response.data.message || error.message })
                  reject(error);
                });
            } else {
              api.get(finalUrl)
                .then(res => {
                  commit('GET_ALL_USERS', res.data);
                  resolve(res);
                })
                .catch(error => {
                  console.log('Problem', error.message);
                  dispatch('showError', {errorMessage: error.response.data.message || error.message})
                  reject(error);
                });
            }
        })
    },
    logout({commit, dispatch}) {
        return new Promise((resolve, reject) => {
            commit(type.SET_TOKEN, '');
            localStorage.removeItem('token');
            localStorage.removeItem('role');
            setTimeout(() => {
                resolve(true);
            }, 100)
        })
    },
    async login({commit, dispatch}, data) {
        console.log(data)
        return await new Promise((resolve, reject) => {
            api.post('user/login', data)
                .then(res => {
                    if (res.data.status && res.data.status === 200) {
                        commit(type.SET_TOKEN, res.data.token);
                        localStorage.setItem('token', res.data.token);
                        dispatch('getMyUser');
                        router.push({ name: 'dashboard' })
                    } else {
                        dispatch('showError', { errorMessage: res.data.msg || res.data.message })
                    }
                    resolve(res);
                })
                .catch(error => {
                    console.log('Problem', error.message);
                   dispatch('showError', { errorMessage: error.response.data.message || error.message })
                    reject(error);
                });
        })
    },
    async editUser({commit, dispatch}, data) {
        console.log(data)
        let id = data.id
        return await new Promise((resolve, reject) => {
            api.put(`user/${id}/edit-user`, data.userData)
                .then(res => {
                    if (res.data.status && res.data.status === 200) {
                        commit('SHOW_EDIT_USER', false)
                        dispatch('showError', { errorMessage: res.data.msg || res.data.message, isSuccess: true })
                        dispatch('getAllUsers');
                      commit('SET_USER', null);
                    } else {
                        commit('SHOW_EDIT_USER', false)
                        dispatch('showError', { errorMessage: res.data.msg || res.data.message })
                      commit('SET_USER', null);
                    }
                    resolve(res);
                })
                .catch(error => {
                    console.log('Problem', error.message);
                    commit('SHOW_EDIT_USER', false)
                   dispatch('showError', { errorMessage: error.response.data.message || error.message })
                  commit('SET_USER', null);
                    reject(error);
                });
        })
    },
    async createUser({commit, dispatch}, data) {
        console.log(data)
        return await new Promise((resolve, reject) => {
            api.post(`user/create-user`, data, {
              headers: { "Content-Type": "multipart/form-data" }
            })
                .then(res => {
                    if (res.data.status && res.data.status === 200) {
                        commit('SHOW_ADD_NEW_USER', false)
                        dispatch('showError', { errorMessage: res.data.msg || res.data.message, isSuccess: true })
                        dispatch('getAllUsers');
                      commit('SET_USER', null);
                    } else {
                        commit('SHOW_ADD_NEW_USER', false)
                        dispatch('showError', { errorMessage: res.data.msg || res.data.message })
                      commit('SET_USER', null);
                    }
                    resolve(res);
                })
                .catch(error => {
                    console.log('Problem', error.message);
                    commit('SHOW_ADD_NEW_USER', false)
                   dispatch('showError', { errorMessage: error.response.data.message || error.message })
                  commit('SET_USER', null);
                    reject(error);
                });
        })
    },
    async removeUser({commit, dispatch}, id) {
        return await new Promise((resolve, reject) => {
            api.delete(`user/${id}/delete-user`)
                .then(res => {
                    if (res.data.status && res.data.status === 200) {
                        commit('SHOW_REMOVE_USER', false)
                        dispatch('showError', { errorMessage: res.data.msg || res.data.message, isSuccess: true })
                        dispatch('getAllUsers');
                    } else {
                        commit('SHOW_REMOVE_USER', false)
                        dispatch('showError', { errorMessage: res.data.msg || res.data.message })
                    }
                    resolve(res);
                })
                .catch(error => {
                    console.log('Problem', error.message);
                    commit('SHOW_REMOVE_USER', false)
                   dispatch('showError', { errorMessage: error.response.data.message || error.message })
                    reject(error);
                });
        })
    },
    async resetUserPassword({commit, dispatch}, data) {
        return await new Promise((resolve, reject) => {
            api.post(`user/reset-password`, data)
                .then(res => {
                    if (res.data.status && res.data.status === 200) {
                        commit('SHOW_RESET_PASSWORD', false)
                        dispatch('showError', { errorMessage: res.data.msg || res.data.message, isSuccess: true })
                        dispatch('getAllUsers');
                    } else {
                        commit('SHOW_RESET_PASSWORD', false)
                        dispatch('showError', { errorMessage: res.data.msg || res.data.message })
                    }
                    resolve(res);
                })
                .catch(error => {
                    console.log('Problem', error.message);
                    commit('SHOW_RESET_PASSWORD', false)
                   dispatch('showError', { errorMessage: error.response.data.message || error.message })
                    reject(error);
                });
        })
    },
    async forgotPassword({commit, dispatch}, data) {
        console.log(data)
        return await new Promise((resolve, reject) => {
            api.post('user/forgot-password', data)
                .then(res => {
                    if (res.data.status && res.data.status === 200) {
                        commit(type.SET_TOKEN, '');
                        localStorage.removeItem('token');
                      localStorage.removeItem('role');
                    } else {
                        dispatch('showError', { errorMessage: res.data.msg || res.data.message })
                    }
                    resolve(res);
                })
                .catch(error => {
                    console.log('Problem', error.message);
                   dispatch('showError', { errorMessage: error.response.data.message || error.message })
                    reject(error);
                });
        })
    },
};

export default actions;
