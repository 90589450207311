<template>
  <div class="page-container">
    <div class="page-header">
      <div class="title" >
        <img src="../assets/warning.svg" alt="icon">
        <span class="text">
            404 - Страница не найдена
        </span>
      </div>
    </div>
    <div class="page-content">
      <div class="not-found">
        <h1>404</h1>
        <div class="wrapper">
          <span class="title">Страница не найдена</span>
          <div class="info">
            <div class="text">
              Страницу которую вы искали, не найдена
              Пожалуйста, вернитесь на главную страницу!
            </div>
            <button @click="goToMainPage">На главную</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "NotFound",
  computed: {},
  methods: {
    goToMainPage() {
      this.$router.push({name: 'main'})
    },
  },
}
</script>

<style scoped lang="scss">
@import "../styles/variables";

.page-container {
  padding-left: 0;
  padding-right: 0;
  width: 100%;
}

.page-content {
  background-size: contain;
  background: url("../assets/page_not_found.svg") no-repeat center;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;

  ackground-position-y: -100px;
  background-size: 106vw;
}
.not-found {
  display: inline-flex;
  flex-direction: column;
  align-items: center;

  width: 502px;

  h1 {
    color: $black;
    font-family: SF_Pro_Bold, Arial, sans-serif;
    font-size: 128px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin: 0;
  }

  .wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;

    .title {
      color: $black;
      font-family: SF_Pro_SemiBold, Arial, sans-serif;
      font-size: 48px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: 1px;
    }
    .info {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 60px;

      .text {
        text-align: center;
        font-family: SF_Pro_Medium, Arial, sans-serif;
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }

      button {
        display: flex;
        padding: 13px 34px;
        align-items: flex-start;
        gap: 10px;
        border-radius: 12px;
        border: 1px solid $green;
        background: $back;

        color: $green;
        font-family: SF_Pro_SemiBold, Arial, sans-serif;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
    }
  }
}

</style>
