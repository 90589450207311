<template>
  <div class="login-page">
    <div class="form" :class="{'forgot-password': forgotPassword}">
      <button v-if="forgotPassword" @click="setForgotPassword(false)" class="back-btn">
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M12.6667 8L3.33333 8" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M7.33333 12L3.33333 8L7.33333 4" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        Назад
      </button>
      <div class="form__top">
        <img src="../assets/logo.svg" alt="logo">
        <span v-if="!forgotPassword">Войдите в свой аккаунт</span>
        <span v-else>Забыли пароль?</span>
      </div>
      <div v-if="!forgotPassword" class="form__bottom">
        <form @submit="submit">
          <div class="inputs">
            <div class="login">
              <label for="login">Email</label>
              <input placeholder="mail@gmail.com"
                     :class="{'invalid': !emailValid}"
                     type="email"
                     id="login"
                     v-model="email"
                     @focus:model-value="emailValid.value = true"
                     @update:model-value="validateEmail(email)"
                     @blur="validateEmail(email)"
              >
            </div>
            <div class="password">
              <label for="password">Пароль</label>
              <input :type="passwordFieldType" v-model="password" id="password">
              <button type="button" class="password-visibility-switcher" @click="switchVisibility">
                <img v-if="passwordFieldType === 'password'" src="../assets/visibility_off_icon.svg" alt="visible">
                <img v-else src="../assets/visibility_icon.svg" alt="hidden">
              </button>
            </div>
          </div>

          <div class="remember-me">
            <input type="checkbox" id="remember">
            <label for="remember">Запомнить меня</label>
          </div>

          <button :disabled="!password || !email || !emailValid" class="submit-btn" type="submit">Войти</button>

          <button class="forgot-btn" type="button" @click="setForgotPassword(true)">Забыли пароль?</button>

        </form>
      </div>
      <div v-else class="form__bottom">
        <form @submit="submitEmail">
          <span>Письмо с кодом подтверждения будет отправлено на Ваш email:</span>
          <div class="inputs">
            <div class="login">
              <label :class="{'invalid': !emailValid}" for="login">Email</label>
              <input placeholder="mail@gmail.com"
                     :class="{'invalid': !emailValid}"
                     type="email"
                     id="login"
                     v-model="email"
                     @focus:model-value="emailValid.value = true"
                     @update:model-value="validateEmail(email)"
                     @blur="validateEmail(email)"
              >
            </div>
          </div>
          <button :disabled="!email || !emailValid" class="submit-btn" type="submit">Отправить</button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapMutations, mapState} from "vuex";
import {validation} from '@/mixins/validation'
import {markRaw} from "vue";
export default {
  name: "Login",
  mixins: [validation],
  data: () => ({
    email: '',
    password: '',
    emailValid: true,
    passwordFieldType: 'password',
    forgotPassword: false

  }),
  computed: {
    ...mapState({
      token: state => state.user.token,
    }),
  },
  methods: {
    ...mapMutations({
      setToken: 'SET_TOKEN'
    }),
    ...mapActions({
      login: 'login',
      getMyUser: 'getMyUser',
      sendForgotPassword: 'forgotPassword',
    }),
    setForgotPassword(val) {
      this.forgotPassword = val
      this.email = ''
      this.emailValid = true
    },
    switchVisibility() {
      this.passwordFieldType = this.passwordFieldType === "password" ? "text" : "password";
    },
    /*validateEmail() {
      console.log('validating', this.email)
      let reg = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/gm;
      this.emailValid = reg.test(this.email);
    },*/
    async submitEmail(e) {
      e.preventDefault()
      await this.sendForgotPassword({
        email: this.email
      }).then(res => {
        console.log(res)
      }).catch(err => {
        console.log(err)
      })
      this.forgotPassword = false
      this.email = ''
    },
    logout() {
      this.setToken('')
      localStorage.removeItem('token')
      localStorage.removeItem('role');
      this.$router.push('login')
    },
    async submit(e) {
      e.preventDefault()
      let data = {
        login: this.email,
        password: this.password,
      }
      await this.login(data).then(async res => {
        console.log(res)
        await this.getMyUser().then(login => {
          if (login.data.type === 1) {
            this.$router.push('/admin-dashboard/executive')
          }
          if (login.data.type === 2 || login.data.type === 3) {
            this.$router.push('/dashboard')
          }
        }).catch(e => {
          if (e.response.data.status === 401) {
            this.logout();
          }
        });
      }).catch(err => {
        console.log(err)
      })
      //this.setToken('mock_token')
      //localStorage.setItem('token', 'mock_token')
      // this.$router.push('/dashboard')
    }
  },
}
</script>

<style scoped lang="scss">
@import "../styles/variables";

.login-page {
  position: relative;
  display: flex;
  width: auto;//1440px;
  min-width: 100vh;
  height: auto;//881px;
  min-height: 100vh;
  //padding: 147px 440px 148px 440px;
  justify-content: center;
  align-items: center;
  background: linear-gradient(0deg, rgba(20, 24, 31, 0.48) 0%, rgba(20, 24, 31, 0.48) 100%), url('../assets/login_background.png');
  background-size: cover;
  background-repeat: no-repeat;

  .form {
    position: relative;
    //width: 560px;
    //height: 586px;
    flex-shrink: 0;
    border-radius: 6px;
    padding: 60px;
    border: 0.5px solid var(--light-mode-neutral-150, #EAEAEF);
    background: var(--light-mode-neutral-0, #FFF);

    /* Light mode/Table shadow */
    box-shadow: 0px 1px 4px 0px rgba(33, 33, 52, 0.10);

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .back-btn {
      border-radius: 6px;
      border: 1px solid rgba(255, 255, 255, 0.24);
      display: flex;
      padding: 8px 16px 8px 8px;
      align-items: flex-start;
      gap: 5px;
      background: transparent;

      color: #FFFFFF;
      text-align: center;
      font-feature-settings: 'clig' off, 'liga' off;
      font-family: SF_Pro_Medium, Arial, sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px; /* 114.286% */

      position: absolute;
      left: 0;
      top: -50px;
    }


    &.forgot-password {
      position: absolute;
      top: 100px;
    }

    &__top {
      display: inline-flex;
      flex-direction: column;
      align-items: center;
      gap: 25px;

      margin-bottom: 60px;

      span {
        color: $grey;
        text-align: center;
        font-feature-settings: 'clig' off, 'liga' off;

        /* Epsilon - Subtitle */
        font-family: SF_Pro_Regular, Arial, sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 150% */
      }
    }

    &__bottom {
      form {
        display: inline-flex;
        flex-direction: column;
        align-items: center;
        gap: 24px;

        .inputs {
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 24px;

          .login, .password {
            display: flex;
            width: 440px;
            flex-direction: column;
            align-items: flex-start;
            gap: 4px;

            input {
              display: flex;
              padding: 10px 16px;
              justify-content: center;
              align-items: center;
              gap: 8px;
              align-self: stretch;
              border-radius: 4px;
              border: 1px solid #DCDCE4;
              background: #FFF;

              color: $grey;
              font-feature-settings: 'clig' off, 'liga' off;
              font-family: SF_Pro_Regular, Arial, sans-serif;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 20px; /* 142.857% */
            }
          }

          .password {
            position: relative;
          }

          .password-visibility-switcher {
            background: none;
            border: none;
            position: absolute;
            right: 2px;
            bottom: 4px;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 10px;
            img {
              //height: 16px;
              width: 18px;
            }
          }
        }

        .remember-me {
          display: flex;
          align-items: center;
          gap: 8px;
          align-self: stretch;

          #remember {
            width: 20px;
            height: 20px;
          }

          label {
            color: #32324D;
            font-feature-settings: 'clig' off, 'liga' off;
            font-family: SF_Pro_Regular, Arial, sans-serif;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px; /* 142.857% */
          }
        }

        .submit-btn {
          display: flex;
          width: 440px;
          padding: 12px 16px;
          justify-content: center;
          align-items: center;
          gap: 8px;
          border-radius: 4px;
          background: #4945FF;
          border: none;

          color: #FFF;
          font-feature-settings: 'clig' off, 'liga' off;
          font-family: SF_Pro_Bold, Arial, sans-serif;
          font-size: 14px;
          font-style: normal;
          font-weight: 700;
          line-height: 16px; /* 114.286% */

          &:disabled {
            background: $grey;
          }
        }

        .forgot-btn {
          //color: #4945FF;
          text-align: center;
          font-feature-settings: 'clig' off, 'liga' off;

          /* Sigma - Table Label */
          font-family: SF_Pro_Bold, Arial, sans-serif;
          font-size: 11px;
          font-style: normal;
          font-weight: 700;
          line-height: 16px; /* 145.455% */
          text-transform: uppercase;

          border: none;
          background: none;
        }
      }
    }
  }
}
</style>
