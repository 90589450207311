<template>
  <div class="popup wide">
    <h4 class="popup__title middle">Установить скидки для типов топлива</h4>

    <div class="modal-remove" v-if="removeDate !== null">
      <div class="modal-remove__overlay" @click="removeDate = null"></div>
      <div class="modal-remove__content">
        <p>Вы действительно хотите удалить скидки за {{ removeDate }}?</p>

        <div class="actions">
          <button class="button btn-primary" @click="removeDate = null">Нет</button>
          <button class="button btn-primary" @click="setRemove()">Да</button>
        </div>
      </div>
    </div>

    <div class="discount-block">
      <ul>
        <li>
          <button class="btn-primary export-btn" @click="setNew = true;setNewDate()">
            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M12.25 7H1.75" stroke="#6DB534" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M7 1.75V12.25" stroke="#6DB534" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            Добавить дату
          </button>
        </li>
        <li v-for="(date, index) of datesList">
          <button class="discount-block__btn-date" @click="setItem(date, index);setNew = false" :class="{ 'active-date': date.active }">
            {{ date.name }}
            <button @click.stop="removeItem(date)">
              <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6.42857 5.25V3.6C6.42857 3.24196 6.56403 2.89858 6.80515 2.64541C7.04627 2.39223 7.37329 2.25 7.71429 2.25H10.2857C10.6267 2.25 10.9537 2.39223 11.1949 2.64541C11.436 2.89858 11.5714 3.24196 11.5714 3.6V5.25M14.25 5.25L13.5 14.4C13.5 14.758 13.3645 15.1014 13.1234 15.3546C12.8823 15.6078 12.5553 15.75 12.2143 15.75H5.78571C5.44472 15.75 5.1177 15.6078 4.87658 15.3546C4.63546 15.1014 4.5 14.758 4.5 14.4L3.75 5.25H14.25Z" stroke="#6DB534" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M3 5.25H4.33333H15" stroke="#6DB534" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </button>
          </button>
        </li>
      </ul>
      <div class="form">
        <div class="date-picker">
          <div class="wrap">
            <span class="date-picker-label">Выбора даты</span>

            <VueDatePicker
              ref="dateRange"
              v-model="date"
              :enable-time-picker="false"
              :format="'dd.MM.yyyy'"
              locale="ru"
              cancelText="Отмена"
              selectText="Выбрать"
              :clearable="false"
              :teleport="true"
              :disabled="!setNew"
              @update:model-value="formatingDate('surety')"
            >
              <template #input-icon>
                <img class="input-slot-image" src="../../assets/datepicker-icon.svg" alt="d"/>
              </template>
            </VueDatePicker>
          </div>
        </div>
        <div class="discount-item" v-for="(item, index) of providersList">
          <div class="form__item">
            <div class="label">
            </div>
            <div class="input">
              <v-select :options="fuelProviders"
                        label="name"
                        v-model="item.fuel_provider_id"
                        :clearable="false"
                        :reduce="item => item.id"
                        :searchable="false"
                        :appendToBody="true"
              ></v-select>
            </div>
          </div>
          <div class="form__item">
            <div class="label">
            </div>
            <div class="input">
              <v-select :options="fuelTypes"
                        label="name"
                        v-model="item.fuel_type_id"
                        :clearable="false"
                        :reduce="item => item.id"
                        :searchable="false"
                        :appendToBody="true"
              ></v-select>
            </div>
          </div>
          <div class="form__item">
            <div class="label">
            </div>
            <div class="input">
              <input type="number" v-model="item.value">
            </div>
          </div>
          <button @click="removeItemProvider(index)">
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M6.42857 5.25V3.6C6.42857 3.24196 6.56403 2.89858 6.80515 2.64541C7.04627 2.39223 7.37329 2.25 7.71429 2.25H10.2857C10.6267 2.25 10.9537 2.39223 11.1949 2.64541C11.436 2.89858 11.5714 3.24196 11.5714 3.6V5.25M14.25 5.25L13.5 14.4C13.5 14.758 13.3645 15.1014 13.1234 15.3546C12.8823 15.6078 12.5553 15.75 12.2143 15.75H5.78571C5.44472 15.75 5.1177 15.6078 4.87658 15.3546C4.63546 15.1014 4.5 14.758 4.5 14.4L3.75 5.25H14.25Z" stroke="#6DB534" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M3 5.25H4.33333H15" stroke="#6DB534" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </button>
        </div>
        <button class="btn-primary export-btn" @click="addItemProvider()">
          <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" style="margin-right: 10px">
            <path d="M12.25 7H1.75" stroke="#6DB534" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M7 1.75V12.25" stroke="#6DB534" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
          Добавить скидку
        </button>
      </div>
    </div>

    <div class="actions">
      <button class="button btn-primary" @click="closeAll()">Отмена</button>
      <button class="button btn-primary" @click="saveDiscount()">Сохранить</button>
    </div>
  </div>
</template>

<script>

import {popups} from '@/mixins/popups'
import {mapActions, mapState} from "vuex";
import VueDatePicker from "@vuepic/vue-datepicker";
import vSelect from "vue-select";

export default {
  name: "setDiscountFuelType",
  components: {vSelect, VueDatePicker},
  mixins: [popups],
  data: () => ({
    providersList: [],
    datesList: [],
    date: null,
    dateModel: null,
    setNew: true,
    removeDate: null
  }),
  computed: {
    ...mapState({
      fuelProviders: state => state.user.fuelProviders,
      fuelTypes: state => state.user.fuelTypes,
      clientProviders: state => state.customer.clientProvidersFuel,
      customer: state => state.popups.customer,
    })
  },
  methods: {
    ...mapActions({
      getClientProvidersFuel: 'getClientProvidersFuel',
      setClientProvidersFuel: 'setClientProvidersFuel',
      removeClientProvidersFuel: 'removeClientProvidersFuel',
    }),
    removeItemProvider(index) {
      this.providersList.splice(index, 1)
    },
    addItemProvider() {
      this.providersList.push({
        fuel_provider_id: null,
        fuel_type_id: null,
        value: ''
      })
    },
    formatingDate(type) {
      this.dateModel = `${this.date.getFullYear()}-${String(this.date.getMonth() + 1).padStart(2, '0')}-${String(this.date.getDate()).padStart(2, '0')}`;
    },
    saveDiscount() {
      console.log('saveDiscount', this.providersList)
      let data = {
        start_date: this.dateModel,
        data: []
      };
      if (!this.setNew) {
        let dateSplit = this.date.split('.')
        data.start_date = `${dateSplit[0]}-${dateSplit[1]}-${dateSplit[2]}`;
      }
      this.providersList.forEach(item => {
        if (item.value !== null && item.value !== '') {
          data.data.push({
            fuel_provider_id: item.fuel_provider_id,
            id: item.fuel_provider_id,
            fuel_type_id: item.fuel_type_id,
            value: item.value
          })
        }
      })
      this.setClientProvidersFuel({customerID: this.customer.id, data})
    },
    setNewDate() {
      this.date = null;
      this.providersList = [{
        fuel_provider_id: null,
        fuel_type_id: null,
        value: ''
      }]
    },
    setItem(data, index) {
      this.datesList.forEach((item, i) => {
        item.active = index === i;
      })
      let dateSplit = data.name.split('.')
      this.date = `${dateSplit[2]}.${dateSplit[1]}.${dateSplit[0]}`;
      this.providersList.forEach(provider => {
        provider.value = '';
      })
      this.providersList = []
      data.arr.forEach(item => {
        this.providersList.push({
          fuel_provider_id: item.fuel_provider_id,
          fuel_type_id: item.fuel_type_id,
          value: item.discount
        })
      })
    },
    removeItem(date) {
      this.removeDate = date.name
    },
    setRemove() {
      let dateSplit = this.removeDate.split('.')
      let data = {
        customerID: this.customer.id,
        start_date: `${dateSplit[0]}-${dateSplit[1]}-${dateSplit[2]}`,
      }
      this.removeClientProvidersFuel(data).then(res => {
        this.setItems();
      });
      this.removeDate = null;
    },
    setItems() {
      this.getClientProvidersFuel(this.customer.id).then(res => {
        this.providersList = [{
          fuel_provider_id: null,
          fuel_type_id: null,
          value: ''
        }];
        // this.fuelProviders.forEach((item, index) => {
        //   this.providersList.push({
        //     id: item.id,
        //     name: item.name,
        //     value: ''
        //   })
        // })
        let arr = [];
        Object.keys(this.clientProviders).map((key) => {
          let keySplit = key.split('-')
          let date = `${keySplit[2]}.${keySplit[1]}.${keySplit[0]}`
          arr.push({
            name: date,
            arr: this.clientProviders[key],
            active: false
          })
        })
        this.datesList = arr;
      })
    }
  },
  mounted() {
    this.setItems();
  }
}
</script>

<style lang="scss">
.form__item {
  .vs__dropdown-toggle {
    overflow: hidden;
  }
}
</style>

<style scoped lang="scss">
@import "../../styles/variables";
.left, .right {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 2px;
}

.wide {
  max-width: unset;
  width: auto;
  max-height: 80%;

  .form {
    flex-direction: unset;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 560px;
    max-height: calc(100vh - 330px);
    overflow-y: auto;
    overflow-x: hidden;
    gap: 6px;
  }
}

.discount-block {
  display: flex;

  & > ul {
    min-width: 170px;
    margin: 0 20px 0 0;
    padding: 0;
    list-style: none;

    li {
      width: 100%;
      margin-bottom: 5px;

      &:first-child {
        margin-bottom: 20px;

        button {
          justify-content: flex-start;
          svg {
            margin-right: 10px;
          }
        }
      }

      & > button {
        width: 100%;
      }
    }
  }

  &__btn-date {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 40px;
    padding: 10px;
    border-radius: 6px;
    border: 1px solid #F1F8EB;
    background: none;
    text-align: left;
    transition: all ease .3s;

    &:hover {
      background: #F1F8EB;
    }

    &.active-date {
      background: #F1F8EB;
    }

    button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 25px;
      height: 25px;
      margin: 0;
      padding: 3px 4px 3px 3px;
      background: none;
      border: none;
      border-radius: 6px;
      transition: all ease .3s;

      &:hover {
        background: #85ff8e;
      }
    }
  }
}

.date-picker {
  display: flex;
  align-items: center;
  gap: 30px;
  width: 100%;
  align-self: stretch;

  .date-picker-label {
    display: flex;
    align-items: center;
    white-space: nowrap;
    min-width: 40%;
    padding: 0 10px;
  }


  .wrap {
    gap: 10px;
    display: flex;
    justify-content: space-between;
    width: 100%;

    color: $grey-2;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: SF_Pro_Medium, Arial, sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 14px; /* 116.667% */
    text-align: left;

    .dp__input {
      border-radius: 6px;
      border: 1px solid $back !important;
      --dp-border-color: $back !important;
    }
  }
}

.modal-remove {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  z-index: 11;

  &__overlay {
    width: 100%;
    height: 100%;
    background: $black;
    opacity: 0.3;
    position: fixed;
    z-index: 9;
    top: 0;
    left: 0;
    transition: none!important;
    transform: none!important;
  }

  &__content {
    position: relative;
    border-radius: 12px;
    background: #FFFFFF;
    box-shadow: 0 2px 4px 0 rgba(109, 181, 52, 0.30);
    padding: 30px;
    z-index: 10;
  }
}

.discount-item {
  display: flex;
  align-items: center;
  width: 100%;

  .form__item {
    width: calc((100% - 4px - 25px - 50px) / 2);
    .label {
      width: 0;
      padding: 0;
    }

    .input {
      width: 100%;
      input {
        max-width: 100% !important;
        text-align: center;
      }
    }

    &:nth-child(3) {
      width: 75px;
    }
  }

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 25px;
    height: 25px;
    margin: 0;
    padding: 3px 4px 3px 3px;
    background: none;
    border: none;
    border-radius: 6px;
    transition: all ease .3s;

    &:hover {
      background: #85ff8e;
    }
  }
}
</style>
