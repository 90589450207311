<template>
  <div class="popup">
    <span v-if="loading" class="loader">
      <svg class="circular" height="50" width="50">
        <circle class="path" cx="25" cy="25.2" r="19.9" fill="none" stroke-width="6" stroke-miterlimit="10" />
      </svg>
    </span>
    <h4 class="popup__title">{{ setEditCardDataPopup !== null ? 'Редактировать' : 'Добавить' }} вручную топливную карту</h4>

    <div class="form">
<!--      <div class="form__item">
        <div class="label">
          №
        </div>
        <div class="input">
          <input type="text" v-model="cardEdit.number">
        </div>
      </div>-->
<!--      <div class="form__item">-->
<!--        <div class="label">-->
<!--          АЗС-->
<!--        </div>-->
<!--        <div class="input">-->
<!--          <input type="text" v-model="cardEdit.brand">-->
<!--        </div>-->
<!--      </div>-->
      <div class="form__item">
        <div class="label">
          Интеграция
        </div>
        <div class="input">
          <v-select :options="integrations"
                    label="name"
                    v-model="cardEdit.integration"
                    :reduce="option => option.id"
                    :clearable="false"
                    :searchable="false"
          ></v-select>
        </div>
      </div>
      <div class="form__item">
        <div class="label">
          ВИНК
        </div>
        <div class="input">
          <v-select :options="fuelProviders"
                    label="name"
                    v-model="cardEdit.fuel_provider_id"
                    :clearable="false"
                    :searchable="false"
          ></v-select>
        </div>
      </div>
      <div class="form__item">
        <div class="label">
          Номер карты
        </div>
        <div class="input">
          <input type="text" v-model="cardEdit.number">
        </div>
      </div>
      <div class="form__item">
        <div class="label">
          Период действия
        </div>
        <div class="input">
          <VueDatePicker
              v-model="cardEdit.expired_at"
              :enable-time-picker="false"
              format="dd.MM.yyyy"
              locale="ru"
              cancelText="Отмена"
              selectText="Выбрать"
          />
        </div>
      </div>
      <div class="form__item">
        <div class="label">
          Статус
        </div>
        <div class="input">
          <v-select :class="{'green': cardEdit.status.id === 2, 'red': cardEdit.status.id === 1}"
                    :options="cardStatuses"
                    label="name"
                    v-model="cardEdit.status"
                    :clearable="false"
                    :searchable="false"
          ></v-select>
        </div>
      </div>
      <div class="form__item">
        <div class="label">
          Клиент
        </div>
        <div class="input">
<!--          <input type="text" v-model="cardEdit.customer">-->
          <v-select :options="customersOptions"
                    label="name"
                    v-model="cardEdit.customer"
                    @search="fetchOptions"
          >
            <template #no-options>
              Введите имя пользователя
            </template>
<!--            <template #spinner>-->
<!--              <div v-if="spinner"></div>-->
<!--            </template>-->
          </v-select>
        </div>
      </div>
<!--      <div class="form__item">
        <div class="label">
          Водитель
        </div>
        <div class="input">
          <input type="text" v-model="cardEdit.driver">
        </div>
      </div>-->
      <div class="form__item">
        <div class="label">
          Держатель
        </div>
        <div class="input">
          <input type="text" v-model="cardEdit.holder">
        </div>
      </div>
      <div class="form__item">
        <div class="label">
          PIN-код
        </div>
        <div class="input">
          <input type="text" v-model="cardEdit.pin">
        </div>
      </div>
    </div>

    <div class="actions">
      <button class="button btn-primary" @click="closeAll()">Отмена</button>
      <button :disabled="disableSubmitButton" class="button btn-primary" @click="createNewCard()">Сохранить</button>
    </div>
  </div>
</template>

<script>

import {popups} from '@/mixins/popups'
import vSelect from 'vue-select'

import 'vue-select/dist/vue-select.css';
import {mapActions, mapMutations, mapState} from "vuex";
import VueDatePicker from "@vuepic/vue-datepicker";
export default {
  name: "addCardPopup",
  mixins: [popups],
  components: {
    VueDatePicker,
    vSelect
  },
  data: () => ({
    loading: false,
    spinner: true,
    cardEdit: {
      number: '',
      brand: '',
      fuel_provider_id: '',
      expired_at: '',
      status: { name: "Активна", id: 2 },
      customer: '',
      pin: '',
      holder: '',
      integration: null
    },
    statusOptions: [
      { title: "Активна", id: 2 },
      { title: "Заблокирована", id: 1 }
    ],
    // integrations: [
    //   {
    //     id: 1,
    //     name: 'Delko SmartOil'
    //   },
    //   {
    //     id: 2,
    //     name: 'Delko Синергия'
    //   },
    // ],
  }),
  computed: {
    ...mapState({
      card: state => state.popups.card,
      setEditCardDataPopup: state => state.popups.setEditCardDataPopup,
      customers: state => state.admin.customers,
      cardStatuses: state => state.admin.cardStatuses,
      managers: state => state.admin.managers,
      fuelProviders: state => state.user.fuelProviders,
      integrations: state => state.admin.integrations,
    }),
    customersOptions() {
      let customers = []
      if (this.managers && this.managers.length > 0) {
        for (let i = 0; i < this.managers.length; i++) {
          let customer = this.managers[i]
          customers.push(customer)
        }
      }
      return customers
    },
    disableSubmitButton() {
      return !this.cardEdit.number ||
          !this.cardEdit.fuel_provider_id ||
          !this.cardEdit.expired_at ||
          !this.cardEdit.holder ||
          !this.cardEdit.status ||
          !this.cardEdit.customer ||
          !this.cardEdit.pin ||
          !this.cardEdit.integration
    }
  },
  methods: {
    ...mapActions({
      addNewCard: 'addNewCard',
      editCard: 'editCard',
      getCustomers: 'getCustomers',
      getUsersSearch: 'getUsersSearch',
      getCardStatuses: 'getCardStatuses',
    }),
    ...mapMutations({
      setColumns: 'SET_COLUMNS',
    }),
    async fetchOptions (search, loading) {
      if (search.length >= 3) {
        console.log(search)
        this.spinner = true
        loading(true)
        await this.getUsersSearch({
          search,
          type: 3
        })

        // this.setColumns([
        //   {
        //     name: 'Имя или название организации',
        //     field: 'name',
        //     filter_name: 'customer_user.name',
        //     filter_value: search,
        //     order: ''
        //   }
        // ])

        // let args = [
        //   {
        //     name: 'filter[customer_user.name]',
        //     value: search
        //   }
        // ]
        // await this.getCustomers()
        loading(false)
        //this.spinner = false
      }

    },
    async createNewCard() {
      this.loading = true

      let cardData = {
        number: this.cardEdit.number,
        // brand: this.cardEdit.brand,
        fuel_provider_id: this.cardEdit.fuel_provider_id.id,
        // expired_at: this.cardEdit.expired_at.toLocaleDateString(),
        status_id: this.cardEdit.status.id,
        customer_id: this.cardEdit.customer.customer_id,
        pin: this.cardEdit.pin,
        holder: this.cardEdit.holder,
        integration: this.cardEdit.integration,
      }

      if (typeof this.cardEdit.expired_at === 'string') {
        cardData.expired_at = this.cardEdit.expired_at
      } else {
        cardData.expired_at = this.cardEdit.expired_at.toLocaleDateString()
      }

      // if (this.setEditCardDataPopup === null) {
      //   cardData.expired_at = this.cardEdit.expired_at.toLocaleDateString()
      //   cardData.number = this.cardEdit.number
      //   cardData.status_id = this.cardEdit.status.id
      //   // cardData.customer_id = this.cardEdit.customer.customer_id
      // }

      if (this.setEditCardDataPopup !== null) {
        await this.editCard({id: this.setEditCardDataPopup.id, data: cardData})
      } else {
        await this.addNewCard(cardData)
      }

      this.loading = false
      //this.closeAll()
    }
  },
  mounted() {
    if (this.setEditCardDataPopup !== null) {
      this.cardEdit.number = this.setEditCardDataPopup.number;
      this.cardEdit.expired_at = this.setEditCardDataPopup.expired_at;
      this.cardEdit.status = this.setEditCardDataPopup.status;
      this.cardEdit.integration = this.setEditCardDataPopup.integration_id;
      this.cardEdit.customer = {
        id: this.setEditCardDataPopup.customer.id,
        name: this.setEditCardDataPopup.customer.user.name
      };
      this.cardEdit.pin = this.setEditCardDataPopup.pin;
      this.cardEdit.holder = this.setEditCardDataPopup.holder;
      this.fuelProviders.forEach(provider => {
        if (provider.id === this.setEditCardDataPopup.fuel_provider_id) {
          this.cardEdit.fuel_provider_id = {
            id: this.setEditCardDataPopup.fuel_provider_id,
            name: provider.name
          }
        }
      })
    }
  }
}
</script>

<style lang="scss">
.input .v-select .vs__search {
  pointer-events: none;
}
</style>

<style scoped lang="scss">
@import "../../styles/variables";
.dp__input {
  border-radius: 6px;
  border: 1px solid $back !important;
  --dp-border-color: $back !important;
}

.popup .form__item .label {
  min-width: 120px;
}
</style>
