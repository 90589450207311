import * as type from './types';
import api from '../../../api';
import {GET_REGIONS_REFERENCES} from "./types";

const actions = {
    async getFuelProviderReferences({commit, dispatch}) {
        return await new Promise((resolve, reject) => {
            api.get(`fuel-provider-mapping/get-fuel-provider-all`)
                .then(res => {
                    commit(type.GET_FUEL_PROVIDER_REFERENCES, res.data);
                    resolve(res);
                })
                .catch(error => {
                    console.log('Problem', error.message);
                    dispatch('showError', { errorMessage: error.response.data.message || error.message })
                    reject(error);
                });
        })
    },
    async editFuelProviderReferences({commit, dispatch}, data) {
        return await new Promise((resolve, reject) => {
            api.patch(`fuel-provider-mapping/${data.id}/update-fuel-provider`, data.field)
                .then(res => {
                    dispatch('getFuelProviderReferences')
                    resolve(res);
                })
                .catch(error => {
                    console.log('Problem', error.message);
                    dispatch('showError', { errorMessage: error.response.data.message || error.message })
                    reject(error);
                });
        })
    },
    async addFuelProviderReferences({commit, dispatch}, data) {
        return await new Promise((resolve, reject) => {
            api.post(`fuel-provider-mapping/add-fuel-provider`, data)
                .then(res => {
                    dispatch('getFuelProviderReferences')
                    resolve(res);
                })
                .catch(error => {
                    console.log('Problem', error.message);
                    dispatch('showError', { errorMessage: error.response.data.message || error.message })
                    reject(error);
                });
        })
    },
    async removeFuelProviderReferences({commit, dispatch}, id) {
        return await new Promise((resolve, reject) => {
            api.delete(`fuel-provider-mapping/${id}/delete-fuel-provider`)
                .then(res => {
                    dispatch('getFuelProviderReferences')
                    resolve(res);
                })
                .catch(error => {
                    console.log('Problem', error.message);
                    dispatch('showError', { errorMessage: error.response.data.message || error.message })
                    reject(error);
                });
        })
    },

    async getFuelProviderPartnerReferences({commit, dispatch}) {
        return await new Promise((resolve, reject) => {
            api.get(`fuel-provider-partner-mapping/get-fuel-provider-all`)
                .then(res => {
                    commit(type.GET_FUEL_PROVIDER_PARTNER_REFERENCES, res.data);
                    resolve(res);
                })
                .catch(error => {
                    console.log('Problem', error.message);
                    dispatch('showError', { errorMessage: error.response.data.message || error.message })
                    reject(error);
                });
        })
    },
    async editFuelProviderPartnerReferences({commit, dispatch}, data) {
        return await new Promise((resolve, reject) => {
            api.patch(`fuel-provider-partner-mapping/${data.id}/update-fuel-provider`, data.field)
                .then(res => {
                    dispatch('getFuelProviderPartnerReferences')
                    resolve(res);
                })
                .catch(error => {
                    console.log('Problem', error.message);
                    dispatch('showError', { errorMessage: error.response.data.message || error.message })
                    reject(error);
                });
        })
    },
    async addFuelProviderPartnerReferences({commit, dispatch}, data) {
        return await new Promise((resolve, reject) => {
            api.post(`fuel-provider-partner-mapping/add-fuel-provider`, data)
                .then(res => {
                    dispatch('getFuelProviderPartnerReferences')
                    resolve(res);
                })
                .catch(error => {
                    console.log('Problem', error.message);
                    dispatch('showError', { errorMessage: error.response.data.message || error.message })
                    reject(error);
                });
        })
    },
    async removeFuelProviderPartnerReferences({commit, dispatch}, id) {
        return await new Promise((resolve, reject) => {
            api.delete(`fuel-provider-partner-mapping/${id}/delete-fuel-provider`)
                .then(res => {
                    dispatch('getFuelProviderPartnerReferences')
                    resolve(res);
                })
                .catch(error => {
                    console.log('Problem', error.message);
                    dispatch('showError', { errorMessage: error.response.data.message || error.message })
                    reject(error);
                });
        })
    },

    async getFuelTypesReferences({commit, dispatch}) {
        return await new Promise((resolve, reject) => {
            api.get(`fuel-type-mapping/get-fuel-type-all`)
                .then(res => {
                    commit(type.GET_FUEL_TYPES_REFERENCES, res.data);
                    resolve(res);
                })
                .catch(error => {
                    console.log('Problem', error.message);
                    dispatch('showError', { errorMessage: error.response.data.message || error.message })
                    reject(error);
                });
        })
    },
    async editFuelTypesReferences({commit, dispatch}, data) {
        return await new Promise((resolve, reject) => {
            api.patch(`fuel-type-mapping/${data.id}/update-fuel-type`, data.field)
                .then(res => {
                    dispatch('getFuelTypesReferences')
                    resolve(res);
                })
                .catch(error => {
                    console.log('Problem', error.message);
                    dispatch('showError', { errorMessage: error.response.data.message || error.message })
                    reject(error);
                });
        })
    },
    async addFuelTypesReferences({commit, dispatch}, data) {
        return await new Promise((resolve, reject) => {
            api.post(`fuel-type-mapping/add-fuel-type`, data)
                .then(res => {
                    dispatch('getFuelTypesReferences')
                    resolve(res);
                })
                .catch(error => {
                    console.log('Problem', error.message);
                    dispatch('showError', { errorMessage: error.response.data.message || error.message })
                    reject(error);
                });
        })
    },
    async removeFuelTypesReferences({commit, dispatch}, id) {
        return await new Promise((resolve, reject) => {
            api.delete(`fuel-type-mapping/${id}/delete-fuel-type`)
                .then(res => {
                    dispatch('getFuelTypesReferences')
                    resolve(res);
                })
                .catch(error => {
                    console.log('Problem', error.message);
                    dispatch('showError', { errorMessage: error.response.data.message || error.message })
                    reject(error);
                });
        })
    },

    async getRegionsReferences({commit, dispatch}) {
        return await new Promise((resolve, reject) => {
            api.get(`condition-mapping/get-condition-all`)
                .then(res => {
                    commit(type.GET_REGIONS_REFERENCES, res.data);
                    resolve(res);
                })
                .catch(error => {
                    console.log('Problem', error.message);
                    dispatch('showError', { errorMessage: error.response.data.message || error.message })
                    reject(error);
                });
        })
    },
    async editRegionsReferences({commit, dispatch}, data) {
        return await new Promise((resolve, reject) => {
            api.patch(`condition-mapping/${data.id}/update-condition`, data.field)
                .then(res => {
                    dispatch('getRegionsReferences')
                    resolve(res);
                })
                .catch(error => {
                    console.log('Problem', error.message);
                    dispatch('showError', { errorMessage: error.response.data.message || error.message })
                    reject(error);
                });
        })
    },
    async addRegionsReferences({commit, dispatch}, data) {
        return await new Promise((resolve, reject) => {
            api.post(`condition-mapping/add-condition`, data)
                .then(res => {
                    dispatch('getRegionsReferences')
                    resolve(res);
                })
                .catch(error => {
                    console.log('Problem', error.message);
                    dispatch('showError', { errorMessage: error.response.data.message || error.message })
                    reject(error);
                });
        })
    },
    async removeRegionsReferences({commit, dispatch}, id) {
        return await new Promise((resolve, reject) => {
            api.delete(`condition-mapping/${id}/delete-condition`)
                .then(res => {
                    dispatch('getRegionsReferences')
                    resolve(res);
                })
                .catch(error => {
                    console.log('Problem', error.message);
                    dispatch('showError', { errorMessage: error.response.data.message || error.message })
                    reject(error);
                });
        })
    },
};

export default actions;
