<template>
  <div class="popup">
    <span v-if="loading" class="loader">
      <svg class="circular" height="50" width="50">
        <circle class="path" cx="25" cy="25.2" r="19.9" fill="none" stroke-width="6" stroke-miterlimit="10" />
      </svg>
    </span>
    <h4 class="popup__title">Установить лимит <span v-if="showSetCardLimitCustomerPopup">для клиента</span><span v-else>на топливную карту</span></h4>

    <div class="form">
      <div v-if="showSetCardLimitCustomerPopup" class="form__item">
        <div class="label">
          Клиент
        </div>
        <div class="input">
          <v-select :options="customersOptions"
                    label="name"
                    v-model="customerSelected"
                    :clearable="false"
                    @search="fetchOptions"
          >
            <template #no-options>
              Введите имя
            </template>
          </v-select>
        </div>
      </div>
      <div class="form__item">
        <div class="label">
          Тип лимита
        </div>
        <div class="input">
          <v-select :options="limitTypeOptions"
                    label="name"
                    v-model="limitType"
                    :clearable="false"
                    :searchable="false"
                    @option:selected="amount_limit = ''; volume_limit = ''"
          ></v-select>
        </div>
      </div>
      <div class="form__item" v-if="!showSetCardLimitCustomerPopup">
        <div class="label">
          Тип топлива
        </div>
        <div class="input">
          <v-select :options="fuelTypesArr"
                    label="name"
                    v-model="fuelType"
                    :clearable="false"
                    :searchable="false"
          ></v-select>
        </div>
      </div>
      <div class="form__item" v-if="limitType && limitType.name === 'Рубли'">
        <div class="label">
          Сумма руб.
        </div>
        <div class="input">
          <input type="number" v-model="amount_limit">
        </div>
      </div>
      <div class="form__item" v-if="limitType && limitType.name === 'Литры'">
        <div class="label">
          Объем в литрах
        </div>
        <div class="input">
          <input type="number" v-model="volume_limit">
        </div>
      </div>
      <div class="form__item">
        <div class="label">
          По времени
        </div>
        <div class="input">
          <v-select :options="cardLimitsArr"
                    label="name"
                    v-model="limit"
                    :clearable="false"
                    :searchable="false"
          ></v-select>
        </div>
      </div>
    </div>

    <div class="actions">
      <button class="button btn-primary" @click="closeAll()">Отмена</button>
      <button :disabled="disableSubmitButton" class="button btn-primary" @click="setCardLimit()">Сохранить</button>
    </div>
  </div>
</template>

<script>

import {popups} from '@/mixins/popups'
import {mapActions, mapMutations, mapState} from "vuex";
import VueDatePicker from "@vuepic/vue-datepicker";
import vSelect from "vue-select";
export default {
  name: "setCardLimitPopup",
  components: {vSelect, VueDatePicker},
  mixins: [popups],
  data: () => ({
    loading: false,
    limitType: null,
    fuelType: null,
    limitTypeOptions: [],
    customerSelected: null,
    amount_limit: '',
    volume_limit: '',
    cardLimitsArr: [],
    limit: null,
    fuelTypesArr: []
  }),
  computed: {
    ...mapState({
      card: state => state.popups.card,
      cardsChecked: state => state.popups.cardsChecked,
      showSetCardLimitCustomerPopup: state => state.popups.showSetCardLimitCustomerPopup,
      cardLimits: state => state.user.card_limits,
      customer: state => state.popups.customer,
      // customers: state => state.admin.customers,
      customers: state => state.admin.managers,
      cardCatalog: state => state.admin.cardCatalog,
      fuelTypes: state => state.user.fuelTypes,
    }),
    disableSubmitButton() {
      let flag = true;
      if (this.limit && this.amount_limit !== '') {
        flag = false;
      }
      if (this.limit && this.volume_limit !== '') {
        flag = false;
      }
      return flag;
    },
    customersOptions() {
      let options = []
      if (this.customers && this.customers.length > 0) {
        for (let i = 0; i < this.customers.length; i++) {
          let customer = this.customers[i]
          options.push(customer)
        }
      }
      return options
    }
  },
  methods: {
    ...mapActions({
      // getCustomers: 'getCustomers',
      getUsersSearch: 'getUsersSearch',
      getCardLimits: 'getCardLimits',
      bunchSetCardsLimit: 'bunchSetCardsLimit',
    }),
    ...mapMutations({
      setCard: 'SET_CARD',
      setCustomer: 'SET_CUSTOMER',
    }),
    async setCardLimit() {
      this.loading = true

      let cards_ids = []
      let customer_id = null

      if (this.showSetCardLimitCustomerPopup) {
        customer_id = this.customerSelected.customer_id ? this.customerSelected.customer_id : this.customerSelected.id
      } else {
        if (this.card) {
          cards_ids = [this.card.id]
        } else if (this.cardsChecked) {
          let cardIds = []
          for (let i = 0; i < this.cardsChecked.length; i++) {
            let card = this.cardsChecked[i]
            if (card.id) {
              cardIds.push(card.id)
            }
          }
          cards_ids = cardIds
        }
      }

      let data = {
        cards_ids,
      }

      if (this.amount_limit !== '') {
        data['amount_limit'] = this.amount_limit
      }

      if (this.volume_limit !== '') {
        data['amount_limit'] = this.volume_limit
      }

      if ((this.card && (this.card.integration_id === 1 || this.card.integration_id === 2)) || (this.cardsChecked && this.cardsChecked.length > 0 && (this.cardsChecked[0].integration_id === 1 || this.cardsChecked[0].integration_id === 2))) {
        data['fuel_name'] = this.fuelType.name;
        data['fuel_code'] = this.fuelType.id;
        data['duration_name'] = this.limit.name;
        data['duration_code'] = this.limit.id;
        data['currency_name'] = this.limitType.name;
        data['currency_code'] = this.limitType.id;
      } else {
        data['fuel_type_id'] = this.fuelType.id;
        data['date_limit_id'] = this.limit.id;
        data['currency_limit_id'] = this.limitType.id;
      }

      await this.bunchSetCardsLimit(data)

      this.loading = false
      //this.closeAll()
    },
    async fetchOptions(search, loading) {
      if (search.length >= 3) {
        console.log(search)
        loading(true)

        await this.getUsersSearch({
          search,
          type: 3
        })
        loading(false)
      }
    },
  },
  async mounted() {

    if ((this.card && (this.card.integration_id === 1 || this.card.integration_id === 2)) || (this.cardsChecked && this.cardsChecked.length > 0 && (this.cardsChecked[0].integration_id === 1 || this.cardsChecked[0].integration_id === 2))) {
      this.limitTypeOptions = [];
      this.cardCatalog.currencylist.forEach(item => {
        this.limitTypeOptions.push({
          id: item.Code ?? item.code,
          name: item.Name ? item.Name.charAt(0).toUpperCase() + item.Name.slice(1) : item.name.charAt(0).toUpperCase() + item.name.slice(1)
        })
      })
      this.limitType = this.limitTypeOptions[0]
      this.cardLimitsArr = [];
      this.cardCatalog.durationlist.forEach(item => {
        this.cardLimitsArr.push({
          id: item.Code ?? item.code,
          name: item.Name ? item.Name.charAt(0).toUpperCase() + item.Name.slice(1) : item.name.charAt(0).toUpperCase() + item.name.slice(1)
        })
      })
      this.limit = this.cardLimitsArr[0]
      this.fuelTypesArr = [];
      this.cardCatalog.goodlist.forEach(item => {
        this.fuelTypesArr.push({
          id: item.Code ?? item.code,
          name: item.Name ? item.Name.charAt(0).toUpperCase() + item.Name.slice(1) : item.name.charAt(0).toUpperCase() + item.name.slice(1)
        })
      })
      this.fuelType = this.fuelTypesArr[0]
    } else {
      this.limitTypeOptions = this.cardCatalog.currencyLimitTypes;
      this.limitType = this.limitTypeOptions[0]

      this.cardLimitsArr = this.cardCatalog.dateLimitTypes;
      this.limit = this.cardLimitsArr[0]

      this.fuelTypesArr = this.cardCatalog.fuelTypes;
      this.fuelType = this.fuelTypesArr[0]
    }
  },
  beforeUnmount() {
    this.setCard(null)
    this.setCustomer(null)
  }
}
</script>

<style lang="scss">
.vs__selected {
  text-align: left;
}
</style>

<style scoped lang="scss">
@import "../../styles/variables";

.popup .form__item .label {
  min-width: 120px;
}
</style>
