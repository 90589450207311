<template>
  <div class="page-container">
    <div class="page-header">
      <div class="title" >
        <img src="../../assets/menu-icons/dashboard-active.svg" alt="icon">
        <span class="text">
            Дашборд
        </span>
      </div>
    </div>
    <div v-if="userRole === 3" class="page-content">
      <div class="left">
        <div class="card debt-card" v-if="client_details && client_details.finance && client_details.finance.debt_last_month > 0">
          <div class="left">
            <div class="name">Задолженность за предыдущий месяц:</div>
            <div class="debt">{{ client_details.finance.debt_last_month }} ₽</div>
          </div>
<!--          <button class="pay-btn">Оплатить</button>-->
        </div>
        <div class="cards">
          <div class="card" v-if="client_details && client_details.finance && client_details.finance.balance">
            <div class="name">
              <span v-html="'Текущий<br/>баланс<br/>'"></span>
            </div>
            <div :class="{ 'green': client_details.finance.balance > 0, 'orange': client_details.finance.balance <= 0 }" class="value value-big">
              {{ Number(client_details.finance.balance).toFixed(2) }}
              <br/>
              <span class="small-text">* Обновление транзакций каждый час.<br/>Выгрузка платежей в 9:30, 14:30, 16:30, 17:30</span>
            </div>
          </div>
          <div class="card" v-if="client_details && client_details.finance && client_details.finance.limit">
            <div class="name">
              <span v-html="'Кредитный<br/>лимит'"></span>
            </div>
            <div :class="{ 'green': client_details.finance.limit > 0, 'orange': client_details.finance.limit <= 0 }" class="value">{{ client_details.finance.limit }}</div>
          </div>
          <div class="card" v-if="client_details && client_details.finance">
            <div class="name">
              <span v-html="'Прогнозируемый<br/>баланс через'"></span>
              <v-select class="blue" :options="daysOptions"
                        label="title"
                        v-model="daysBalance"
                        :clearable="false"
                        :searchable="false"
              ></v-select>
            </div>
            <div :class="{ 'green': client_details.finance[`predict_balance_${daysBalance.value}`] > 0, 'orange': client_details.finance[`predict_balance_${daysBalance.value}`] <= 0 }" class="value">{{ client_details.finance[`predict_balance_${daysBalance.value}`].toFixed(2) }}</div>
          </div>
        </div>
        <div class="cards">
          <div class="card double-width" v-if="client_details && client_details.finance">
            <div class="name" v-html="'Среднесуточная<br/>реализация'"></div>
            <div class="button-group">
              <button @click="setActiveButton('roubles')" :class="{'active': activeButton === 'roubles'}" class="switcher left">Рубли</button>
              <button @click="setActiveButton('litres')" :class="{'active': activeButton === 'litres'}" class="switcher right">Литры</button>
            </div>
            <div :class="{
                            'green': getTurnoverValue() > 0,
                            'orange': getTurnoverValue() <= 0
                          }"
                 class="value">
              {{ activeButton === 'roubles' ? client_details.finance.daily_turnover_sum.toFixed(2) : client_details.finance.daily_turnover_fuel.toFixed(2) }}
            </div>
          </div>
          <div class="card" v-if="client_details && client_details.finance">
            <div class="name" v-html="'Дата последнего<br/>платежа'"></div>
            <div class="blue value">{{ getPaymentDate(client_details.finance.last_payment_datetime) }}</div>
          </div>
        </div>

        <div class="fuel-card" v-if="client_details && client_details.cards_info">
          <div class="name" v-html="'Количество<br/>топливных карт'"></div>
          <div class="value">{{ client_details.cards_info.cards_count }}</div>
        </div>
      </div>
      <div class="right">
        <div class="card personal" v-if="client_details && client_details.manager_user">
          <div class="name">Информация о персональном менеджере</div>
          <div class="info">
            <div class="avatar">{{ getAvatarText(client_details.manager_user.name) }}</div>
            <div class="personal-data">
              <div class="names">{{ client_details.manager_user.name }}</div>
              <div class="job">Менеджер</div>
              <div class="phone">{{ client_details.manager_user.phone }}</div>
              <a class="mail" :href="`mailto:${client_details.manager_user.login}`" target="_blank">{{ client_details.manager_user.login }}</a>
            </div>
          </div>
        </div>
        <div class="card contracts">
          <div class="name">Информация о договорах</div>
          <div class="contracts-info">
            <div class="item" v-if="client_details && client_details.contract">
              <div class="item_key">Номер договора</div>
              <div class="item_value">{{ client_details.contract.name }}</div>
            </div>
            <div class="item" v-if="client_details && client_details.contract">
              <div class="item_key">Дата подписания</div>
              <div class="item_value">{{ getFormatDate(client_details.contract.date) }}</div>
            </div>
            <div class="item" v-if="client_details && client_details.user_status_id">
              <div class="item_key">Статус</div>
              <div class="item_value value-active" :class="{ 'value-active': client_details.user_status_id === 1, 'value-inactive': client_details.user_status_id === 2 }">
                {{ client_details.user_status }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else-if="userRole === 2" class="page-content column">
      <div class="tab-content table">
        <TableComponent
          :columns="columns"
          :rows="tableRows_1"
          :title="'Клиенты'"
          :isBordered="true"
          :totalCount="totalCount"
          :pageSize="pageSize"
          :pageCount="pageCount"
          :currentPage="Number(currentPage)"
          :isLoading="loading"
          @downloadXSLX="downloadXSLX"
          @regetData="regetData($event)"
          :noData="noData"
          :additionalRows="additionalRows_1"
          :show-additional-table-header="false"
          :actionType="'managerDashboardActions'"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapMutations, mapState} from "vuex";
import vSelect from "vue-select";

export default {
  name: "Dashboard",
  components: {vSelect},
  data: () => ({
    cards: [
      {
        name: `Текущий<br/>баланс`,
        value: '0 ₽',
        color: 'green'
      },
      {
        name: `Кредитный<br/>лимит`,
        value: '10 900.00 ₽',
        color: 'green'
      },
      {
        name: `Прогнозируемый<br/>баланс через`,
        value: '-90.00 ₽',
        color: 'orange'
      },
      {
        name: `Среднесуточная<br/>реализация`,
        value: '900.00 ₽',
        color: 'green',
        doubleWidth: true
      },
      {
        name: `Дата последнего<br/>платежа`,
        value: '17 октября',
        color: 'blue'
      }
    ],
    activeButton: 'roubles',
    tableColumns_1: [
      {
        name: 'Наименование клиента',
        field: 'client_name',
        filter_name: 'users_ids[]',
        filter_value: '',
        order: ''
      },
      {
        name: 'Текущий\n' +
          'баланс',
        field: 'current_balance',
        filter_name: 'current_balance',
        filter_value: '',
        order: '',
        balance_field: true,
        filter_name_from: 'current_balance_from',
        filter_value_from: '',
        filter_name_to: 'current_balance_to',
        filter_value_to: '',
        from_to_fields: true
      },
      {
        name: 'Оборот\n' +
          'за текущий\n' +
          'месяц',
        field: 'turnover_current_month',
        filter_name: 'turnover_current_month',
        filter_value: '',
        order: '',
        filter_name_from: 'turnover_current_month_from',
        filter_value_from: '',
        filter_name_to: 'turnover_current_month_to',
        filter_value_to: '',
        from_to_fields: true
      },
      {
        name: 'Потребление\n' +
          'за текущий месяц\n' +
          '(тип топлива n)',
        field: 'fuel_type_month',
        filter_name: 'fuel_type_month_ids[]',
        filter_value: '',
        order: '',
        fuel_type: true
      },
      {
        name: 'Оборот\n' +
          'за текущий\n' +
          'год',
        field: 'turnover_current_year',
        filter_name: 'turnover_current_year',
        filter_value: '',
        order: '',
        filter_name_from: 'turnover_current_year_from',
        filter_value_from: '',
        filter_name_to: 'turnover_current_year_to',
        filter_value_to: '',
        from_to_fields: true
      },
      {
        name: 'Потребление\n' +
          'за текущий год\n' +
          '(тип топлива n)',
        field: 'fuel_type_year',
        filter_name: 'fuel_type_year_ids[]',
        filter_value: '',
        order: '',
        fuel_type: true
      }
    ],
    tableRows_1: [],
    additionalRows_1: [
      {
        client_name: `ИТОГО`,
        current_balance: ``,
        turnover_current_month: ``,
        fuel_type_month: ``,
        turnover_current_year: ``,
        fuel_type_year: ``,
      },
    ],
    noData: false,
    loading: false,
    totalCount: 0,
    pageSize: 0,
    pageCount: 0,
    currentPage: 0,

    daysOptions: [
      { title: "7 дней", value: 7 },
      { title: "14 дней", value: 14 },
      { title: "21 день", value: 21 }
    ],
    daysBalance: { title: "14 дней", value: 14 }
  }),
  computed: {
    ...mapState({
      userRole: state => state.user.userRole,
      columns: state => state.popups.columns,
      myUser: state => state.user.myUser,
      manager_dashboard: state => state.admin.manager_dashboard,
      client_details: state => state.customer.client_details,
    }),
  },
  watch: {
    myUser(val) {
      if (val && val.type > 0) {
        if (this.userRole === 2) {
          this.getFuelTypesReq();
          // this.getManagerDashboard()
          let data = {
            size: 100,
            pageNumber: 1
          };

          this.regetData(data);
        }
        if (this.userRole === 3) {
          this.getClientDetails();
        }
      }
    },
    async manager_dashboard(val) {
      await this.setTablesData()
    },
  },
  methods: {
    ...mapActions({
      getClientDetails: 'getClientDetails',
      getManagerDashboard: 'getManagerDashboard',
      getFuelTypesReq: 'getFuelTypes',
    }),
    ...mapMutations({
      setColumns: 'SET_COLUMNS',
      setPaginationFilters: 'SET_PAGINATION_FILTERS',
      resetPaginationFilters: 'RESET_PAGINATION_FILTERS',
    }),
    setActiveButton(val) {
      this.activeButton = val
    },
    async regetData(data) {
      console.log(data)
      this.loading = true

      let args = [
        {
          name: 'page',
          value: data.pageNumber
        },
        {
          name: 'page_size',
          value: data.size
        }
      ]

      if (Math.ceil(this.totalCount/data.size) < data.pageNumber) {
        args[0].value = 1
      }

      this.setPaginationFilters(args)

      await this.getManagerDashboard()
    },
    async downloadXSLX() {
      await this.getManagerDashboard(true)
    },
    async setTablesData() {
      this.totalCount = this.manager_dashboard.total_count
      this.pageSize = this.manager_dashboard.page_size
      this.pageCount = this.manager_dashboard.page_count
      this.currentPage = this.manager_dashboard.current_page
      let dashboardData = []

      for (let i = 0; i < this.manager_dashboard.data.length; i++) {
        let data = this.manager_dashboard.data[i]
        data.actionType = 'managerDashboardActions'

        dashboardData.push(data)
      }

      this.additionalRows_1[0].current_balance = this.manager_dashboard.total.total_balance.toFixed(2);
      this.additionalRows_1[0].turnover_current_month = this.manager_dashboard.total.total_turnover_current_month.toFixed(2);
      this.additionalRows_1[0].fuel_type_month = this.manager_dashboard.total.total_fuel_type_month.toFixed(2);
      this.additionalRows_1[0].turnover_current_year = this.manager_dashboard.total.total_turnover_current_year.toFixed(2);
      this.additionalRows_1[0].fuel_type_year = this.manager_dashboard.total.total_fuel_type_year.toFixed(2);

      this.tableRows_1 = dashboardData


      if (!this.tableRows_1 || this.tableRows_1.length === 0) {
        this.noData = true
      } else {
        this.noData = false
      }

      if (this.columns) {
        this.setColumns(this.columns)
      } else {
        this.setColumns(this.tableColumns_1)
      }

      this.loading = false
    },
    getPaymentDate(date) {
      if (date) {
        let split = date.split('-');
        let day = split[2];
        const monthArr = [
          'Января',
          'Февраля',
          'Марта',
          'Апреля',
          'Мая',
          'Июня',
          'Июля',
          'Августа',
          'Сентября',
          'Октября',
          'Ноября',
          'Декабря',
        ];
        let monthName = monthArr[Number(split[1]) - 1]
        return `${day} ${monthName}`;
      } else {
        return '-'
      }
    },
    getTurnoverValue() {
      return this.activeButton === 'roubles' ? this.client_details.finance.daily_turnover_sum : this.client_details.finance.daily_turnover_fuel
    },
    getAvatarText(name) {
      let text = '';
      if (name) {
        let nameSplit = name.split(' ')
        nameSplit.forEach((item, index) => {
          if (index < 2) {
            text += item[0];
          }
        })
      }
      return text;
    },
    getFormatDate(date) {
      let splitDate = date.split('-');
      return `${splitDate[2]}.${splitDate[1]}.${splitDate[0]}`
    }
  },
  async mounted() {
    this.setColumns(null)

    this.resetPaginationFilters()

    setTimeout(() => {
      if (this.userRole === 2) {
        this.getFuelTypesReq();
        // this.getManagerDashboard()
        let data = {
          size: 100,
          pageNumber: 1
        };

        this.regetData(data);
      }
      if (this.userRole === 3) {
        this.getClientDetails();
      }
    }, 200);
  }
}
</script>

<style scoped lang="scss">
@import "../../styles/variables";

.page-content {
  display: flex;

  &.column {
    flex-direction: column;
  }

  >.left {
    display: flex;
    flex-direction: column;
    padding-top: 30px;
    //padding-right: 30px;
    //max-width: 630px;
    //min-width: 630px;
    //flex-wrap: wrap;
    width: 75%;
    border-right: 1px solid rgba(26, 26, 24, 0.10);
    .cards {
      display: flex;
    }
    @media screen and (min-width: 1440px) {
      //width: 60%;
    }
  }
  .right {
    display: flex;
    flex-direction: column;
    padding-top: 30px;
    padding-left: 30px;
    width: 50%;
    flex-shrink: 1;
  }
}

.card {
  width: 128px;
  width: calc(33% - 80px);
  height: 111px;
  flex-shrink: 0;
  border-radius: 12px;
  background: #FFFFFF;
  margin-right: 30px;
  margin-bottom: 30px;
  padding: 30px 27px 39px 25px;
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;

  &.double-width {
    //width: 338px;
    width: calc(66% - 80px);
  }

  &.debt-card {
    height: auto;
    border-radius: 12px;
    background: linear-gradient(341deg, #FFEC00 -27.5%, #FFEA00 -26.24%, #F7B403 5.17%, #F08A05 34.06%, #EC6B07 60.45%, #E95908 81.81%, #E85208 98.14%);
    box-shadow: 0px 4px 24px -6px rgba(234, 101, 9, 0.64);
    width: calc(100% - 84px);

    flex-direction: row;

    .left {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
    }

    .name {
      //width: 160px;
      color: #FFFFFF;
      font-family: SF_Pro_Regular, Arial, sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;
    }
    .debt {
      color: #FFFFFF;
      text-align: center;
      font-family: SF_Pro_SemiBold, Arial, sans-serif;
      font-size: 30px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }

    .pay-btn {
      display: inline-flex;
      padding: 12px 16px;
      justify-content: center;
      align-items: center;
      gap: 8px;
      background: transparent;

      border-radius: 4px;
      border: 1px solid #FFFFFF;

      color: #FFFFFF;
      font-feature-settings: 'clig' off, 'liga' off;

      /* Omega (Bold) - Button text */
      font-family: SF_Pro_Bold, Arial, sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 16px; /* 114.286% */
      text-transform: uppercase;

      transition: all .3s;

      &:hover {
        background: #FFFFFF;
        color: #F08A05;
      }
    }
  }

  .name {
    color: $black;
    font-family: SF_Pro_Medium, Arial, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-align: left;
  }

  .value {
    width: 100%;
    text-align: right;
    font-family: SF_Pro_SemiBold, Arial, sans-serif;
    font-size: 19px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    position: relative;

    &:before {
      content: '';
      position: absolute;
      left: 0;
      bottom: -2px;
      width: 4px;
      height: 30px;
      flex-shrink: 0;
      border-radius: 20px;
    }

    &.value-big {
      &:before {
        height: 50px;
      }
    }

    &.green {
      color: $green;
      &:before {
        background: $green;
      }
    }
    &.orange {
      color: $orange;
      &:before {
        background: $orange;
      }
    }
    &.blue {
      color: $blue;
      &:before {
        background: $blue;
      }
    }
  }

  &.personal, &.contracts {
    width: calc(100% - 60px);
    //width: calc(100% - 50px);
    max-width: 375px;
    height: unset;
    flex-shrink: 0;
    padding: 30px;
    padding-top: 20px;
    @media screen and (min-width: 1440px) {
      max-width: unset;
      //width: 100%;
    }

    .name {
      margin-bottom: 31px;
      text-align: left;
    }

    .info {
      display: flex;
      .avatar {
        height: 48px;
        width: 48px;
        flex-shrink: 0;
        background: $green;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #FFFFFF;
        text-align: center;
        font-family: SF_Pro_Medium, Arial, sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin-right: 16px;
      }
      .personal-data {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        .names {
          color: $black;
          font-family: SF_Pro_SemiBold, Arial, sans-serif;
          font-size: 18px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          margin-bottom: 9px;
          text-align: left;
        }
        .job {
          color: $grey_1;
          font-family: SF_Pro_Regular, Arial, sans-serif;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          margin-bottom: 16px;
        }
        .phone {
          margin: 0 0 5px;
          font-size: 16px;
          font-weight: 600;
        }
        .mail {
          color: var(--Blue-1, #2F80ED);
          font-family: SF_Pro_Regular, Arial, sans-serif;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }
    }

    .contracts-info {
      display: inline-flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 16px;
      width: 100%;

      .item {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: flex-start;
        text-align: left;

        &_key {
          color: $grey_1;
          font-family: SF_Pro_Regular, Arial, sans-serif;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
        &_value {
          color: $black;
          text-align: right;
          font-family: SF_Pro_Medium, Arial, sans-serif;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;

          &.value-active {
            position: relative;
            color: $green;

            &:before {
              content: '';
              position: absolute;
              left: -12px;
              bottom: 6.5px;
              width: 6px;
              height: 6px;
              flex-shrink: 0;
              border-radius: 50%;
              background: $green;
            }
          }

          &.value-inactive {
            position: relative;
            color: $orange;

            &:before {
              content: '';
              position: absolute;
              left: -12px;
              bottom: 6.5px;
              width: 6px;
              height: 6px;
              flex-shrink: 0;
              border-radius: 50%;
              background: $orange;
            }
          }
        }
      }
    }
  }
}

.button-group {
  position: absolute;
  top: 30px;
  right: 27px;
  display: inline-flex;
  align-items: center;
  box-shadow: 0px 2px 5px 0px rgba(38, 51, 77, 0.03);

  .switcher {
    display: flex;
    height: 40px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border: 2px solid #F5F6F7;
    background: #FFFFFF;
    color: $grey_2;
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: SF_Pro_Medium, Arial, sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 6px;//20px; /* 166.667% */

    &.left {
      padding: 15px 15px 15px 20px;
      border-radius: 30px 0px 0px 30px;
    }
    &.right {
      padding: 15px 20px 15px 15px;
      border-radius: 0px 30px 30px 0px;
    }

    &.active {
      color: $green;
    }

    transition: all .3s;

    &:hover {
      background: $green;
      color: #FFFFFF;
    }
  }
}

.fuel-card {
  //width: 338px;
  width: calc(66% - 80px);
  height: 38px;
  flex-shrink: 0;
  border-radius: 12px;
  background: #FFFFFF;
  padding: 34px 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;

  .name {
    text-align: left;
    color: $black;
    font-family: SF_Pro_Medium, Arial, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .value {
    color: #FFFFFF;
    text-align: center;
    font-family: SF_Pro_SemiBold, Arial, sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    z-index: 1;
    padding-right: 45px;
  }

  &:before {
    content: '';
    position: absolute;
    right: 17px;
    bottom: 15px;
    width: 120px;
    height: 75px;
    flex-shrink: 0;
    border-radius: 20px;

    background: url("../../assets/fuel-card.svg");
    background-repeat: no-repeat;
    background-size: contain;
  }
}

@media screen and (max-width: 1440px) {
  .card {
    padding: 20px;
    width: calc(33% - 68px);
    &.double-width {
      //width: 338px;
      width: calc(66% - 68px);
    }
    .name {
      font-size: 12px;
    }
    .value {
      font-size: 15px;
    }

    &.personal, &.contracts {
      height: unset;
      padding: 20px;
      .name {
        margin-bottom: 24px;
      }
      .info {
        .personal-data {
          .names {
            font-size: 14px;
          }
          .job {
            font-size: 12px;
          }
        }
      }

      .contracts-info {
        gap: 14px;

        .item {
          &_key, &_value {
            font-size: 13px;
          }
        }
      }
    }
  }
  .fuel-card {
    .name {
      font-size: 12px;
    }
    .value {
      font-size: 20px;
    }
  }
}

.tab-content {
  margin-top: 30px;
}

.small-text {
  display: flex;
  justify-content: flex-end;
  margin-left: 10px;
  color: #1A1A18;
  font-family: SF_Pro_Medium, Arial, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 1;
  text-align: left;
}

</style>
