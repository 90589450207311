import {mapMutations, mapState} from "vuex";

export const popups = {
  computed: {},
  methods: {
    ...mapMutations({
      toggleOverlay: 'TOGGLE_OVERLAY',
      showEditClient: 'SHOW_EDIT_CLIENT',
      showRemoveClient: 'SHOW_REMOVE_CLIENT',
      showSetCardLimit: 'SHOW_SET_CARD_LIMIT',
      showSetCardLimitCustomer: 'SHOW_SET_CARD_LIMIT_CUSTOMER',
      showSuspendCard: 'SHOW_SUSPEND_CARD',
      showAddCard: 'SHOW_ADD_CARD',
      showEditUser: 'SHOW_EDIT_USER',
      showRemoveUser: 'SHOW_REMOVE_USER',
      showResetPassword: 'SHOW_RESET_PASSWORD',
      showAddNewUser: 'SHOW_ADD_NEW_USER',
      showErrorPopup: 'SHOW_ERROR_POPUP',
      showBunchChangeCardStatus: 'SHOW_BUNCH_CHANGE_CARD_STATUS',
      showUploadPrices: 'SHOW_UPLOAD_PRICES',
      showViewFile: 'SHOW_VIEW_FILE',
      showRemoveFile: 'SHOW_REMOVE_FILE',
      showSuretyInfo: 'SHOW_SURETY_INFO_POPUP',
      dataSuretyInfo: 'DATA_SURETY_INFO_POPUP',
      setUser: 'SET_USER',
      setShowDiscountPopup: 'SET_SHOW_DISCOUNT_POPUP',
      setShowDiscountFuelTypePopup: 'SET_SHOW_DISCOUNT_FUEL_TYPE_POPUP',
      setShowDiscountRegionPopup: 'SET_SHOW_DISCOUNT_REGION_POPUP',
      setSuretyFilesToRemove: 'SET_SURETY_FILES_TO_REMOVE',
      setSuretyFilesToAdd: 'SET_SURETY_FILES_TO_ADD',
      setEditCardPopup: 'SET_SHOW_EDIT_CARD_POPUP',
      setShowRemoveReferencePopup: 'SET_SHOW_REMOVE_REFERENCE_POPUP',
      setIsSaveRemoveReferencePopup: 'SET_IS_SAVE_REMOVE_REFERENCE_POPUP',
      setEditCardData: 'SET_EDIT_CART_DATA_POPUP',
      setShowUploadCardsPopup: 'SET_SHOW_UPLOAD_CARDS_POPUP',
      setShowCardsForClientPopup: 'SET_SHOW_CARDS_FOR_CLIENT_POPUP',
      setShowIntegrationsListPopup: 'SET_SHOW_INTEGRATION_LIST_POPUP',
      setShowListVinksRegionsFuel: 'SET_SHOW_LIST_VINKS_REGIONS_FUEL_POPUP',
    }),
    closeAll(withoutOverlay) {
      this.toggleOverlay(false);
      this.showEditClient(false);
      this.showRemoveClient(false);
      this.showSetCardLimit(false);
      this.showSuspendCard(false);
      this.showAddCard(false);
      this.showEditUser(false);
      this.showRemoveUser(false);
      this.showResetPassword(false);
      this.showAddNewUser(false);
      this.showErrorPopup(false);
      this.showBunchChangeCardStatus(false);
      this.showUploadPrices(false);
      this.showViewFile(false);
      this.showRemoveFile(false);
      this.showSuretyInfo(false);
      this.dataSuretyInfo(null);
      this.setUser(null);
      this.setShowDiscountPopup(false);
      this.setShowDiscountFuelTypePopup(false);
      this.setShowDiscountRegionPopup(false);
      this.setSuretyFilesToRemove([]);
      this.setSuretyFilesToAdd([]);
      this.setEditCardPopup(false);
      this.setShowRemoveReferencePopup(false);
      this.setEditCardData(null);
      this.setShowUploadCardsPopup(false);
      this.setShowCardsForClientPopup(false);
      this.setShowIntegrationsListPopup(false);
      this.setShowListVinksRegionsFuel(false);
    }
  }
}
