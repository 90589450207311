<template>
  <div class="popup">
    <span v-if="loading" class="loader">
      <svg class="circular" height="50" width="50">
        <circle class="path" cx="25" cy="25.2" r="19.9" fill="none" stroke-width="6" stroke-miterlimit="10" />
      </svg>
    </span>
    <h4 class="popup__title">Загрузка прайса</h4>

    <div class="form">
      <div class="form__item">
        <div class="label">
          Дата изменения
          условий
        </div>
        <div class="input">
          <VueDatePicker
              v-model="date"
              :enable-time-picker="false"
              format="dd.MM.yyyy"
              locale="ru"
              cancelText="Отмена"
              selectText="Выбрать"
          />
        </div>
      </div>
      <div class="form__item">
        <div class="label">
          Файл
        </div>
        <div class="input">
          <button @click="uploadXSLX" v-if="!file" class="button upload-btn btn-primary">
            <svg width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M16.625 12.5V15.8333C16.625 16.2754 16.4582 16.6993 16.1613 17.0118C15.8643 17.3244 15.4616 17.5 15.0417 17.5H3.95833C3.53841 17.5 3.13568 17.3244 2.83875 17.0118C2.54181 16.6993 2.375 16.2754 2.375 15.8333V12.5" stroke="#6DB534" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M13.4584 6.66667L9.50008 2.5L5.54175 6.66667" stroke="#6DB534" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M9.5 2.5V12.5" stroke="#6DB534" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            Загрузить файл
          </button>
          <div v-else class="wrapper">
            <input @click="uploadXSLX" type="text" v-model="file_name">
            <div @click="clearFileInput" class="clear-btn">
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M10 17.5C14.1421 17.5 17.5 14.1421 17.5 10C17.5 5.85786 14.1421 2.5 10 2.5C5.85786 2.5 2.5 5.85786 2.5 10C2.5 14.1421 5.85786 17.5 10 17.5Z" stroke="#6DB534" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M12.5 7.5L7.5 12.5" stroke="#6DB534" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M7.5 7.5L12.5 12.5" stroke="#6DB534" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="actions">
      <button class="button btn-primary" @click="closeAll()">Отмена</button>
      <button :disabled="disabledSubmitButton" class="button btn-primary" @click="setNewUserData()">Сохранить</button>
    </div>

    <input id="fileUpload" @input="addFile($event)" type="file" accept=".xlsx, .xls" hidden>

  </div>
</template>

<script>

import {popups} from '@/mixins/popups'
import {mapActions, mapState} from "vuex";
import VueDatePicker from "@vuepic/vue-datepicker";
import {toFormData} from "@/utils/toFormData";
export default {
  name: "uploadPricesPopup",
  mixins: [popups],
  components: {
    VueDatePicker
  },
  data: () => ({
    loading: false,
    date: null,
    file_name: '',
    file: null,
  }),
  computed: {
    disabledSubmitButton() {
      return !this.file_name ||
          !this.file ||
          !this.date
    }
  },
  methods: {
    ...mapActions({
      uploadFuelPrices: 'uploadFuelPrices',
    }),
    clearFileInput() {
      this.file = null
      this.file_name = ''
    },
    uploadXSLX() {
      document.getElementById("fileUpload").click()
    },
    async addFile(file) {
      console.log(file)
      console.log(file.target.files[0])

      this.file = file.target.files[0]
      this.file_name = this.file.name
    },
    async setNewUserData() {
      this.loading = true

      console.log('DATE')
      console.log(this.date)

      let fullDate = this.date.toLocaleString()

      let date = fullDate.split(', ')[0]
      // let time = fullDate.split(', ')[1]
      let reversedDate = date.split(".").reverse().join("-");
      let newDate = `${reversedDate}`

      let form_data = toFormData({
        files: this.file,
        date: newDate
      })

      await this.uploadFuelPrices(form_data)

      this.loading = false
      //this.closeAll()
    },
  },
  async mounted() {
    let date = new Date()
    console.log(date)
    this.date = date
  }
}
</script>

<style scoped lang="scss">
@import "../../styles/variables";

.form {
  &.fake {
    margin-bottom: 0;
    .left {
      display: flex;
      width: 100%;
      &.full-width {
        .form__item{
          width: 100%;
        }
      }
    }
  }
}

.upload-btn {
  width: 100%;
  svg {
    margin-right: 12px;
  }
}

.wrapper {
  position: relative;

  input {
    padding-right: 40px !important;
  }
  .clear-btn {
    cursor: pointer;
    position: absolute;
    right: 11px;
    top: 11px;
  }
}
</style>
