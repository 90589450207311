<template>
  <div class="table-wrapper">
    <span v-if="loading" class="loader">
      <svg class="circular" height="50" width="50">
        <circle class="path" cx="25" cy="25.2" r="19.9" fill="none" stroke-width="6" stroke-miterlimit="10" />
      </svg>
    </span>
<!--
    <div v-if="filterPopupShown !== null || actionsPopupShown !== null || cardActionsPopupShown" @click="closePopups" class="overlay"></div>
-->

    <div class="header" :class="{'borderless': noTable}" v-if="actionType === 'references'">
      <button class="btn-primary export-btn" @click="$emit('addNew')">
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M12 8H4" stroke="#6DB534" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M8 4V12" stroke="#6DB534" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        <span v-if="actionType_2 === 'vinks'" class="text">добавить ВИНК</span>
        <span v-if="actionType_2 === 'vinksPartner'" class="text">добавить ВИНК партнера</span>
        <span v-if="actionType_2 === 'fuelTypes'" class="text">добавить тип топлива</span>
        <span v-if="actionType_2 === 'regions'" class="text">добавить регион</span>
      </button>
    </div>

    <div class="header" :class="{'borderless': noTable}" v-if="actionType !== 'references'">
      <div v-if="title && !cardsReport" class="title" v-html="title"></div>

      <div class="date-picker" v-if="datePicker && !paymentsActions2">
        <div class="wrap">
          <span class="date-picker-label">Выбора временного диапазона:</span>

          <VueDatePicker
              ref="dateRange"
              v-model="date"
              range
              :enable-time-picker="false"
              :month-picker="actionType !== 'transactionReportActions' && actionType !== 'reviseReport' && actionType !== 'paymentsActions'"
              :format="actionType === 'transactionReportActions' || actionType === 'reviseReport' || actionType === 'paymentsActions' ? 'dd.MM.yyyy' : 'MM.yyyy'"
              locale="ru"
              cancelText="Отмена"
              selectText="Выбрать"
              @update:model-value="setDateRange"
              :clearable="false"
          >
            <template #input-icon>
              <img class="input-slot-image" src="../assets/datepicker-icon.svg" alt="d"/>
            </template>
          </VueDatePicker>
        </div>


        <button @click="download" class="btn-primary export-btn">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4.66659 14C3.93021 14 3.33325 13.403 3.33325 12.6667V3.33333C3.33325 2.59695 3.93021 2 4.66659 2H9.33325L12.6666 5.33333V12.6667C12.6666 13.403 12.0696 14 11.3333 14H4.66659Z" stroke="#6DB534" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M12.6667 6L9.33342 6C8.96523 6 8.66675 5.70152 8.66675 5.33333L8.66675 2" stroke="#6DB534" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M6 10L8 12M8 12L10 10M8 12L8 8" stroke="#6DB534" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
          <span class="text">Экспорт в .xls</span>
        </button>
      </div>

      <div class="card-actions" v-else-if="cardActions">
        <div class="menu">
<!--          <button @click="setLimitForCards" :disabled="noCheckedRows || integrationAndVink" class="menu-btn set-limit">-->
          <button @click="setLimitForCards" :disabled="true" class="menu-btn set-limit">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M12 16L21 16" stroke="#868685" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M3 16H5" stroke="#868685" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M8.5 18.5C9.88071 18.5 11 17.3807 11 16C11 14.6193 9.88071 13.5 8.5 13.5C7.11929 13.5 6 14.6193 6 16C6 17.3807 7.11929 18.5 8.5 18.5Z" stroke="#868685" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M19 8H21" stroke="#868685" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M3 8H12" stroke="#868685" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M15.5 10.5C16.8807 10.5 18 9.38071 18 8C18 6.61929 16.8807 5.5 15.5 5.5C14.1193 5.5 13 6.61929 13 8C13 9.38071 14.1193 10.5 15.5 10.5Z" stroke="#868685" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </button>
<!--          <div class="wrap">-->
<!--            <button @click="showLimitActionsPopup" class="menu-btn set-limit">-->
<!--              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--                <path d="M12 16L21 16" stroke="#868685" stroke-linecap="round" stroke-linejoin="round"/>-->
<!--                <path d="M3 16H5" stroke="#868685" stroke-linecap="round" stroke-linejoin="round"/>-->
<!--                <path d="M8.5 18.5C9.88071 18.5 11 17.3807 11 16C11 14.6193 9.88071 13.5 8.5 13.5C7.11929 13.5 6 14.6193 6 16C6 17.3807 7.11929 18.5 8.5 18.5Z" stroke="#868685" stroke-linecap="round" stroke-linejoin="round"/>-->
<!--                <path d="M19 8H21" stroke="#868685" stroke-linecap="round" stroke-linejoin="round"/>-->
<!--                <path d="M3 8H12" stroke="#868685" stroke-linecap="round" stroke-linejoin="round"/>-->
<!--                <path d="M15.5 10.5C16.8807 10.5 18 9.38071 18 8C18 6.61929 16.8807 5.5 15.5 5.5C14.1193 5.5 13 6.61929 13 8C13 9.38071 14.1193 10.5 15.5 10.5Z" stroke="#868685" stroke-linecap="round" stroke-linejoin="round"/>-->
<!--              </svg>-->
<!--            </button>-->

<!--            <div v-click-outside="onClickOutside" v-if="limitActionsPopupShown" class="actions-popup cards cards-limits">-->
<!--              <button class="action-btn" @click="setLimitForCards" :disabled="noCheckedRows">-->
<!--                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--                  <path d="M2.25 6.75H15.75M3.75 14.25H14.25C15.0784 14.25 15.75 13.5784 15.75 12.75V5.25C15.75 4.42157 15.0784 3.75 14.25 3.75H3.75C2.92157 3.75 2.25 4.42157 2.25 5.25V12.75C2.25 13.5784 2.92157 14.25 3.75 14.25Z" stroke="#868685" stroke-linecap="round" stroke-linejoin="round"/>-->
<!--                  <path d="M5.25 9.75H7.5" stroke="#868685" stroke-linecap="round" stroke-linejoin="round"/>-->
<!--                </svg>-->
<!--                Установить лимит для карты-->
<!--              </button>-->
<!--&lt;!&ndash;              <button class="action-btn green" @click="setCardLimitForClient">&ndash;&gt;-->
<!--&lt;!&ndash;                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">&ndash;&gt;-->
<!--&lt;!&ndash;                  <path d="M12.75 12.1709C14.4978 12.5827 15.75 13.694 15.75 15.0002" stroke="#868685" stroke-linecap="round" stroke-linejoin="round"/>&ndash;&gt;-->
<!--&lt;!&ndash;                  <path d="M2.25 15C2.25 13.3431 4.26472 12 6.75 12C9.23528 12 11.25 13.3431 11.25 15" stroke="#868685" stroke-linecap="round" stroke-linejoin="round"/>&ndash;&gt;-->
<!--&lt;!&ndash;                  <path d="M11.25 9.75C12.9069 9.75 14.25 8.40685 14.25 6.75C14.25 5.09315 12.9069 3.75 11.25 3.75" stroke="#868685" stroke-linecap="round" stroke-linejoin="round"/>&ndash;&gt;-->
<!--&lt;!&ndash;                  <path d="M6.75 9.75C8.40685 9.75 9.75 8.40685 9.75 6.75C9.75 5.09315 8.40685 3.75 6.75 3.75C5.09315 3.75 3.75 5.09315 3.75 6.75C3.75 8.40685 5.09315 9.75 6.75 9.75Z" stroke="#868685" stroke-linecap="round" stroke-linejoin="round"/>&ndash;&gt;-->
<!--&lt;!&ndash;                </svg>&ndash;&gt;-->
<!--&lt;!&ndash;                Установить лимит для клиента&ndash;&gt;-->
<!--&lt;!&ndash;              </button>&ndash;&gt;-->
<!--            </div>-->
<!--          </div>-->

          <button :disabled="noCheckedRows || (allUnlocked && allLocked) || (!allUnlocked && !allLocked)" class="menu-btn change-status" @click="changeStatus">
            <svg v-if="noCheckedRows && allUnlocked" class="lock" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M8 11V7.88889C8 6.85749 8.42143 5.86834 9.17157 5.13903C9.92172 4.40972 10.9391 4 12 4C13.0609 4 14.0783 4.40972 14.8284 5.13903C15.5786 5.86834 16 6.85749 16 7.88889V11" stroke="#868685" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M5 12C5 11.4477 5.44772 11 6 11H18C18.5523 11 19 11.4477 19 12V20C19 20.5523 18.5523 21 18 21H6C5.44772 21 5 20.5523 5 20V12Z" stroke="#868685" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            <svg v-if="!noCheckedRows && !allUnlocked && !allLocked" class="lock" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M8 11V7.88889C8 6.85749 8.42143 5.86834 9.17157 5.13903C9.92172 4.40972 10.9391 4 12 4C13.0609 4 14.0783 4.40972 14.8284 5.13903C15.5786 5.86834 16 6.85749 16 7.88889V11" stroke="#868685" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M5 12C5 11.4477 5.44772 11 6 11H18C18.5523 11 19 11.4477 19 12V20C19 20.5523 18.5523 21 18 21H6C5.44772 21 5 20.5523 5 20V12Z" stroke="#868685" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            <svg v-if="!noCheckedRows && allUnlocked" class="lock" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M8 11V7.88889C8 6.85749 8.42143 5.86834 9.17157 5.13903C9.92172 4.40972 10.9391 4 12 4C13.0609 4 14.0783 4.40972 14.8284 5.13903C15.5786 5.86834 16 6.85749 16 7.88889V11" stroke="#868685" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M5 12C5 11.4477 5.44772 11 6 11H18C18.5523 11 19 11.4477 19 12V20C19 20.5523 18.5523 21 18 21H6C5.44772 21 5 20.5523 5 20V12Z" stroke="#868685" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            <svg v-if="!noCheckedRows && allLocked" class="unlock" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M8 11V7.88889C8 6.85749 8.42143 5.86834 9.17157 5.13903C9.92172 4.40972 10.9391 4 12 4C13.0609 4 14.0783 4.40972 14.8284 5.13903C15.5786 5.86834 16 6.85749 16 7.88889" stroke="#868685" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M5 12C5 11.4477 5.44772 11 6 11H18C18.5523 11 19 11.4477 19 12V20C19 20.5523 18.5523 21 18 21H6C5.44772 21 5 20.5523 5 20V12Z" stroke="#868685" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </button>
        </div>
        <div class="wrap">
          <button class="card-actions-btn" @click="showCardActionsPopup">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M18 12H6" stroke="#6DB534" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M12 6V18" stroke="#6DB534" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </button>

          <div v-click-outside="onClickOutside" v-if="cardActionsPopupShown" class="actions-popup cards">
            <button class="action-btn" @click="addCard">
              <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M3.75 11.25L3.375 14.625L6.75 14.25L14.3358 6.66421C15.1168 5.88317 15.1168 4.61683 14.3358 3.83579L14.1642 3.66421C13.3832 2.88316 12.1168 2.88316 11.3358 3.66421L3.75 11.25Z" stroke="#868685" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M10.5 4.5L13.5 7.5" stroke="#868685" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M9.75 15H15.75" stroke="#868685" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
              Добавить карту вручную
            </button>
            <button class="action-btn green" @click="downloadFile('cards-upload-sample')">
              <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M3 15L15 15C15.4142 15 15.75 14.6642 15.75 14.25L15.75 5.25C15.75 4.83579 15.4142 4.5 15 4.5L2.25 4.5L2.25 14.25C2.25 14.6642 2.58579 15 3 15Z" stroke="#868685" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M9 4.5L7.71967 3.21967C7.57902 3.07902 7.38825 3 7.18934 3H3C2.58579 3 2.25 3.33579 2.25 3.75V4.5" stroke="#868685" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M11.25 9L9 7.5M9 7.5L6.75 9M9 7.5L9 12" stroke="#868685" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
              Скачать шаблон загрузки карт
            </button>
            <button class="action-btn green" @click="addBunchCard">
              <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M3 15L15 15C15.4142 15 15.75 14.6642 15.75 14.25L15.75 5.25C15.75 4.83579 15.4142 4.5 15 4.5L2.25 4.5L2.25 14.25C2.25 14.6642 2.58579 15 3 15Z" stroke="#868685" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M9 4.5L7.71967 3.21967C7.57902 3.07902 7.38825 3 7.18934 3H3C2.58579 3 2.25 3.33579 2.25 3.75V4.5" stroke="#868685" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M11.25 9L9 7.5M9 7.5L6.75 9M9 7.5L9 12" stroke="#868685" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
              Пакетная загрузка карт
            </button>
            <button class="action-btn green" @click="showUploadCards">
              <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M15 6H3V14.25C3 14.6642 3.33579 15 3.75 15H14.25C14.6642 15 15 14.6642 15 14.25V6Z" stroke="#868685" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M9 12.75L9 8.25" stroke="#868685" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M6.75 9.75L9 8.25L11.25 9.75" stroke="#868685" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M12.8505 3H5.14953C4.88038 3 4.63188 3.14421 4.49835 3.3779L3 6H15L13.5017 3.3779C13.3681 3.14421 13.1196 3 12.8505 3Z" stroke="#868685" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
              Загрузка карт из...
            </button>
            <button class="action-btn green" @click="syncCardStatuses">
              <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M3 4.0498V7.7998H6.75" stroke="#868685" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M4.50611 10.7446C4.89517 11.8181 5.63259 12.7397 6.60725 13.3704C8.58659 14.6512 11.2655 14.5069 13.088 13.0077C15.9334 10.6671 15.4403 6.1401 12.2124 4.40534C11.1877 3.85465 10.0066 3.64263 8.84722 3.80121C6.54363 4.11629 4.99442 6.01082 3.375 7.5" stroke="#868685" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
              Обновить статусы карт
            </button>
          </div>
        </div>


        <button @click="download" class="btn-primary export-btn">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4.66659 14C3.93021 14 3.33325 13.403 3.33325 12.6667V3.33333C3.33325 2.59695 3.93021 2 4.66659 2H9.33325L12.6666 5.33333V12.6667C12.6666 13.403 12.0696 14 11.3333 14H4.66659Z" stroke="#6DB534" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M12.6667 6L9.33342 6C8.96523 6 8.66675 5.70152 8.66675 5.33333L8.66675 2" stroke="#6DB534" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M6 10L8 12M8 12L10 10M8 12L8 8" stroke="#6DB534" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
          <span class="text">Экспорт в .xls</span>
        </button>
      </div>

      <div class="users-management" v-else-if="usersManagement">
        <button class="btn-primary export-btn new-user" @click="addNewUser">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 8H4" stroke="#6DB534" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M8 4V12" stroke="#6DB534" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
          <span class="text">Новый пользователь</span>
        </button>
        <button @click="download" class="btn-primary export-btn">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4.66659 14C3.93021 14 3.33325 13.403 3.33325 12.6667V3.33333C3.33325 2.59695 3.93021 2 4.66659 2H9.33325L12.6666 5.33333V12.6667C12.6666 13.403 12.0696 14 11.3333 14H4.66659Z" stroke="#6DB534" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M12.6667 6L9.33342 6C8.96523 6 8.66675 5.70152 8.66675 5.33333L8.66675 2" stroke="#6DB534" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M6 10L8 12M8 12L10 10M8 12L8 8" stroke="#6DB534" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
          <span class="text">Экспорт в .xls</span>
        </button>
      </div>

      <div class="cards-report" v-else-if="cardsReport">
        <div v-if="title && userRole === 3" class="title" v-html="title"></div>
        <div class="cards-report-filters" v-if="userRole !== 3">
          <div class="input">
            <div class="label">Клиент</div>
            <v-select
              v-model="client"
              :options="managers"
              label="name"
              :clearable="true"
              :searchable="true"
              placeholder="Выбрать"
              @search="fetchOptions"
            >
              <template #no-options>
                Введите имя
              </template>
            </v-select>
          </div>
          <div class="input">
            <div class="label">Номер карты</div>
            <v-select
              :options="cardsSelect"
              label="number"
              v-model="card"
              :clearable="true"
              :searchable="true"
              placeholder="Выбрать"
            >
              <template #no-options>
                Введите имя
              </template>
            </v-select>
          </div>
<!--          <div class="input">-->
<!--            <div class="label">АЗС</div>-->
<!--            <v-select :options="gasStationOptions"-->
<!--                      label="title"-->
<!--                      v-model="gasStation"-->
<!--                      :clearable="false"-->
<!--                      :searchable="false"-->
<!--            ></v-select>-->
<!--          </div>-->
        </div>

        <div class="date-picker">
          <div class="wrap">
            <span class="date-picker-label">Выбора временного диапазона:</span>

            <VueDatePicker
              ref="dateRange"
              v-model="date"
              range
              :enable-time-picker="false"
              :format="'dd.MM.yyyy'"
              locale="ru"
              cancelText="Отмена"
              selectText="Выбрать"
              @update:model-value="setDateRange"
              :clearable="false"
            >
              <template #input-icon>
                <img class="input-slot-image" src="../assets/datepicker-icon.svg" alt="d"/>
              </template>
            </VueDatePicker>
          </div>
        </div>
        <button class="btn-primary export-btn" @click="download">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4.66659 14C3.93021 14 3.33325 13.403 3.33325 12.6667V3.33333C3.33325 2.59695 3.93021 2 4.66659 2H9.33325L12.6666 5.33333V12.6667C12.6666 13.403 12.0696 14 11.3333 14H4.66659Z" stroke="#6DB534" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M12.6667 6L9.33342 6C8.96523 6 8.66675 5.70152 8.66675 5.33333L8.66675 2" stroke="#6DB534" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M6 10L8 12M8 12L10 10M8 12L8 8" stroke="#6DB534" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
          <span class="text">Экспорт в .xls</span>
        </button>
      </div>

      <div v-else-if="paymentsActions && userRole === 1 && datePicker" class="payments">
        <div class="date-picker">
          <div class="wrap">
            <span class="date-picker-label">Выбора временного диапазона:</span>

            <VueDatePicker
              ref="dateRange"
              v-model="date"
              range
              :enable-time-picker="false"
              :month-picker="!paymentsActions"
              :format="paymentsActions ? 'dd.MM.yyyy' : 'MM.yyyy'"
              locale="ru"
              cancelText="Отмена"
              selectText="Выбрать"
              @update:model-value="setDateRange"
              :clearable="false"
            >
              <template #input-icon>
                <img class="input-slot-image" src="../assets/datepicker-icon.svg" alt="d"/>
              </template>
            </VueDatePicker>
          </div>
        </div>

        <button class="btn-primary export-btn" @click="showIntegrationList()">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4.66634 14C3.92996 14 3.33301 13.403 3.33301 12.6667V3.33333C3.33301 2.59695 3.92996 2 4.66634 2H9.33301L12.6663 5.33333V12.6667C12.6663 13.403 12.0694 14 11.333 14H4.66634Z" stroke="#6DB534" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M12.667 6L9.33366 6C8.96547 6 8.66699 5.70152 8.66699 5.33333L8.66699 2" stroke="#6DB534" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M10 10L8 8M8 8L6 10M8 8L8 12" stroke="#6DB534" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
          <span class="text">Список интеграций</span>
        </button>

        <button class="btn-primary export-btn" @click="downloadFile('payments_sample')">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4.66634 14C3.92996 14 3.33301 13.403 3.33301 12.6667V3.33333C3.33301 2.59695 3.92996 2 4.66634 2H9.33301L12.6663 5.33333V12.6667C12.6663 13.403 12.0694 14 11.333 14H4.66634Z" stroke="#6DB534" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M12.667 6L9.33366 6C8.96547 6 8.66699 5.70152 8.66699 5.33333L8.66699 2" stroke="#6DB534" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M10 10L8 8M8 8L6 10M8 8L8 12" stroke="#6DB534" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
          <span class="text">Шаблон</span>
        </button>

        <button class="btn-primary export-btn" @click="$emit('uploadXSLX')">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4.66634 14C3.92996 14 3.33301 13.403 3.33301 12.6667V3.33333C3.33301 2.59695 3.92996 2 4.66634 2H9.33301L12.6663 5.33333V12.6667C12.6663 13.403 12.0694 14 11.333 14H4.66634Z" stroke="#6DB534" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M12.667 6L9.33366 6C8.96547 6 8.66699 5.70152 8.66699 5.33333L8.66699 2" stroke="#6DB534" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M10 10L8 8M8 8L6 10M8 8L8 12" stroke="#6DB534" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
          <span class="text">Загрузить .xls(x)</span>
        </button>

        <button class="btn-primary export-btn" @click="$emit('downloadXSLX')">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4.66659 14C3.93021 14 3.33325 13.403 3.33325 12.6667V3.33333C3.33325 2.59695 3.93021 2 4.66659 2H9.33325L12.6666 5.33333V12.6667C12.6666 13.403 12.0696 14 11.3333 14H4.66659Z" stroke="#6DB534" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M12.6667 6L9.33342 6C8.96523 6 8.66675 5.70152 8.66675 5.33333L8.66675 2" stroke="#6DB534" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M6 10L8 12M8 12L10 10M8 12L8 8" stroke="#6DB534" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
          <span class="text">Экспорт в .xls</span>
        </button>
      </div>

      <div v-else class="payments">
<!--        <button class="btn-primary export-btn" v-if="actionType === 'uploadTxFiles'" @click="showIntegrationList()">-->
<!--          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--            <path d="M4.66634 14C3.92996 14 3.33301 13.403 3.33301 12.6667V3.33333C3.33301 2.59695 3.92996 2 4.66634 2H9.33301L12.6663 5.33333V12.6667C12.6663 13.403 12.0694 14 11.333 14H4.66634Z" stroke="#6DB534" stroke-linecap="round" stroke-linejoin="round"/>-->
<!--            <path d="M12.667 6L9.33366 6C8.96547 6 8.66699 5.70152 8.66699 5.33333L8.66699 2" stroke="#6DB534" stroke-linecap="round" stroke-linejoin="round"/>-->
<!--            <path d="M10 10L8 8M8 8L6 10M8 8L8 12" stroke="#6DB534" stroke-linecap="round" stroke-linejoin="round"/>-->
<!--          </svg>-->
<!--          <span class="text">Список интеграций</span>-->
<!--        </button>-->

        <button v-if="actionType === 'uploadTxFiles'" class="btn-primary export-btn" @click="showListVinksRegionsFuel()">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4.66659 14C3.93021 14 3.33325 13.403 3.33325 12.6667V3.33333C3.33325 2.59695 3.93021 2 4.66659 2H9.33325L12.6666 5.33333V12.6667C12.6666 13.403 12.0696 14 11.3333 14H4.66659Z" stroke="#6DB534" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M12.6667 6L9.33342 6C8.96523 6 8.66675 5.70152 8.66675 5.33333L8.66675 2" stroke="#6DB534" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M6 10L8 12M8 12L10 10M8 12L8 8" stroke="#6DB534" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
          <span class="text">Список названий</span>
        </button>

        <button v-if="actionType === 'uploadTxFiles'" class="btn-primary export-btn" @click="downloadFile('transaction-sample')">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4.66659 14C3.93021 14 3.33325 13.403 3.33325 12.6667V3.33333C3.33325 2.59695 3.93021 2 4.66659 2H9.33325L12.6666 5.33333V12.6667C12.6666 13.403 12.0696 14 11.3333 14H4.66659Z" stroke="#6DB534" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M12.6667 6L9.33342 6C8.96523 6 8.66675 5.70152 8.66675 5.33333L8.66675 2" stroke="#6DB534" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M6 10L8 12M8 12L10 10M8 12L8 8" stroke="#6DB534" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
          <span class="text">Шаблон</span>
        </button>

        <button class="btn-primary export-btn" @click="download">
          <svg v-if="uploadFiles" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4.66634 14C3.92996 14 3.33301 13.403 3.33301 12.6667V3.33333C3.33301 2.59695 3.92996 2 4.66634 2H9.33301L12.6663 5.33333V12.6667C12.6663 13.403 12.0694 14 11.333 14H4.66634Z" stroke="#6DB534" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M12.667 6L9.33366 6C8.96547 6 8.66699 5.70152 8.66699 5.33333L8.66699 2" stroke="#6DB534" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M10 10L8 8M8 8L6 10M8 8L8 12" stroke="#6DB534" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
          <svg v-else width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4.66659 14C3.93021 14 3.33325 13.403 3.33325 12.6667V3.33333C3.33325 2.59695 3.93021 2 4.66659 2H9.33325L12.6666 5.33333V12.6667C12.6666 13.403 12.0696 14 11.3333 14H4.66659Z" stroke="#6DB534" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M12.6667 6L9.33342 6C8.96523 6 8.66675 5.70152 8.66675 5.33333L8.66675 2" stroke="#6DB534" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M6 10L8 12M8 12L10 10M8 12L8 8" stroke="#6DB534" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
          <span v-if="noTable" class="text">Скачать</span>
          <span v-else-if="uploadFiles" class="text">Загрузить .xls</span>
          <span v-else class="text">Экспорт в .xls</span>
        </button>
      </div>

      <button @click="resetFilters()" class="btn-primary export-btn reset-filters-btn">
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M2 8C2 11.3137 4.68629 14 8 14C11.3137 14 14 11.3137 14 8C14 4.68629 11.3137 2 8 2C6.11516 2 4.4333 2.86911 3.33333 4.22844" stroke="#6DB534" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M3 2L3 4.66667L5.66667 4.66667" stroke="#6DB534" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        <span class="text">Сбросить фильтр</span>
      </button>
    </div>

    <span v-if="!columns || isLoading" class="loader">
      <svg class="circular" height="50" width="50">
        <circle class="path" cx="25" cy="25.2" r="19.9" fill="none" stroke-width="6" stroke-miterlimit="10" />
      </svg>
    </span>

    <div v-if="!noTable && columns && actionType === 'transactionReportActions'" @scroll="setTableScroll($event)" class="table-scroll-line">
      <div :style="{ width: getWidth + 'px' }"></div>
    </div>
    <div id="Table" v-if="!noTable" @scroll="setTableScroll($event)" class="table-scroll" :class="{'no-scroll': filterPopupShown !== null || filterPopupShown_2 !== null}">
      <table v-if="columns" :id="'table-block-' + actionType" :class="{'bordered': isBordered, 'light-font': isLightFont}" style="position:relative;">
        <thead>
        <tr>
          <th v-if="checkingOn">
            <label class='checkbox-cont'>
              <input @input="checkAll($event)" v-model="allChecked" class="checkbox" type='checkbox' id="checkAll">
              <span :class="{'semi-active': semiActive}" class='indicator'></span>
            </label>
          </th><!--@mouseleave="closePopups"-->
          <th v-for="(column, i) of columns"
              :key="i"
              @click="showFilterPopup($event, i, column)"
              :style="{
                width: column.field === 'actions' && actionType === 'references' ? '64px' : actionType === 'references' ? '47%' : 'auto',
                minWidth: column.field === 'actions' && actionType === 'references' ? '64px' : 'auto'
              }"
              :class="{'active': ((filterPopupShown && filterPopupShown.index === i) || (column.filter_value || column.filter_value_from || column.filter_value_to || column.order === 'asc' || column.order === 'desc')) || ((filterPopupShown_2 && filterPopupShown_2.index === i) || column.filter_value || column.order === 'asc' || column.order === 'desc'), 'non-hoverable': column.field === 'actions'}"
          ><!--@mouseenter="showFilterPopup($event, i, column)"
            @mouseleave="checkMousePosition"-->
            <div class="bg"></div>
            <span class="inner">
              <span v-html="column.name"></span>
              <span class="arrow" v-if="column.field !== 'actions' && !column.no_filter">
                <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M8.75 1.66675H1.25L4.16667 5.19175V7.91675L5.83333 8.75008V5.19175L8.75 1.66675Z" stroke="#666687" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </span>
            </span>
<!--            FILTER POPUP-->
          </th>
        </tr>
        </thead>
        <tbody v-if="!noData">

        <template v-if="cardsReport" v-for="(row, k) of rows">
          <tr>
            <td :colspan="columns.length" style="font-weight: 600">Номер карты: <span style="color: #6DB534;">{{ row.number }}</span> Держатель: {{ row.holder }}</td>
          </tr>
          <template v-for="(transaction, transactionI) of row.transactions">
            <tr v-if="transactionI < 10 && !row[`show_all`]">
              <td v-for="(column, j) of columns"
                  :key="j"
                  @click="cellClicked($event, transaction, column.field, k)"
                  :class="[row.class, {'action-td': column.field === 'actions', 'clickable': transaction.hidden_filed && column.field === transaction.hidden_filed}]"
              >
                <div v-if="column.fuel_type">
                  <span>{{ transaction[column.field].name }}</span>
                </div>

                <div v-else-if="column.field === 'fuel_provider_id'">
                  <span>{{ getFuelProviderName(column, transaction) }}</span>
                </div>

                <div v-else-if="column.date_type">
                  <span>{{ getFormatedDate(column, transaction) }}</span>
                </div>

                <span v-else :class="{'green': transaction.green && transaction.green[column.field], 'red': transaction.red && transaction.red[column.field]}">{{transaction[column.field]}}</span>

                <svg v-if="column.field === 'actions'" width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M6.125 3.27783C6.125 3.5099 6.21719 3.73246 6.38128 3.89655C6.54538 4.06065 6.76794 4.15283 7 4.15283C7.23206 4.15283 7.45462 4.06065 7.61872 3.89655C7.78281 3.73246 7.875 3.5099 7.875 3.27783C7.875 3.04577 7.78281 2.82321 7.61872 2.65911C7.45462 2.49502 7.23206 2.40283 7 2.40283C6.76794 2.40283 6.54538 2.49502 6.38128 2.65911C6.21719 2.82321 6.125 3.04577 6.125 3.27783ZM6.125 7.65283C6.125 7.8849 6.21719 8.10746 6.38128 8.27155C6.54538 8.43565 6.76794 8.52783 7 8.52783C7.23206 8.52783 7.45462 8.43565 7.61872 8.27155C7.78281 8.10746 7.875 7.8849 7.875 7.65283C7.875 7.42077 7.78281 7.19821 7.61872 7.03411C7.45462 6.87002 7.23206 6.77783 7 6.77783C6.76794 6.77783 6.54538 6.87002 6.38128 7.03411C6.21719 7.19821 6.125 7.42077 6.125 7.65283ZM6.125 12.0278C6.125 12.2599 6.21719 12.4825 6.38128 12.6466C6.54538 12.8106 6.76794 12.9028 7 12.9028C7.23206 12.9028 7.45462 12.8106 7.61872 12.6466C7.78281 12.4825 7.875 12.2599 7.875 12.0278C7.875 11.7958 7.78281 11.5732 7.61872 11.4091C7.45462 11.245 7.23206 11.1528 7 11.1528C6.76794 11.1528 6.54538 11.245 6.38128 11.4091C6.21719 11.5732 6.125 11.7958 6.125 12.0278Z" fill="#6DB534"/>
                </svg>

              </td>
            </tr>
          </template>
          <tr v-if="row[`show_all`]" v-for="transaction of row.transactions">
            <td v-for="(column, j) of columns"
                :key="j"
                @click="cellClicked($event, transaction, column.field, k)"
                :class="[row.class, {'action-td': column.field === 'actions', 'clickable': transaction.hidden_filed && column.field === transaction.hidden_filed}]"
            >
              <div v-if="column.fuel_type">
                <span>{{ transaction[column.field].name }}</span>
              </div>

              <div v-else-if="column.field === 'fuel_provider_id'">
                <span>{{ getFuelProviderName(column, transaction) }}</span>
              </div>

              <div v-else-if="column.date_type">
                <span>{{ getFormatedDate(column, transaction) }}</span>
              </div>

              <span v-else :class="{'green': transaction.green && transaction.green[column.field], 'red': transaction.red && transaction.red[column.field]}">{{transaction[column.field]}}</span>

              <svg v-if="column.field === 'actions'" width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6.125 3.27783C6.125 3.5099 6.21719 3.73246 6.38128 3.89655C6.54538 4.06065 6.76794 4.15283 7 4.15283C7.23206 4.15283 7.45462 4.06065 7.61872 3.89655C7.78281 3.73246 7.875 3.5099 7.875 3.27783C7.875 3.04577 7.78281 2.82321 7.61872 2.65911C7.45462 2.49502 7.23206 2.40283 7 2.40283C6.76794 2.40283 6.54538 2.49502 6.38128 2.65911C6.21719 2.82321 6.125 3.04577 6.125 3.27783ZM6.125 7.65283C6.125 7.8849 6.21719 8.10746 6.38128 8.27155C6.54538 8.43565 6.76794 8.52783 7 8.52783C7.23206 8.52783 7.45462 8.43565 7.61872 8.27155C7.78281 8.10746 7.875 7.8849 7.875 7.65283C7.875 7.42077 7.78281 7.19821 7.61872 7.03411C7.45462 6.87002 7.23206 6.77783 7 6.77783C6.76794 6.77783 6.54538 6.87002 6.38128 7.03411C6.21719 7.19821 6.125 7.42077 6.125 7.65283ZM6.125 12.0278C6.125 12.2599 6.21719 12.4825 6.38128 12.6466C6.54538 12.8106 6.76794 12.9028 7 12.9028C7.23206 12.9028 7.45462 12.8106 7.61872 12.6466C7.78281 12.4825 7.875 12.2599 7.875 12.0278C7.875 11.7958 7.78281 11.5732 7.61872 11.4091C7.45462 11.245 7.23206 11.1528 7 11.1528C6.76794 11.1528 6.54538 11.245 6.38128 11.4091C6.21719 11.5732 6.125 11.7958 6.125 12.0278Z" fill="#6DB534"/>
              </svg>

            </td>
          </tr>
          <tr v-if="row && row.transactions && row.transactions.length > 10">
            <td>
              <button class="show-all-btn"
                      @click="row[`show_all`] = !row[`show_all`];$forceUpdate()"
              >
                Показать {{ row[`show_all`] ? 'последние' : 'все' }}
              </button>
            </td>
          </tr>
          <tr>
            <td v-for="(column, j) of additionalColumns"
                @mouseleave="checkMousePosition"
                :colspan="columns.length / additionalColumns.length"
            >
              <span style="font-weight: 600">{{ column.name }}</span>
            </td>
          </tr>
          <tr v-for="total of row.total">
            <td v-for="(column, j) of additionalColumns"
                @click="cellClicked($event, total, column.field, k)"
                :class="[row.class, {'action-td': column.field === 'actions', 'clickable': total.hidden_filed && column.field === total.hidden_filed}]"
                :colspan="columns.length / additionalColumns.length"
            ><!--@mouseenter="cellHovered($event, total, column.field, k)"
              @mouseleave="checkMousePosition"-->

              <div v-if="column.field === 'fuelType'">
                <span>{{ total[column.field].name }}</span>
              </div>

              <span v-else>{{total[column.field]}}</span>
            </td>
          </tr>
          <tr>
            <td v-for="(column, j) of additionalColumns"
                @mouseleave="checkMousePosition"
                :colspan="columns.length / additionalColumns.length"
            >

              <div v-if="column.field === 'fuelType'">
                <span></span>
              </div>

              <span v-else>{{ getTotalSum(column.field, row.total) }}</span>
            </td>
          </tr>
        </template>

        <tr v-else v-for="(row, k) of rows" :key="k">
          <td v-if="checkingOn">
            <label class='checkbox-cont'>
              <input v-model="row.checked" @input="checkChanged(row)" class="checkbox" type='checkbox' :id="`check_${k}`">
              <span class='indicator'></span>
            </label>
          </td>
          <td v-for="(column, j) of columns"
              :key="j"
              @click="cellClicked($event, row, column.field, k)"
              :class="[row.class, {'action-td': column.field === 'actions', 'clickable': row.hidden_filed && column.field === row.hidden_filed}]"
          ><!--@mouseenter="cellHovered($event, row, column.field, k)"
            @mouseleave="checkMousePosition"-->
            <span class="hidden" v-if="row.hidden_filed && column.field === row.hidden_filed">
              <span v-if="!row.hidden">{{row[column.field]}}</span>
              <span v-else>****</span>
            </span>

            <div v-else-if="column.surety" class="guarantee">
              <span>{{row[column.field]}}</span>
              <button :disabled="!row['surety']" :class="{ 'disabled-btn': !row['surety'] }" @click="showSurety(row['surety_info'])">
                <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M9.8 8.3333C9.8 9.32741 8.99411 10.1333 8 10.1333C7.00589 10.1333 6.2 9.32741 6.2 8.3333C6.2 7.33919 7.00589 6.5333 8 6.5333C8.99411 6.5333 9.8 7.33919 9.8 8.3333Z" stroke="#6DB534" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M2 8.3333C2.96008 5.87489 5.20162 4.1333 8 4.1333C10.7984 4.1333 13.0399 5.87489 14 8.3333C13.0399 10.7917 10.7984 12.5333 8 12.5333C5.20162 12.5333 2.96008 10.7917 2 8.3333Z" stroke="#6DB534" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                <span>Подробнее</span>
              </button>
            </div>

            <div v-else-if="column.fuel_type">
              <span>
                <template v-for="fuelType in row[column.field]">
                  {{ fuelType }}
                  <br>
                </template>
              </span>
            </div>

            <div v-else-if="column.fuel_types">
              <span>{{ getFuelTypes(column, row) }}</span>
            </div>

            <div v-else-if="column.field === 'fuel_provider_id'">
              <span>{{ getFuelProviderName(column, row) }}</span>
            </div>

            <div v-else-if="column.field === 'integration_name'">
              <span>{{ row.integration?.name }}</span>
            </div>

            <div v-else-if="column.fuel_types_total">
              <span>{{ getFuelTypesTotal(column, row) }}</span>
            </div>

            <div v-else-if="column.fuel_types_total_end">
              <span>{{ getFuelTypesTotalEnd(column, row) }}</span>
            </div>

            <div v-else-if="column.field === 'limit_id'">
              <span>{{ getLimitName(column, row) }}</span>
            </div>

            <div v-else-if="column.date_type">
              <span>{{ getFormatedDate(column, row) }}</span>
            </div>

            <div v-else-if="column.month_arr">
              <span>
                <template v-if="row[`${column.field}_show_all`]"
                          v-for="(monthItem, monthIndex) in row[column.field]">
                  {{ getMonthName(monthIndex) }} - <span :class="{'green': Number(monthItem) > 0, 'red': Number(monthItem) < 0}">{{ Number(monthItem).toFixed(2) }}</span>
                  <br>
                </template>
                <template v-else>
                  {{ getMonthNameCurrent(row[column.field]) }} - <span :class="{'green': getMonthValueCurrent(row[column.field]) > 0, 'red': getMonthValueCurrent(row[column.field]) < 0}">{{ getMonthValueCurrent(row[column.field]) }}</span>
                  <br>
                </template>
                <button class="show-all-btn"
                        @click="row[`${column.field}_show_all`] = !row[`${column.field}_show_all`];$forceUpdate()"
                >
                  Показать {{ row[`${column.field}_show_all`] ? 'текущий' : 'все' }}
                </button>
              </span>
            </div>

            <div v-else-if="column.balance_field">
              <span v-if="row[column.field] > 0" style="color: #6DB534">{{ Number(row[column.field]).toFixed(2) }}</span>
              <span v-if="row[column.field] <= 0" style="color: #FF0000">{{ Number(row[column.field]).toFixed(2) }}</span>
            </div>

            <div v-else-if="column.field === 'go_into_minus'">
              <span v-if="row[column.field] > 0" style="color: #6DB534">{{ Number(row[column.field]).toFixed(2) }}</span>
              <span v-if="row[column.field] <= 0" style="color: #FF0000">{{ Number(row[column.field]).toFixed(2) }}</span>
            </div>

            <div v-else-if="column.field === 'saldo_beginning_of_year'">
              {{ Number(row[column.field]).toFixed(2) }}
            </div>

            <div v-else-if="column.cards_field">
              <span>
                <span style="color: #6DB534">{{ row['cards_active_count'] }}</span> <span style="color: #FF0000">({{ row['cards_blocked_count'] }})</span>
              </span>
            </div>

            <div v-else-if="column.card_limits">
              <template v-for="(limit, limitIndex) of row[column.field]">
                <span v-if="!row.show_all && limitIndex < 3">
                  <template v-if="row.integration_id === 1 || row.integration_id === 2">
                    {{ limit.fuel_name }} - {{ limit.amount_limit >= 0 ? limit.amount_limit : limit.admin_amount_limit }} {{ limit.currency_name }}\{{ limit.duration_name }}
                  </template>
                  <template v-if="row.integration_id !== 1 && row.integration_id !== 2">
                    {{ limit.fuelType?.name }} - {{ limit.amount_limit >= 0 ? limit.amount_limit : limit.admin_amount_limit }} {{ limit.currencyLimit?.name }}\{{ limit.dateLimit?.name }}
                  </template>
                  <br>
                </span>
              </template>
              <template v-for="limit of row[column.field]">
                <span v-if="row.show_all">
                  <template v-if="row.integration_id === 1 || row.integration_id === 2">
                    {{ limit.fuel_name }} - {{ limit.amount_limit >= 0 ? limit.amount_limit : limit.admin_amount_limit }} {{ limit.currency_name }}\{{ limit.duration_name }}
                  </template>
                  <template v-if="row.integration_id !== 1 && row.integration_id !== 2">
                    {{ limit.fuelType?.name }} - {{ limit.amount_limit >= 0 ? limit.amount_limit : limit.admin_amount_limit }} {{ limit.currencyLimit?.name }}\{{ limit.dateLimit?.name }}
                  </template>
                  <br>
                </span>
              </template>
              <button class="show-all-btn"
                      v-if="row[column.field].length > 3"
                      @click="row.show_all = !row.show_all;$forceUpdate()"
              >
                {{ row.show_all ? 'Скрыть' : 'Показать все' }}
              </button>
            </div>

            <span v-else :class="{'green': row.green && row.green[column.field], 'red': row.red && row.red[column.field]}">{{row[column.field]}}</span>

            <svg v-if="column.field === 'actions'" width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M6.125 3.27783C6.125 3.5099 6.21719 3.73246 6.38128 3.89655C6.54538 4.06065 6.76794 4.15283 7 4.15283C7.23206 4.15283 7.45462 4.06065 7.61872 3.89655C7.78281 3.73246 7.875 3.5099 7.875 3.27783C7.875 3.04577 7.78281 2.82321 7.61872 2.65911C7.45462 2.49502 7.23206 2.40283 7 2.40283C6.76794 2.40283 6.54538 2.49502 6.38128 2.65911C6.21719 2.82321 6.125 3.04577 6.125 3.27783ZM6.125 7.65283C6.125 7.8849 6.21719 8.10746 6.38128 8.27155C6.54538 8.43565 6.76794 8.52783 7 8.52783C7.23206 8.52783 7.45462 8.43565 7.61872 8.27155C7.78281 8.10746 7.875 7.8849 7.875 7.65283C7.875 7.42077 7.78281 7.19821 7.61872 7.03411C7.45462 6.87002 7.23206 6.77783 7 6.77783C6.76794 6.77783 6.54538 6.87002 6.38128 7.03411C6.21719 7.19821 6.125 7.42077 6.125 7.65283ZM6.125 12.0278C6.125 12.2599 6.21719 12.4825 6.38128 12.6466C6.54538 12.8106 6.76794 12.9028 7 12.9028C7.23206 12.9028 7.45462 12.8106 7.61872 12.6466C7.78281 12.4825 7.875 12.2599 7.875 12.0278C7.875 11.7958 7.78281 11.5732 7.61872 11.4091C7.45462 11.245 7.23206 11.1528 7 11.1528C6.76794 11.1528 6.54538 11.245 6.38128 11.4091C6.21719 11.5732 6.125 11.7958 6.125 12.0278Z" fill="#6DB534"/>
            </svg>

          </td>
        </tr>
        <tr v-if="additionalRows && additionalColumns.length === 0" v-for="(additionalRow, l) of additionalRows" :key="l"  :class="{'light-font': isLightFontAdditional}">
          <td v-for="(column, m) of columns" :key="m">
          <span>
            <span :class="{'strong': additionalRow.strong_text && additionalRow.strong_text === column.field}">{{additionalRow[column.field]}}</span>
          </span>
          </td>
        </tr>
        <tr v-if="additionalColumns2.length > 0"><td></td></tr>
        <tr v-if="additionalColumns2.length > 0">
          <td v-for="(column, j) of additionalColumns2"
              @mouseleave="checkMousePosition"
              :colspan="columns.length / additionalColumns2.length"
          >
            <span style="font-weight: 600">{{ column.name }}</span>
          </td>
        </tr>
        <tr v-if="additionalColumns2.length > 0" v-for="total of additionalColumnsData2">
          <td v-for="(column, j) of additionalColumns2"
              @click="cellClicked($event, total, column.field, j)"
              :colspan="columns.length / additionalColumns2.length"
          >

            <div v-if="column.field === 'fuelType'">
              <span>{{ total[column.field].name }}</span>
            </div>

            <span v-else>{{Number(total[column.field]).toFixed(2)}}</span>
          </td>
        </tr>
        <tr>
          <td v-for="(column, j) of additionalColumns2"
              @mouseleave="checkMousePosition"
              :colspan="columns.length / additionalColumns2.length"
          >

            <div v-if="column.field === 'fuelType'">
              <span></span>
            </div>

            <span v-else>{{ getTotalSum(column.field, additionalColumnsData2).toFixed(2) }}</span>
          </td>
        </tr>
        </tbody>
      </table>
      <div v-if="separateString" v-html="separateString"></div>
      <table class="additional" v-if="isAdditionalTable && !cardsReport" :class="{'bordered': isBordered, 'light-font': isLightFontAdditional}">
        <thead v-if="showAdditionalTableHeader">
        <tr>
          <th v-for="(column, i) of additionalColumns" :key="i">
            {{column.name}}
          </th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(row, k) of additionalRows" :key="k">
          <td v-for="(column, j) of additionalColumns" :key="j">
            {{row[column.field]}}
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <span v-if="noData" class="no-data">Нет данных</span>

    <div v-if="pageCount > 0" class="pagination">
      <div class="size">
        <div class="input">
          <v-select class="small pagination-select"
                    :options="sizes"
                    v-model="size"
                    :clearable="false"
                    :searchable="false"
                    :appendToBody="true"
                    :calculatePosition="calculatePosition"
                    @option:selected="emitReGetData"
          >
            <template #open-indicator="{ attributes }">
              <span v-bind="attributes">
                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M9.5 4.44444C9.5 4.56481 9.45671 4.66898 9.37012 4.75694L6.30762 7.86806C6.22103 7.95602 6.11849 8 6 8C5.88151 8 5.77897 7.95602 5.69238 7.86806L2.62988 4.75694C2.54329 4.66898 2.5 4.56481 2.5 4.44444C2.5 4.32407 2.54329 4.21991 2.62988 4.13194C2.71647 4.04398 2.81901 4 2.9375 4H9.0625C9.18099 4 9.28353 4.04398 9.37012 4.13194C9.45671 4.21991 9.5 4.32407 9.5 4.44444Z" fill="#6DB534"/>
                </svg>
              </span>
            </template>
          </v-select>
        </div>
        <div class="label">
          Записей на странице
        </div>
      </div>
      <vue-awesome-paginate
        :total-items="totalCount"
        :items-per-page="Number(pageSize)"
        :max-pages-shown="5"
        v-model="currentPageModel"
        :on-click="onClickHandler"
      >
        <template #prev-button>
          <span>
            <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M6 8.825L2.2915 5L6 1.175L4.8583 0L0 5L4.8583 10L6 8.825Z" :fill="isPrevButtonDisabled ? '#575656' : '#6DB534'"/>
            </svg>
          </span>
        </template>
        <template #next-button>
          <span>
            <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M0 8.825L3.7085 5L0 1.175L1.1417 0L6 5L1.1417 10L0 8.825Z" :fill="isNextButtonDisabled ? '#575656' : '#6DB534'"/>
            </svg>
          </span>
        </template>
      </vue-awesome-paginate>
<!--      <div class="pages">-->
<!--        <button @click="prevPage" :disabled="isPrevButtonDisabled">-->
<!--          <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--            <path d="M6 8.825L2.2915 5L6 1.175L4.8583 0L0 5L4.8583 10L6 8.825Z" :fill="isPrevButtonDisabled ? '#575656' : '#6DB534'"/>-->
<!--          </svg>-->
<!--        </button>-->
<!--        <button @click="selectPage(i + 1)" v-if="pageCount" v-for="(page, i) of pageCount" :key="i" :class="{'active': currentPage === i + 1}">{{i + 1}}</button>-->
<!--        <button @click="nextPage" :disabled="isNextButtonDisabled">-->
<!--          <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--            <path d="M0 8.825L3.7085 5L0 1.175L1.1417 0L6 5L1.1417 10L0 8.825Z" :fill="isNextButtonDisabled ? '#575656' : '#6DB534'"/>-->
<!--          </svg>-->
<!--        </button>-->
<!--      </div>-->
    </div>
    <input id="fileUpload" @input="addFile($event)" type="file" accept=".xlsx, .xls" hidden>
  </div>
</template>

<script>
import orderFilterIcon from '../components/orderFilterIcon.vue'
import { ref } from 'vue';
import { DatePickerInstance } from "@vuepic/vue-datepicker"
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import {mapActions, mapMutations, mapState} from "vuex";
import usersManagement from "@/Pages/Admin/UsersManagement.vue";
import cardsReport from "@/Pages/Reports/CardsReport.vue";
import vSelect from "vue-select";
import {VueAwesomePaginate} from "vue-awesome-paginate";

export default {
  name: "Table",
  props: {
    isSecondTable: {
      type: Boolean,
      default: false
    },
    checkingOn: {
      type: Boolean,
      default: false
    },
    totalCount: {
      type: Number,
      default: 0
    },
    currentPage: {
      type: Number,
      default: 1
    },
    pageCount: {
      type: Number,
      default: 0
    },
    pageSize: {
      type: [Number, String],
      default: 5
    },
    datePicker: {
      type: Boolean,
      default: false
    },
    cardsReport: {
      type: Boolean,
      default: false
    },
    paymentsActions: {
      type: Boolean,
      default: false
    },
    paymentsActions2: {
      type: Boolean,
      default: false
    },
    uploadFiles: {
      type: Boolean,
      default: false
    },
    usersManagement: {
      type: Boolean,
      default: false
    },
    cardActions: {
      type: Boolean,
      default: false
    },
    noTable: {
      type: Boolean,
      default: false
    },
    isBordered: {
      type: Boolean,
      default: false
    },
    isLightFont: {
      type: Boolean,
      default: false
    },
    isLightFontAdditional: {
      type: Boolean,
      default: false
    },
    showAdditionalTableHeader: {
      type: Boolean,
      default: true
    },
    title: {
      type: String,
      default: ''
    },
    separateString: {
      type: String,
      default: ''
    },
    columns: {
      type: Array,
      default: []
    },
    noData: {
      type: Boolean,
      default: false
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    rows: {
      type: Array,
      default: []
    },
    additionalColumns: {
      type: Array,
      default: []
    },
    additionalColumns2: {
      type: Array,
      default: []
    },
    additionalColumnsData2: {
      type: Array,
      default: []
    },
    additionalRows: {
      type: Array,
      default: []
    },
    actionType: {
      type: String,
      default: '',
      require: true
    },
    actionType_2: {
      type: String,
      default: ''
    }
  },
  components: {
    VueAwesomePaginate,
    vSelect,
    orderFilterIcon,
    VueDatePicker
  },
  data() {
    return {
      getWidth: 0,
      loading: false,
      attributes: {
        'ref': 'openIndicator',
        'role': 'presentation',
        'class': 'vs__open-indicator',
      },
      //filterPopupShown: null,
      //actionsPopupShown: null,
      allChecked: false,
      pageNumber: 1,
      size: 100,
      sizes: [50, 100],
      cardActionsPopupShown: false,
      limitActionsPopupShown: false,
      activeIndex: 0,
      date: null,
      clientOptions: [
        {title: 'Наименование клиента', value: 1},
        {title: 'Наименование клиента', value: 2},
        {title: 'Наименование клиента', value: 3},
      ],
      client: '',
      cardOptions: [
        {title: '7013420001865393', value: 1},
        {title: '2222222222222222', value: 2},
        {title: '3453453453333333', value: 3},
      ],
      card: '',
      gasStationOptions: [
        {title: 'Татнефть', value: 1},
        {title: 'Танеко', value: 2},
        {title: 'Лукойл', value: 3},
      ],
      gasStation: {title: 'Татнефть', value: 1},
      currentPageModel: 1
    }
  },
  watch: {
    rows(val) {
      this.allChecked = false
      setTimeout(() => {
        let table = document.getElementById(`table-block-${this.actionType}`);
        this.getWidth = table?.offsetWidth
      }, 1500)
    },
    currentPage(val) {
      this.currentPageModel = this.currentPage
    },
    client(val) {
      // if (this.client === null) {
        this.clearSelectOptions()
      // }
    },
    card(val) {
      // if (this.card === null) {
        this.clearNumberCardSelectOptions()
      // }
    }
  },
  computed: {
    ...mapState({
      tableFilterPopup: state => state.popups.tableFilterPopup,
      tableFilterPopup_2: state => state.popups.tableFilterPopup_2,
      mouseOverFilter: state => state.popups.mouseOverFilter,
      cardsReportFilters: state => state.popups.cardsReportFilters,
      userRole: state => state.user.userRole,
      cardLimits: state => state.user.card_limits,
      managers: state => state.admin.managers,
      cardsSelect: state => state.admin.cardsSelect,
      fuelProviders: state => state.user.fuelProviders,
      columns_store: state => state.popups.columns,
      columns_2_store: state => state.popups.columns_2,
      paginationFilters_store: state => state.popups.paginationFilters,
    }),
    noCheckedRows() {
      return !this.rows.some(item => item.checked === true)
    },
    integrationAndVink() {
      let checkedRows = this.rows.filter(item => item.checked === true)
      let differentIntegration = false;
      let delkoIntegration = false;
      let delkoIntegration2 = false;
      let noDelkoIntegration = false;
      let integrationId = 0;

      checkedRows.forEach(item => {
        if (item.integration_id === 1) {
          delkoIntegration = true;
        } else if (item.integration_id === 2) {
          delkoIntegration2 = true;
        } else {
          noDelkoIntegration = true
        }
        if (integrationId !== 0 && item.integration_id !== integrationId) {
          differentIntegration = true
        }
        integrationId = item.integration_id
      })

      if (!differentIntegration && (delkoIntegration || delkoIntegration2)) {
        let fuel_provider_id = checkedRows[0].fuel_provider_id;
        checkedRows.forEach(item => {
          if (item.fuel_provider_id !== fuel_provider_id) {
            differentIntegration = true
          }
          fuel_provider_id = item.fuel_provider_id;
        })
      }

      if (!delkoIntegration && !delkoIntegration2) {
        differentIntegration = false;
      }

      // if (!differentIntegration && !delkoIntegration && !delkoIntegration2) {
      //   let fuel_provider_id = checkedRows[0].fuel_provider_id;
      //   checkedRows.forEach(item => {
      //     if (item.fuel_provider_id !== fuel_provider_id) {
      //       differentIntegration = true
      //     }
      //     fuel_provider_id = item.fuel_provider_id;
      //   })
      // }
      return differentIntegration;
    },
    semiActive() {
      return this.rows.some(item => item.checked === true) && this.rows.some(item => item.checked === false)
    },
    allLocked() {
      let checkedRows = this.rows.filter(item => item.checked === true)
      return checkedRows.every(item => item.status_id === 1 || item.status_id === 3)
    },
    allUnlocked() {
      let checkedRows = this.rows.filter(item => item.checked === true)
      return checkedRows.every(item => item.status_id === 2)
    },
    isAdditionalTable() {
      return this.additionalColumns.length > 0 && this.additionalRows.length > 0
    },
    filterPopupShown: {
      get() {
        return this.tableFilterPopup
      },
      set(val) {
        this.setTableFilterPopup(val)
      }
    },
    filterPopupShown_2: {
      get() {
        return this.tableFilterPopup_2
      },
      set(val) {
        this.setTableFilterPopup_2(val)
      }
    },
    // pageCount(){
    //   let l = this.totalCount,
    //       s = this.size;
    //   // редакция переводчика спасибо комментаторам
    //   return Math.ceil(l/s);
    //   // оригинал
    //   // return Math.floor(l/s);
    // }
    isNextButtonDisabled() {
      return this.currentPage >= this.pageCount
    },
    isPrevButtonDisabled() {
      return this.currentPage === 1
    }
  },
  methods: {
    ...mapActions({
      bunchUploadCardsFile: 'bunchUploadCardsFile',
      getCustomers: 'getCustomers',
      getUsersSearch: 'getUsersSearch',
      getCardsSelect: 'getCardsSelect',
      getCardsReport: 'getCardsReport',
      getTransactionReport: 'getTransactionReport',
      getCardsFromDelko: 'getCardsFromDelko',
      getCards: 'getCards',
      getAllUsers: 'getAllUsers',
      getReviseReport: 'getReviseReport',
      getManagerDashboard: 'getManagerDashboard',
      getPayments: 'getPayments',
      getPricesFiles: 'getPricesFiles',
      getTransactionFiles: 'getTransactionFiles',
      getFileByPath: 'getFileByPath',
      getCardStatuses: 'getCardStatuses',
      getOperationsReport: 'getOperationsReport',
      getAccountantDashboard: 'getAccountantDashboard',
      syncCardStatusesFromDelko: 'syncCardStatusesFromDelko',
      getCardCatalog: 'getCardCatalog',
    }),
    ...mapMutations({
      setCustomer: 'SET_CUSTOMER',
      toggleOverlay: 'TOGGLE_OVERLAY',
      showAddCard: 'SHOW_ADD_CARD',
      setShowUploadCardsPopup: 'SET_SHOW_UPLOAD_CARDS_POPUP',
      showAddNewUser: 'SHOW_ADD_NEW_USER',
      setTableFilterPopup: 'SET_TABLE_FILTER_POPUP',
      setTableFilterPopup_2: 'SET_TABLE_FILTER_POPUP_2',
      setColumns: 'SET_COLUMNS',
      setColumns_2: 'SET_COLUMNS_2',
      setActionType: 'SET_ACTION_TYPE',
      setActionPopup: 'SET_ACTION_POPUP',
      showBunchChangeCardStatusPopup: 'SHOW_BUNCH_CHANGE_CARD_STATUS',
      setCardsChecked: 'SET_CARDS_CHECKED',
      showSetCardLimit: 'SHOW_SET_CARD_LIMIT',
      showSetCardLimitCustomer: 'SHOW_SET_CARD_LIMIT_CUSTOMER',
      showSuretyInfo: 'SHOW_SURETY_INFO_POPUP',
      dataSuretyInfo: 'DATA_SURETY_INFO_POPUP',
      setDateRangeStore: 'SET_DATE_RANGE',
      setManagers: 'SET_MANAGERS',
      setCardsReportFilters: 'SET_CARDS_REPORT_FILTERS',
      setPaginationFilters: 'SET_PAGINATION_FILTERS',
      setPaginationFilters_2: 'SET_PAGINATION_FILTERS_2',
      setShowIntegrationsListPopup: 'SET_SHOW_INTEGRATION_LIST_POPUP',
      setShowListVinksRegionsFuel: 'SET_SHOW_LIST_VINKS_REGIONS_FUEL_POPUP',
    }),
    setLimitForCards() {
      let cardIds = []
      for (let i = 0; i < this.rows.length; i++) {
        let row = this.rows[i]
        if (row.checked) {
          cardIds.push(row)
        }
      }
      // this.setCardsChecked(cardIds)
      // this.toggleOverlay(true)
      // this.showSetCardLimitCustomer(false)
      // this.showSetCardLimit(true)


      this.loading = true;
      this.getCardCatalog(cardIds[0].id).then(res => {
        this.loading = false;
        this.setCardsChecked(cardIds)
        this.toggleOverlay(true)
        this.showSetCardLimitCustomer(false)
        this.showSetCardLimit(true)
      }).catch(e => {
        this.loading = false;
      });
    },
    setCardLimitForClient() {
      // let cards = []
      // for (let i = 0; i < this.rows.length; i++) {
      //   let row = this.rows[i]
      //   if (row.checked) {
      //     cards.push(row)
      //   }
      // }

      // this.setCardsChecked([])

      // this.setCustomer({
      //   id: cards[0].customer?.id,
      //   name: cards[0].customer?.user?.name
      // })
      this.toggleOverlay(true)
      this.showSetCardLimitCustomer(true)
      this.showSetCardLimit(true)
    },
    changeStatus() {
      let cardIds = []
      for (let i = 0; i < this.rows.length; i++) {
        let row = this.rows[i]
        if (row.checked) {
          cardIds.push(row)
        }
      }

      this.setCardsChecked(cardIds)
      this.toggleOverlay(true)
      this.showBunchChangeCardStatusPopup(true)
    },
    async checkAll(event) {
      await this.$nextTick()
      console.log('checkAll', event.target.checked, this.allChecked)
      let setAll = value => {
        for (let i = 0; i < this.rows.length; i++) {
          this.rows[i].checked = value
        }
      }
      await this.$nextTick()
      setAll(event.target.checked)
    },
    async checkChanged(row) {
      //console.log(row)
      await this.$nextTick()
      setTimeout(() => {
        if (this.rows.every(item => item.checked === true)) {
          this.allChecked = true
        } else {
          this.allChecked = false
        }
      }, 20)
    },
    selectPage(number){
      if (this.currentPage !== number) {
        this.pageNumber = number;
        this.$emit('regetData', {
          size: this.size,
          pageNumber: this.pageNumber
        })
      }
    },
    nextPage(){
      this.pageNumber++;
      this.$emit('regetData', {
        size: this.size,
        pageNumber: this.pageNumber
      })
    },
    prevPage(){
      this.pageNumber--;
      this.$emit('regetData', {
        size: this.size,
        pageNumber: this.pageNumber
      })
    },
    onClickHandler(data) {
      console.log('-----------data', data)
      if (this.currentPage !== data) {
        this.pageNumber = data;
        this.$emit('regetData', {
          size: this.size,
          pageNumber: this.pageNumber
        })
      }
    },
    emitReGetData() {
      console.log('reget')
      this.$emit('regetData', {
        size: this.size,
        pageNumber: this.pageNumber
      })
    },
    async setDateRange() {
      this.loading = true;
      if (this.date[0] === null || this.date[1] === null) {
        setTimeout(() => {
          this.$refs.dateRange.openMenu();
        }, 500)
        return;
      }
      if (this.actionType === 'clientsManagement') {
        await this.setDateRangeStore({ name: this.actionType, str: `${this.date[0].year}.${this.date[0].month + 1}-${this.date[1].year}.${this.date[1].month + 1}` });
        await this.getCustomers().then(res => {
          this.loading = false;
        }).catch(e => {
          this.loading = false;
        });
      }
      if (this.actionType === 'transactionReportActions') {
        this.setDateRangeStore({
          name: this.actionType,
          from: `${this.date[0].getFullYear()}-${String(this.date[0].getMonth() + 1).padStart(2, '0')}-${String(this.date[0].getDate()).padStart(2, '0')}`,
          to: `${this.date[1].getFullYear()}-${String(this.date[1].getMonth() + 1).padStart(2, '0')}-${String(this.date[1].getDate()).padStart(2, '0')}`
        });
        await this.getTransactionReport().then(res => {
          this.loading = false;
        }).catch(e => {
          this.loading = false;
        });
      }
      if (this.actionType === 'reviseReport') {
        this.setDateRangeStore({
          name: this.actionType,
          from: `${this.date[0].getFullYear()}-${String(this.date[0].getMonth() + 1).padStart(2, '0')}-${String(this.date[0].getDate()).padStart(2, '0')}`,
          to: `${this.date[1].getFullYear()}-${String(this.date[1].getMonth() + 1).padStart(2, '0')}-${String(this.date[1].getDate()).padStart(2, '0')}`
        });
        await this.getReviseReport().then(res => {
          this.loading = false;
        }).catch(e => {
          this.loading = false;
        });
      }
      if (this.paymentsActions) {
        this.setDateRangeStore({
          name: this.actionType,
          from: `${this.date[0].getFullYear()}-${String(this.date[0].getMonth() + 1).padStart(2, '0')}-${String(this.date[0].getDate()).padStart(2, '0')}`,
          to: `${this.date[1].getFullYear()}-${String(this.date[1].getMonth() + 1).padStart(2, '0')}-${String(this.date[1].getDate()).padStart(2, '0')}`
        });
        await this.getPayments().then(res => {
          this.loading = false;
        }).catch(e => {
          this.loading = false;
        });
      }
      if (this.cardsReport) {
        this.setDateRangeStore({
          name: 'paymentsActions',
          withoutTime: this.cardsReport,
          from: `${this.date[0].getFullYear()}-${String(this.date[0].getMonth() + 1).padStart(2, '0')}-${String(this.date[0].getDate()).padStart(2, '0')}`,
          to: `${this.date[1].getFullYear()}-${String(this.date[1].getMonth() + 1).padStart(2, '0')}-${String(this.date[1].getDate()).padStart(2, '0')}`
        });
        await this.getCardsReport().then(res => {
          this.loading = false;
        }).catch(e => {
          this.loading = false;
        });
      }
    },
    download() {
      if (this.uploadFiles) {
        this.$emit('uploadXSLX')
      } else {
        this.$emit('downloadXSLX')
      }
    },
    downloadFile(name) {
      const link = document.createElement('a');
      if (name === 'payments_sample') {
        link.href = `/files/${name}.xlsx`;
        link.setAttribute('download', `${name}.xlsx`);
      } else {
        link.href = `/files/${name}.xls`;
        link.setAttribute('download', `${name}.xls`);
      }
      document.body.appendChild(link);
      link.click();
      link.remove();
    },
    async addFile(file) {
      console.log(file)
      console.log(file.target.files[0])

      let formData = new FormData();
      formData.append('files', file.target.files[0])

      await this.bunchUploadCardsFile(formData)
    },
    addBunchCard() {
      document.getElementById("fileUpload").click()
    },
    addDelkoCard() {
      this.loading = true;
      this.getCardsFromDelko().then(res => {
        this.loading = false;
      }).catch(e => {
        this.loading = false;
      });
    },
    showUploadCards() {
      this.toggleOverlay(true)
      this.setShowUploadCardsPopup(true)
    },
    syncCardStatuses() {
      this.loading = true;
      this.syncCardStatusesFromDelko().then(res => {
        this.loading = false;
      }).catch(e => {
        this.loading = false;
      });
    },
    addCard(card) {
      this.closePopups()
      this.toggleOverlay(true)
      this.showAddCard(true)
    },
    showIntegrationList() {
      this.closePopups()
      this.toggleOverlay(true)
      this.setShowIntegrationsListPopup(true)
    },
    showListVinksRegionsFuel() {
      this.closePopups()
      this.toggleOverlay(true)
      this.setShowListVinksRegionsFuel(true)
    },
    addNewUser() {
      this.closePopups()
      this.toggleOverlay(true)
      this.showAddNewUser(true)
    },
    onClickOutside (event) {
      console.log('Clicked outside. Event: ', event)
      this.closePopups()
    },
    cellHovered(event, row, field, index) {
      if (field === 'actions') {
        this.showActionPopup(event, row, index)
      }
    },
    cellClicked(event, row, field, index) {
      if (row.hidden_filed && field === row.hidden_filed) {
        this.toggleHiddenCellContent(row)
      }
      //console.log(field, index)

      if (field === 'actions') {
        this.showActionPopup(event, row, index)
        this.actionsPopupShown = index
      }
    },
    toggleHiddenCellContent(row) {
      row.hidden = !row.hidden
    },
    showCardActionsPopup() {
      this.cardActionsPopupShown = true
    },
    showLimitActionsPopup() {
      this.limitActionsPopupShown = true
    },
    closePopups() {
      this.filterPopupShown = null;
      this.filterPopupShown_2 = null;
      this.setActionPopup(null);
      //this.actionsPopupShown = null
      this.cardActionsPopupShown = false
      this.limitActionsPopupShown = false
    },
    checkMousePosition() {
      if (!this.mouseOverFilter) {
        this.closePopups()
      }
    },
    emulateClick(event, index, column) {
      console.log(event)
      let el = event.target
      console.log(el)
      el.click()
    },
    showFilterPopup(event, index, column) {
      if (!column.no_filter) {
        //console.log(column)
        //console.log(event)
        // Учитываем размеры меню при расчете координат
        const menuWidth = 195; // Замените на ширину вашего меню
        const menuHeight = 125; // Замените на высоту вашего меню

        //const buttonRect = event.target.parentElement.getBoundingClientRect();
        const buttonRect = event.target.getBoundingClientRect();
        //event.target.parentElement.style.background = 'red'

        // let menuLeft = event.clientX - menuWidth;
        // let menuTop = event.clientY + menuHeight;
        // let menuLeft = buttonRect.left;
        // let menuTop = buttonRect.bottom// - 16// + menuHeight - event.target.parentElement.clientHeight;

        let menuLeft = Math.min(buttonRect.left, window.innerWidth - menuWidth - 15);
        let menuTop = Math.min(buttonRect.bottom - 10, window.innerHeight - menuHeight - 15);

        if (this.isSecondTable) {
          this.filterPopupShown_2 = {
            index: index,
            menuX: menuLeft,
            menuY: menuTop,
            action: this.actionType
          }
          this.setColumns_2(this.columns)
        } else {
          this.filterPopupShown = {
            index: index,
            menuX: menuLeft,
            menuY: menuTop,
            action: this.actionType
          }
          this.setColumns(this.columns)
        }

        window.addEventListener('scroll', () => {
          this.filterPopupShown = null
          this.filterPopupShown_2 = null
        })
        document.getElementById('Table').addEventListener('scroll', () => {
          this.filterPopupShown = null
          this.filterPopupShown_2 = null
        })
      }
    },
    showActionPopup(event, row, index) {
      //console.log(event)
      // Учитываем размеры меню при расчете координат
      const menuWidth = 195; // Замените на ширину вашего меню
      const menuHeight = 125; // Замените на высоту вашего меню

      const buttonRect = event.target.closest('td').getBoundingClientRect();
      //event.target.closest('td').style.background = 'red'

      let menuLeft = Math.min(buttonRect.left, window.innerWidth - menuWidth - 130);
      let menuRight = window.innerWidth - buttonRect.left - 20;
      let menuTop = Math.min(buttonRect.bottom - 10, window.innerHeight - menuHeight - 15);

      this.setActionPopup({
        data: row,
        index: index,
        menuX: menuRight,
        menuY: menuTop
      })

      window.addEventListener('scroll', () => {
        this.setActionPopup(null);
      })
      document.getElementById('Table').addEventListener('scroll', () => {
        this.setActionPopup(null);
      })
    },
    calculatePosition(dropdownList, component, {width, top, left}) {
      // console.log(dropdownList, component, width, top, left)
      let topInt = top.slice(0, -2)
      setTimeout(() => {
        let topRes = Number(topInt) - dropdownList.offsetHeight + 8;
        dropdownList.classList.add('pagination-select-list')
        dropdownList.style.top = topRes + 'px';
        dropdownList.style.left = left;
        dropdownList.style.maxWidth = width;
        dropdownList.style.minWidth = width;
      }, 0)
    },
    showSurety(surety_info) {
      this.toggleOverlay(true)
      this.showSuretyInfo(true);
      console.log('surety_info', surety_info)
      this.dataSuretyInfo(surety_info);
    },
    getMonthName(index) {
      const monthArr = [
        'Январь',
        'Февраль',
        'Март',
        'Апрель',
        'Май',
        'Июнь',
        'Июль',
        'Август',
        'Сентябрь',
        'Октябрь',
        'Ноябрь',
        'Декабрь',
      ];
      return monthArr[index];
    },
    getMonthNameCurrent(arr) {
      let date = new Date();
      let currentMonth = date.getMonth();
      let currentMonthName = this.getMonthName(currentMonth);
      return currentMonthName;
    },
    getMonthValueCurrent(arr) {
      let date = new Date();
      let currentMonth = date.getMonth();
      return arr[currentMonth].toFixed(2);
    },
    getFuelTypes(column, row) {
      let field = '';
      if (row.fuel_type_data) {
        row.fuel_type_data.forEach(fuel => {
          if (column.field === fuel.fuel_type) {
            field = fuel.total_liters_fuel;
          }
        })
      }
      return field;
    },
    getFuelProviderName(column, row) {
      let name = '';
      this.fuelProviders.forEach(provider => {
        if (provider.id === row[column.field]) {
          name = provider.name
        }
      })
      return name;
    },
    getFuelTypesTotal(column, row) {
      let field = '';
      if (row.fuel_type_data) {
        row.fuel_type_data.forEach(fuel => {
          if (column.field === fuel.fuel_type) {
            field = fuel.total_amount_fuel;
          }
        })
      }
      return field;
    },
    getFuelTypesTotalEnd(column, row) {
      if (column.field === 'total_amount' && row.total_data) {
        return row.total_data['total_amount']
      }
      if (column.field === 'total_litres' && row.total_data) {
        return row.total_data['total_liters']
      }
      if (column.field === 'total_amount_liter' && row.total_data) {
        return row.total_data['total_amount_liter']
      }
      return 0;
    },
    getLimitName(column, row) {
      let name = '';
      this.cardLimits.forEach(item => {
        if (item.id === row[column.field]) {
          name = item.name;
        }
      })
      return name;
    },
    getFormatedDate(column, row) {
      let str = '';
      if (row[column.field] && row[column.field].length > 0) {
        let splitDateTime = row[column.field].split(' ');
        if (splitDateTime.length > 0) {
          let splitDate = splitDateTime[0].split('-');
          str = `${splitDate[2]}.${splitDate[1]}.${splitDate[0]}`
          if (splitDateTime.length > 1) {
            str += ` ${splitDateTime[1]}`
          }
        }
      }
      return str;
    },
    getTotalSum(column, total) {
      let sum = 0;

      if (total && total.length > 0) {
        total.forEach(item => {
          if (column === 'total_amount') {
            sum += Number(item[column])
          }
          if (column === 'total_customers_sum') {
            sum += Number(item[column])
          }
        })
      }
      return sum;
    },
    async fetchOptions (search, loading) {
      if (search.length >= 3) {
        loading(true)
        await this.getUsersSearch({
          search,
          type: 3
        })
        loading(false)
      }
    },
    clearSelectOptions() {
      this.setManagers([]);
      let filter = [ ...this.cardsReportFilters ];
      if (this.client === '' || this.client === null) {
        filter[0].value = 0;
        this.getCardsSelect()
        this.setCardsReportFilters(filter);
        this.getCardsReport();
      } else {
        filter[0].value = this.client.customer_id;
        this.getCardsSelect({customer_id: this.client.customer_id})
        this.setCardsReportFilters(filter);
        this.getCardsReport();
      }
    },
    clearNumberCardSelectOptions() {
      let filter = [ ...this.cardsReportFilters ];
      if (this.card === '' || this.card === null) {
        filter[1].value = 0;
        this.getCardsSelect()
        this.setCardsReportFilters(filter);
        this.getCardsReport();
      } else {
        filter[1].value = this.card.number;
        this.setCardsReportFilters(filter);
        this.getCardsReport();
      }
    },
    async resetFilters() {
      this.columns_store.forEach(item => {
        item.filter_value = '';
        item.filter_value_to = '';
        item.filter_value_from = '';
      })
      this.setColumns(this.columns_store)
      if (this.columns_2_store && this.columns_2_store.length > 0) {
        this.columns_2_store.forEach(item => {
          item.filter_value = '';
          item.filter_value_to = '';
          item.filter_value_from = '';
        })
        this.setColumns_2(this.columns_2_store)
      }

      let args = [
        {
          name: 'page',
          value: 1
        },
        {
          name: 'perPage',
          value: 100
        }
      ]

      this.setPaginationFilters(args)

      if (this.$route.name === 'cards_management' || this.$route.name === 'fuelCards') {
        await this.getCards()
      } else if (this.$route.name === 'reports.cards' || this.$route.name === 'cardsReports') {
        await this.getCardsReport()
      } else if (this.$route.name === 'fuelCards') {
        await this.getCards()
      } else if (this.$route.name === 'client_management') {
        await this.getCustomers()
      } else if (this.$route.name === 'client_management') {
        await this.getCustomers()
      } else if (this.$route.name === 'users_management') {
        await this.getAllUsers()
      } else if (this.$route.name === 'reports.transactions' || this.$route.name === 'transactionsReports') {
        await this.getTransactionReport()
      } else if (this.$route.name === 'reports.revise') {
        args[1].name = 'page_size'
        this.setPaginationFilters(args)
        await this.getReviseReport()
      } else if (this.$route.name === 'dashboard' || this.$route.name === 'admin.dashboard.executive') {
        args[1].name = 'page_size'
        this.setPaginationFilters(args)
        await this.getManagerDashboard()
        if (this.userRole === 1) {
          args[1].name = 'page_size'
          this.setPaginationFilters_2(args)
          await this.getOperationsReport()
        }
      } else if (this.$route.name === 'reports.payment' || this.$route.name === 'paymentReports' || this.$route.name === 'payments') {
        await this.getPayments()
      } else if (this.$route.name === 'files_upload.prices') {
        args[1].name = 'page_size'
        this.setPaginationFilters(args)
        await this.getPricesFiles()
      } else if (this.$route.name === 'files_upload.transactions') {
        await this.getTransactionFiles()
      } else if (this.$route.name === 'admin.dashboard.accountant') {
        args[1].name = 'page_size'
        this.setPaginationFilters(args)
        await this.getAccountantDashboard()
      }
    },
    setTableScroll(event) {
      if (this.actionType === 'transactionReportActions') {
        let table = document.querySelector('.table-scroll')
        let tableScrollLine = document.querySelector('.table-scroll-line');
        table.scrollLeft = event.target.scrollLeft
        tableScrollLine.scrollLeft = event.target.scrollLeft
        this.$forceUpdate();
      }
    }
  },
  mounted() {
    let dateNow = new Date();
    let dayNow = dateNow.getDate();
    let monthNow = dateNow.getMonth();
    let yearNow = dateNow.getFullYear();
    let monthPrevious = 1;
    let yearPrevious = 2024;
    if (monthNow > 0) {
      monthPrevious = monthNow - 1;
    }
    if (monthNow === 0) {
      monthPrevious = 11;
    }
    if (monthPrevious > monthNow) {
      yearPrevious = yearNow - 1;
    }
    if (monthPrevious < monthNow) {
      yearPrevious = yearNow;
    }
    this.date = [
      {
        month: monthPrevious,
        year: yearPrevious
      },
      {
        month: monthNow,
        year: yearNow
      }
    ]
    if (this.actionType === 'clientsManagement' && this.datePicker) {
      this.setDateRangeStore({ name: this.actionType, str: `${this.date[0].year}.${this.date[0].month + 1}-${this.date[1].year}.${this.date[1].month + 1}` });
    }
    if (this.actionType === 'reviseReport' && this.datePicker) {
      this.setDateRangeStore({ name: this.actionType, str: `${this.date[0].year}.${this.date[0].month + 1}-${this.date[1].year}.${this.date[1].month + 1}` });
    }
    if (this.actionType === 'transactionReportActions' || this.actionType === 'reviseReport' || this.paymentsActions || this.cardsReport) {
      this.date = []
      this.date.push(new Date(`${yearNow}.${monthNow + 1}.1`))
      this.date.push(new Date(`${yearNow}.${monthNow + 1}.${dayNow}`))
      this.setDateRangeStore({
        name: this.cardsReport ? 'paymentsActions' : this.actionType,
        withoutTime: this.cardsReport,
        from: `${this.date[0].getFullYear()}-${String(this.date[0].getMonth() + 1).padStart(2, '0')}-${String(this.date[0].getDate()).padStart(2, '0')}`,
        to: `${this.date[1].getFullYear()}-${String(this.date[1].getMonth() + 1).padStart(2, '0')}-${String(this.date[1].getDate()).padStart(2, '0')}`
      });
    }
  }
}
</script>

<style scoped lang="scss">
@import "../styles/variables";

.no-data {
  display: block;
  margin: 10px auto;
}

.table-wrapper {
  //min-height: 250px;
  position: relative;
}

.overlay {
  position: fixed;
  width: 100vw;
  height: 100vh;
  //background: rgba(255, 0, 0, 0.85);
  z-index: 1;
  left: 0;
  top: 0;
  //background: rgba(255, 0, 0, 0.03);
}

.header {
  display: flex;
  //justify-content: space-between;
  padding: 15px 10px;
  align-items: center;
  align-self: stretch;
  border-bottom: 1px solid $back;
  background: #FFFFFF;
  margin-bottom: 15px;

  &.borderless{
    border: none;
    margin-bottom: 0;
  }

  .title {
    margin-right: auto;
    color: $black;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: M_SemiBold, Arial, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 142.857% */
  }
  .export-btn {
    display: flex;
    flex-grow: 0;
    flex-basis: auto;
    padding: 8px 16px 8px 8px;
    align-items: flex-start;
    gap: 5px;

    font-family: SF_Pro_Medium, Arial, sans-serif;
    line-height: 16px;
    svg {
      path {
        transition: all .3s;
      }
    }
  }
}

.table-scroll {
  overflow-x: auto;
  &.no-scroll {
    //overflow-x: unset;
  }
}

table {
  width: 100%;
  thead {
    th {
      color: $grey-1;
      font-feature-settings: 'clig' off, 'liga' off;
      font-family: SF_Pro_Medium, Arial, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px; /* 133.333% */
      cursor: pointer;
      position: relative;

      &.active {
        .inner {
          color: $green;
          svg {
            path {
              stroke: $green;
            }
          }
        }
      }

      .bg {
        position: absolute;
        height: 100%;
        width: 100%;
        //content: '';
        left: 0;
        top: 0;
        background: transparent;
        z-index: 0;

        &:hover {
          background: $back;
        }
      }

      &.non-hoverable {
        cursor: auto;
        pointer-events: none;
        .bg {
          &:hover {
            background: transparent;
          }
        }
      }

      &:hover {
        //background: $back;

        //svg {
        //  path {
        //    stroke: $green;
        //  }
        //}
      }


      .inner {
        pointer-events: none;
        display: flex;
        align-items: flex-start;
        position: relative;
        z-index: 10;
        //gap: 4px;
        .arrow {
          margin-left: 4px;
        }
      }

    }
  }

  td, th {
    //display: flex;
    padding: 10px;
    //flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    flex: 1 0 0;
    align-self: stretch;
    border-bottom: 1px solid $back;
    text-align: left;
    vertical-align: top;
  }

  td {
    color: $black;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: SF_Pro_Medium, Arial, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
    vertical-align: top;

    &.clickable {
      cursor: pointer;
    }

    &.action-td {
      position: relative;
      cursor: pointer;
      //display: flex;
      padding: 13px 4px;
      //align-items: flex-start;
      //flex: 1 0 0;
      width: 22px;

      &:hover {
        background: $back;
      }
    }

    .guarantee {
      display: flex;
      padding: 10px;
      flex-direction: column;
      align-items: flex-start;
      gap: 10px;
      flex: 1 0 0;
      align-self: stretch;

      button {
        display: flex;
        align-items: flex-start;
        gap: 4px;
        color: $green;
        text-align: center;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: SF_Pro_Medium, Arial, sans-serif;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px;
        background: #FFFFFF;
        border: none;
        padding: 0;
        border-radius: 4px;
      }
    }
  }

  tr {
    &:last-child {
      td {
        border-bottom: none;
      }
    }
    &.light-font {
      td {
        color: $black;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: M_Regular, Arial, sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
      }
    }
  }

  &.light-font {
    td {
      color: $black;
      font-feature-settings: 'clig' off, 'liga' off;
      font-family: M_Regular, Arial, sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 142.857% */
    }
  }
  &.additional {
    margin-top: 10px;
    thead {
      th {
        color: $black;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: M_SemiBold, Arial, sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px; /* 142.857% */
      }
    }
  }

  &.bordered {
    tr {
      border-bottom: 1px solid $back;
    }
  }

  .strong {
    font-weight: 600;
  }
  .green {
    color: $green;
  }
  .red {
    color: $red;
  }

  .light-font {
    color: $black;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: M_Regular, Arial, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }
}

.date-picker {
  display: flex;
  align-items: center;
  gap: 30px;
  align-self: stretch;


  .wrap {
    gap: 10px;
    display: flex;
    width: 370px;

    color: $grey-2;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: SF_Pro_Medium, Arial, sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 14px; /* 116.667% */
    text-align: left;

    .dp__input {
      border-radius: 6px;
      border: 1px solid $back !important;
      --dp-border-color: $back !important;
    }
  }
}

.card-actions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 15px;
  align-self: stretch;

  .wrap {
    position: relative;
  }

  .card-actions-btn {
    display: flex;
    padding: 4px;
    align-items: flex-start;
    gap: 10px;

    border-radius: 6px;
    border: 1px solid $green;
    background: none;
  }
}

.cards-report {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
  align-self: stretch;
  width: 100%;

  .cards-report-filters {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 15px;
    align-self: stretch;
    width: 100%;

    .input {
      width: 211px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 4px;

      .label {
        color: $grey-2;
        font-family: SF_Pro_Medium, Arial, sans-serif;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px; /* 133.333% */
      }

      .v-select {
        width: 100%;

        color: $black;
        font-family: SF_Pro_Medium, Arial, sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px; /* 142.857% */
      }
    }
  }
}

.users-management {
  display: flex;

  .new-user {
    margin-right: 10px;
  }
}

.pagination {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 15px;

  .size {
    display: flex;
    align-items: center;

    .label {
      color: $grey-2;
      font-feature-settings: 'clig' off, 'liga' off;
      font-family: SF_Pro_Regular, Arial, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px; /* 133.333% */
      margin-left: 8px;
    }

    .v-select {
      width: 55px;
      height: 32px;

      color: $black;
      font-family: SF_Pro_Bold, Arial, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      line-height: 16px;
    }
  }

  .pages {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2px;
    button {
      display: flex;
      padding: 8px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 10px;

      background: #FFFFFF;
      width: 32px;
      height: 32px;

      border: none;

      color: $black;
      text-align: center;
      font-feature-settings: 'clig' off, 'liga' off;
      font-family: SF_Pro_Regular, Arial, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px; /* 133.333% */

      &.active, &:hover {
        &:not(:disabled) {
          border-radius: 4px;
          box-shadow: 0px 2px 4px 0px rgba(109, 181, 52, 0.30);
          color: $green;
        }
      }
    }
  }
}

.checkbox-cont {
  .checkbox {
    position: absolute;
    width: 0;
    height: 0;
    z-index: -999;
    opacity: 0;
  }

  .indicator {
    position: relative;
    display: flex;
    align-items: flex-start;
    color: $green;
    height: 22px;
    width: 22px;

    &::before {
      content: '';
      display: inline-block;
      min-width: 20px;
      min-height: 20px;
      top: 0;
      left: 0;
      background: #FFFFFF;
      border: 1px solid #B0B1B2;
      border-radius: 3.5px;
      transition: all .3s;
      cursor: pointer;
      //margin-right: 11px;
    }

    &::after {
      content: url('../assets/tick.svg');
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      left: 0px;
      top: 0px;
      width: 100%;
      height: 100%;
      //border-radius: 50%;
      padding-bottom: 2px;
      opacity: 0;
      transition: all .3s;
      cursor: pointer;
    }

    &.semi-active {
      &::before {
        background: #B0B1B2;
      }
      &::after {
        content: url('../assets/semiTick.svg');
        opacity: 1;
      }

    }
  }

  .checkbox:checked + .indicator {
    &::before {
      border-color: $green;
      background: $green;
    }

    &::after {
      opacity: 1;
    }
  }
}

.menu {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  gap: 10px;
  align-self: stretch;
  flex-wrap: wrap;

  .menu-btn {
    height: 24px;
    width: 24px;
    padding: 0;
    border: none;
    background: #ffffff;

    &:hover {
      &:not(:disabled) {
        svg {
          path {
            stroke: $green;
          }
        }
      }
    }

    &:disabled {
      opacity: 0.3;
    }
  }
}

.payments {
  display: flex;
  gap: 8px;
}

.show-all-btn {
  margin: 5px 0 0;
  padding: 0;
  color: $green;
  border: none;
  background: none;
  outline: none;
}

.reset-filters-btn {
  margin: 0 0 0 10px;
}
</style>
