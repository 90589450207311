<template>
  <div class="layout" :class="{'login': isLoginPage}">
    <allPopups/>
    <div v-if="$route.name !== 'login'" class="main-menu">
      <div class="menu">
        <router-link :to="{ name: 'main' }" class="menu-link">
          <img src="../assets/logo.svg" alt="logo">
        </router-link>
        <div class="separator"></div>
        <router-link v-for="(item, i) in menu" :key="i"
                     @mouseenter="item.active = true"
                     @mouseleave="item.active = false"
                     :to="{ name: item.link }"
                     :class="{'active': $route.name && ($route.name === item.link || ($route.name && $route.name.includes(item.link)) )}"
                     class="menu__item" >
          <img :src="$route.name === item.link || item.active || ($route.name && $route.name.includes(item.link)) ? item.iconActive : item.icon" alt="icon">
          <span class="text">
            {{item.name}}
            <span v-if="item.link === 'notifications' && notifications > 0" class="counter">{{notifications}}</span>
          </span>
        </router-link>
      </div>
      <div class="user">
        <!--<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M12 4C9.79 4 8 5.79 8 8C8 10.21 9.79 12 12 12C14.21 12 16 10.21 16 8C16 5.79 14.21 4 12 4ZM14 8C14 6.9 13.1 6 12 6C10.9 6 10 6.9 10 8C10 9.1 10.9 10 12 10C13.1 10 14 9.1 14 8ZM18 17C17.8 16.29 14.7 15 12 15C9.3 15 6.2 16.29 6 17.01V18H18V17ZM4 17C4 14.34 9.33 13 12 13C14.67 13 20 14.34 20 17V20H4V17Z" fill="#1A1A18"/>
        </svg>-->
        <div class="avatar">
<!--          <img src="../assets/avatar.png" alt="avatar">-->
          <span>{{ getAvatarText() }}</span>
        </div>
        <div class="name-wrap">
         <span v-if="myUser && myUser.type < 3" class="name">
          {{ myUser.name }}
         </span>
         <span v-if="myUser && myUser.type === 3" @click="goToAccount()" class="name cursor-pointer">
          {{ myUser.name }}
         </span>
          <span @click="logout" class="logout">Выйти</span>
        </div>
      </div>

<!--      &lt;!&ndash;   ROLE PICKER //TODO remove when backend is done  &ndash;&gt;-->
<!--      <div style="position: absolute; bottom: 20px; opacity: 0.25;">-->
<!--        <label for="client">-->
<!--          client-->
<!--          <input type="radio" id="client" :value="3" v-model="role" />-->
<!--        </label>-->
<!--        <label for="manager">-->
<!--          manager-->
<!--          <input type="radio" id="manager" :value="2" v-model="role" />-->
<!--        </label>-->
<!--        <label for="admin">-->
<!--          admin-->
<!--          <input type="radio" id="admin" :value="1" v-model="role" />-->
<!--        </label>-->
<!--      </div>-->
<!--      &lt;!&ndash;   ROLE PICKER //TODO remove when backend is done  &ndash;&gt;-->

    </div>
    <div class="content">
      <router-view/>
    </div>
  </div>
</template>

<script>
import {mapActions, mapMutations, mapState} from "vuex";
import allPopups from './popups/allPopups.vue'
import {markRaw} from "vue";
export default {
  name: "Menu",
  components: {
    allPopups
  },
  data: () => ({
    notifications: 3,
    menu_client: [
      {
        link: 'dashboard',
        name: 'Дашборд',
        icon: require('../assets/menu-icons/dashboard.svg'),
        iconActive: require('../assets/menu-icons/dashboard-active.svg')
      },
      {
        link: 'fuelCards',
        name: 'Топливные карты ',
        icon: require('../assets/menu-icons/card.svg'),
        iconActive: require('../assets/menu-icons/card-active.svg')
      },
      {
        link: 'cardsReports',
        name: 'Отчет по оборотам карт',
        icon: require('../assets/menu-icons/reports.svg'),
        iconActive: require('../assets/menu-icons/reports-active.svg')
      },
      {
        link: 'transactionsReports',
        name: 'Транзакционный отчет',
        icon: require('../assets/menu-icons/reports.svg'),
        iconActive: require('../assets/menu-icons/reports-active.svg')
      },
      {
        link: 'paymentReports',
        name: 'Платежный отчет',
        icon: require('../assets/menu-icons/reports.svg'),
        iconActive: require('../assets/menu-icons/reports-active.svg')
      },
      // {
      //   link: 'card',
      //   name: 'Карта АЗС',
      //   icon: require('../assets/menu-icons/card.svg'),
      //   iconActive: require('../assets/menu-icons/card-active.svg')
      // },
      // {
      //   link: 'bills',
      //   name: 'Счета на оплату',
      //   icon: require('../assets/menu-icons/bill.svg'),
      //   iconActive: require('../assets/menu-icons/bill-active.svg')
      // },
      // {
      //   link: 'notifications',
      //   name: 'Уведомления',
      //   icon: require('../assets/menu-icons/bell.svg'),
      //   iconActive: require('../assets/menu-icons/bell-active.svg')
      // },
    ],
    menu_manager: [
      {
        link: 'client_management',
        name: 'Управление клиентами',
        icon: require('../assets/menu-icons/clients.svg'),
        iconActive: require('../assets/menu-icons/clients-active.svg')
      },
      {
        link: 'reports',
        name: 'Отчеты ',
        icon: require('../assets/menu-icons/reports.svg'),
        iconActive: require('../assets/menu-icons/reports-active.svg')
      },
      {
        link: 'payments',
        name: 'Платежи',
        icon: require('../assets/menu-icons/bill.svg'),
        iconActive: require('../assets/menu-icons/bill-active.svg')
      },
    ],
    menu_admin: [
      {
        link: 'admin.dashboard',
        name: 'Дашборд',
        icon: require('../assets/menu-icons/dashboard.svg'),
        iconActive: require('../assets/menu-icons/dashboard-active.svg')
      },
      {
        link: 'reports',
        name: 'Отчеты',
        icon: require('../assets/menu-icons/reports.svg'),
        iconActive: require('../assets/menu-icons/reports-active.svg')
      },
      {
        link: 'cards_management',
        name: 'Управление картами',
        icon: require('../assets/menu-icons/card.svg'),
        iconActive: require('../assets/menu-icons/card-active.svg')
      },
      {
        link: 'files_upload',
        name: 'Загрузка файлов',
        icon: require('../assets/menu-icons/upload.svg'),
        iconActive: require('../assets/menu-icons/upload-active.svg')
      },
      {
        link: 'references',
        name: 'Справочник',
        icon: require('../assets/menu-icons/references.svg'),
        iconActive: require('../assets/menu-icons/references-active.svg')
      },
      {
        link: 'users_management',
        name: 'Управление пользователями',
        icon: require('../assets/menu-icons/users.svg'),
        iconActive: require('../assets/menu-icons/users-active.svg')
      },
    ]
  }),
  computed: {
    ...mapState({
      token: state => state.user.token,
      myUser: state => state.user.myUser,
      userRole: state => state.user.userRole,
      roles: state => state.user.roles,
      user_statuses: state => state.user.user_statuses,
    }),
    menu() {
      switch (this.role) {
        case 3: return this.menu_client;
        case 2: return [this.menu_client[0], this.menu_manager[0], this.menu_manager[1], this.menu_manager[2]]
        case 1: return [this.menu_admin[0], this.menu_manager[0], this.menu_admin[1], this.menu_manager[2], this.menu_admin[2], this.menu_admin[3], this.menu_admin[4], this.menu_admin[5]]
      }
    },
    isLoginPage() {
      return this.$route.name === 'login'
    },
    role: {
      get() {
        return this.userRole
      },
      set(val) {
        this.setUserRole(val)
      }
    }
  },
  methods: {
    ...mapActions({
      getMyUser: 'getMyUser',
      getUserRoles: 'getUserRoles',
      getUserStatuses: 'getUserStatuses',
    }),
    ...mapMutations({
      setToken: 'SET_TOKEN',
      setUserRole: 'SET_USER_ROLE',
      showOverlay: 'TOGGLE_OVERLAY',
    }),
    goTo(link) {
      //console.log(link)
      this.$router.push({name: link})
    },
    logout() {
      this.setToken('')
      localStorage.removeItem('token')
      localStorage.removeItem('role');
      this.$router.push({name: 'login'})
    },
    goToAccount() {
      this.$router.push('/account-management')
    },
    getAvatarText() {
      let text = '';
      if (this.myUser && this.myUser.name) {
        let nameSplit = this.myUser.name.split(' ')
        nameSplit.forEach((item, index) => {
          if (index < 2) {
            text += item[0];
          }
        })
      }
      return text;
    }
  },
  async mounted() {
    if (!this.myUser && this.token) {
      await this.getMyUser().catch(e => {
        if (e.response.data.status === 401) {
          this.logout();
        }
      });
    }

    if (this.myUser && this.token) {
      if (!this.roles || this.roles.length === 0) {
        await this.getUserRoles()
      }
      if (!this.user_statuses || this.user_statuses.length === 0) {
        await this.getUserStatuses()
      }
    }

  }
}
</script>

<style scoped lang="scss">
@import "../styles/variables";

.layout {
  background: $back;
  position: relative;
  display: flex;

  .content {
    flex-shrink: 1;
    width: calc(100% - 299px);
  }

  &.login {
    .content {
      width: 100%;
    }
  }
}

.main-menu {
  display: flex;
  //position: absolute;
  margin-top: 15px;
  margin-left: 15px;
  margin-bottom: 15px;
  display: flex;
  width: 260px;
  height: 100%;
  min-height: calc(100vh - 48px - 30px);
  padding: 24px 12px;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  flex-shrink: 0;
  border-radius: 12px;
  background: #FFFFFF;
  box-shadow: 0px 2px 4px 0px rgba(26, 26, 24, 0.24);
}

.menu {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;

  .menu-link {
    cursor: pointer;
    display: flex;
    width: 100%;
    height: 56px;
    padding: 8px 8px 8px 16px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
    box-sizing: border-box;

    img {
      width: 100%;
    }
  }

  .separator {
    display: flex;
    padding: 18px 16px;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    height: 20px;
  }

  &__item {
    cursor: pointer;
    display: flex;
    //height: 56px;
    padding: 16px 24px 16px 16px;
    align-items: center;
    gap: 12px;
    align-self: stretch;
    border-radius: 8px;
    text-align: left;
    text-decoration: none;
    //align-items: flex-start;

    color: $black;
    font-family: SF_Pro_Regular, Arial, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 125% */
    letter-spacing: 0.1px;
    transition: all .3s;

    &:hover {
      color: $green;
    }

    .text {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      .counter {
        color: $orange;
        text-align: right;
        font-family: SF_Pro_ExtraBold, Arial, sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 800;
        line-height: 20px; /* 142.857% */
        letter-spacing: 0.1px;
      }
    }

    &.active {
      background: rgba(109, 181, 52, 0.10);
      font-family: SF_Pro_SemiBold, Arial, sans-serif;
      font-weight: 600;
    }

    img {
      width: 24px;
      height: 24px;
    }
  }
}

.user {
  display: flex;
  padding: 12px;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;

  border-radius: 8px;
  background: rgba(176, 177, 178, 0.10);

  .avatar {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    span {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      background: rgba(109, 181, 52, 0.1);
    }
  }

  .name-wrap {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }
  .name {
    color: $black;
    font-family: SF_Pro_Medium, Arial, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 150% */
    letter-spacing: 0.5px;
    //cursor: pointer;
    &:hover {
      color: $green;
    }
  }


  .logout {
    cursor: pointer;
    color: $grey_2;
    font-family: SF_Pro_Medium, Arial, sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px; /* 133.333% */
    text-decoration-line: underline;
  }
}
</style>
